import React from 'react';
import { useNavigate } from 'react-router-dom';
import TitlePage from '../../../components/Pages/TitlePage';
import MainPage from '../../../components/Pages/MainPage';
import FormGeneric from '../../../components/Form';
import InputSelect from '../../../components/Inputs/Input_Select';
import InputGeneric from '../../../components/Inputs/Input_generic';
import CONSTANTE_CUSTOMER from '../../../constants/customer';
import ButtonGeneric from '../../../components/Button';
import JourneyProvider from '../../../providers/journey';
import schemaCreateJourneyType from '../../../validators/schemas/journey/createJourneyType';
import InputCheckbox from '../../../components/Inputs/Input_Checkbox';

export default function CreateJourneyTypes() {
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    const newJourneyType = await JourneyProvider.createJourneyType(data);
    if (newJourneyType) {
      navigate(`/journeys/types/${newJourneyType.id}`);
    }
  };

  return (
    <>
      <TitlePage
        title="Jornadas"
        item="Criação de Jornadas"
        description="Página destinada a criação de uma nova jornadas."
      />
      <MainPage>
        <FormGeneric onSubmit={onSubmit} schema={schemaCreateJourneyType}>
          <InputGeneric
            name="title"
            label="Título"
            placeholder="Digite o título da jornada"
            id="journeyTitle"
          />
          <InputSelect
            name="customerType"
            label="Tipo de Cliente"
            options={CONSTANTE_CUSTOMER.CUSTOMER_TYPES.OPTIONS}
            placeholder="Selecione um tipo de cliente"
          />
          <InputCheckbox
            label="Demanda Customizada"
            name="customJourney"
            className="mt-2"
          />
          <InputGeneric
            as="textarea"
            name="description"
            label="Descrição"
            placeholder="Digite a descrição da jornada"
            id="journeyDescription"
          />
          <div className="d-flex justify-content-end mt-2">
            <ButtonGeneric
              className="text-white"
              variant="primary"
              type="submit"
              id="createJourneyTypeButton"
            >
              Criar
            </ButtonGeneric>
          </div>
        </FormGeneric>
      </MainPage>
    </>
  );
}
