/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { BiSend } from 'react-icons/bi';
import { toast } from 'react-toastify';
import { FaRegCalendarAlt } from 'react-icons/fa';
import dayjs from 'dayjs';
import TitlePage from '../../components/Pages/TitlePage';
import MainPage from '../../components/Pages/MainPage';
import demandsProvider from '../../providers/demands';
import ChatModalDemand from '../../components/Modals/ModalDemand/ChatModalDemand';
import FormGeneric from '../../components/Form';
import schemaSendMessageDemand from '../../validators/schemas/demands/sendMessage';
import RichTextArea from '../../components/Inputs/Input_RichTextArea';
import ButtonGeneric from '../../components/Button';
import s3 from '../../providers/urlGenerator';
import PrivateDemandIcon from '../../components/customIcons/PrivateDemandIcon';
import ModalPrivateDemand from '../../components/Modals/ModalDemand/ModalPrivateDemand';
import ModalConfirmation from '../../components/Modals/ModalConfirmation';
import ModalAddTag from '../../components/Modals/ModalDemand/ModalAddTag';
import NotificationIcon from '../../components/customIcons/NotificationIcon';
import EmailUnreadIcon from '../../components/customIcons/EmailUnreadIcon';
import PlusIcon from '../../components/customIcons/PlusIcon';
import ModalAddReminder from '../../components/Modals/ModalDemand/ModalAddReminder';
import ReminderProvider from '../../providers/reminder';
import ModalRemoveReminder from '../../components/Modals/ModalDemand/ModalRemoveReminder';
import MappingIcon from '../../components/customIcons/MappingIcon';
import DemandsTagProvider from '../../providers/demandsTag';
import { defaultGetAllParams } from '../../utils/params';
import DateUtils from '../../utils/DateUtils';
import PrioritizedDemandIcon from '../../components/customIcons/PrioritizedDemandIcon';
import Table from '../../components/Table/Table';
import DocumentProvider from '../../providers/documentProvider';
import DownloadIcon from '../../components/customIcons/DownloadIcon';
import FeedbackDemand from '../../components/Modals/ModalDemand/FeedbackDemand';
import EditIcon from '../../components/customIcons/EditIcon';
import ModalAlterTitle from '../../components/Modals/ModalDemand/ModalAlterTitle';
import ModalPriority from '../../components/Modals/ModalDemand/ModalPriority';
import DemandCategoryProvider from '../../providers/demandCategory';
import SendDocumentSign from '../../components/Sign/SendDocumentSign';

function DownloadButton({ row }) {
  return (
    <div
      onClick={async () => {
        const { signedUrl } = await s3.getSignedDownloadFileUrl(
          row?.awsFileKey,
        );
        s3.downloadFileByUrl(signedUrl, row?.fileName);
      }}
    >
      <DownloadIcon padded={false} />
    </div>
  );
}

const columnsDocuments = [
  {
    name: 'Nome do Arquivo',
    columnName: 'fileName',
    selector: (row) => row?.fileName,
    sortable: true,
  },
  {
    name: 'Enviado em',
    columnName: 'createdAt',
    selector: (row) => DateUtils.formatToLocale(row?.createdAt),
    sortable: true,
  },
  {
    name: 'Ações',
    columnName: 'actions',
    selector: (row) => (
      <div className="row align-items-center">
        <div className="col-auto">
          <DownloadButton row={row} />
        </div>
        <div className="col-auto">
          <SendDocumentSign doc={row} />
        </div>
      </div>
    ),
  },
];

export default function DemandById() {
  const param = useParams();
  const demandId = param.id;
  const scrollRef = useRef(null);

  const navigate = useNavigate();

  const [showMessages, setShowMessages] = useState(true);
  const [showModalPrivateDemand, setShowModalPrivateDemand] = useState(false);
  const [showModalUnPrivateDemand, setShowModalUnPrivateDemand] =
    useState(false);
  const [showModalActivateNotifications, setShowModalActivateNotifications] =
    useState(false);
  const [
    showModalDeactivateNotifications,
    setShowModalDeactivateNotifications,
  ] = useState(false);

  const [showModalAddTag, setShowModalAddTag] = useState(false);
  const [showModalUnreaded, setShowModalUnreaded] = useState(false);
  const [clearRichText, setClearRichText] = useState();
  const [isAssociate, setIsAssociate] = useState(false);
  const [showRichText, setShowRichText] = useState(false);
  const [showPendingFooter, setShowPendingFooter] = useState(false);
  const [showFinishedFooter, setShowFinishedFooter] = useState(false);
  const [showModalValidateDemand, setShowModalValidateDemand] = useState(false);
  const [showModalFeedbackDemand, setShowModalFeedbackDemand] = useState(false);
  const [nextPrioritySla, setNextPrioritySla] = useState(dayjs());
  const [category, setCategory] = useState(null);

  const isAssociateToReminder = async () => {
    if (!demand) return;

    const isAssociateToReminderResponse =
      await ReminderProvider.getAssociateDemandToReminder(
        demand.id,
        demand.businessId,
      );
    if (isAssociateToReminderResponse) {
      setIsAssociate(true);
    } else {
      setIsAssociate(false);
    }
  };

  const demand = useSelector((state) => {
    return state.demand.data;
  });

  const needRefresh = useSelector((state) => state.demand.needRefresh);

  const authUser = useSelector((state) => state.auth.auth);
  const demandsTagList = useSelector((state) => state.demandsTagList);

  const dispatch = useDispatch();

  const fetchTags = async () => {
    const demandsTag = await DemandsTagProvider.getAll(defaultGetAllParams);

    dispatch({
      type: 'SET_DEMANDS_TAG_LIST',
      payload: { ...demandsTag },
    });
  };

  const fetch = () => {
    demandsProvider.getDemandById(demandId).then(async (response) => {
      dispatch({
        type: 'SET_DEMANDS_STATE',
        payload: {
          data: response,
          needRefresh: false,
        },
      });
      if (response?.categoryId) {
        const c = await DemandCategoryProvider.getById(response?.categoryId);
        setCategory(c);
      }
    });
    demandsProvider.getNextPrioritySla(demandId).then((response) => {
      setNextPrioritySla(response.nextPrioritySla);
    });
  };

  if (needRefresh) {
    setTimeout(() => {
      fetch();
    }, 100);
  }

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
    if (demand) {
      if (demand.statusKey === 'PENDING') {
        setShowPendingFooter(true);
        setShowRichText(false);
        setShowFinishedFooter(false);
      } else if (demand.statusKey === 'FINISHED') {
        setShowRichText(false);
        setShowPendingFooter(false);
        setShowFinishedFooter(true);
      } else if (demand.statusKey === 'BLOCKED') {
        setShowFinishedFooter(false);
        setShowPendingFooter(false);
        setShowRichText(false);
      } else {
        setShowFinishedFooter(false);
        setShowPendingFooter(false);
        setShowRichText(true);
      }

      if (!demand.visualized?.includes(authUser.id)) {
        const demandToUpdate = { ...demand };
        delete demandToUpdate.messages;

        demandToUpdate.visualized = demandToUpdate.visualized
          ? [...demandToUpdate.visualized, authUser.id]
          : [authUser.id];

        demandsProvider.updateDemand(demandToUpdate.id, {
          visualized: demandToUpdate.visualized,
        });
      }
    }
    isAssociateToReminder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demand, authUser?.id, needRefresh]);

  useEffect(() => {
    fetch();
    fetchTags();
    dispatch({
      type: 'SET_DOCUMENT_LIST',
      payload: {
        where: [
          {
            key: 'demandId',
            value: demandId,
            operator: 'eq',
          },
        ],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demandId]);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'RESET_DOCUMENT_LIST',
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (data) => {
    if (data.message.message.trim() === '') {
      toast.error('Mensagem não pode ser vazia');
      return;
    }

    const attachments = await Promise.all(
      data?.message.attachments?.map(async (file) => {
        const uploaders = await s3.uploadFile(file);
        return uploaders;
      }) || [],
    );
    const request = {
      content: data.message.message,
      attachments,
    };

    if (demand.statusKey === 'PENDING' || demand.statusKey === 'FINISHED') {
      await demandsProvider.updateDemand(demand.id, {
        statusKey: 'IN_PROGRESS',
        message: request.content,
        attachments: request.attachments,
        checked: false,
      });
    } else {
      await demandsProvider.createMessage(demand.id, request);
    }

    await clearRichText();
    await fetch();

    dispatch({
      type: 'RESET_FILES_STATE',
    });
  };

  const submitPrivateDemands = async (selectedUsers) => {
    let reqParameters = {};
    if (selectedUsers?.length > 0) {
      reqParameters = {
        users: selectedUsers.map((user) => ({ id: user.value })),
      };
    }

    try {
      await demandsProvider.demandTogglePrivate(
        demand.id,
        {
          reqParameters,
        },
        false,
      );

      toast.success('Demanda atualizadas com sucesso.');
    } catch (error) {
      toast.error('Erro ao atualizar demanda.');
    } finally {
      await fetch();
    }
  };

  const toggleNotifications = async () => {
    await demandsProvider.addOrRemoveUserFollowingToDemand(demand.id, {
      userId: authUser.id,
    });
    await fetch();
  };

  const makeUnreaded = async () => {
    const demandToUpdate = { ...demand };
    delete demandToUpdate.messages;

    demandToUpdate.visualized = demandToUpdate.visualized.filter(
      (user) => user !== authUser.id,
    );

    await demandsProvider.updateDemand(demandToUpdate.id, {
      visualized: demandToUpdate.visualized,
    });
    navigate('/demands');
  };

  const removeTag = async (tag) => {
    const demandUpdated = { ...demand };
    delete demandUpdated.message;

    demandUpdated.demandsTags = demandUpdated.demandsTags.filter(
      (tagId) => tagId !== tag,
    );

    await demandsProvider.updateDemand(demandUpdated.id, {
      demandsTags: demandUpdated.demandsTags,
    });
    fetch();
  };

  const submitValidateDemands = async () => {
    try {
      await demandsProvider.updateDemand(demand.id, {
        validationDate: new Date(),
        validationUserId: authUser.id,
      });

      toast.success('Demandas atualizadas com sucesso.');
    } catch (error) {
      toast.error('Erro ao atualizar demandas.');
    } finally {
      await fetch();
    }
  };

  if (!demand?.id) {
    return <h6>Demanda não encontrada</h6>;
  }

  return (
    <>
      <TitlePage
        border="none"
        title={
          <Row className="m-0">
            <Col sm="auto" md="auto" xs="8" id="demandTitle">
              {demand?.name}{' '}
              <button
                type="button"
                id="editTitleButton"
                onClick={() => {
                  dispatch({
                    type: 'STATE_MODAL_DEMAND',
                    payload: { modalType: 'Alterar título' },
                  });
                }}
                className="cursor-pointer border-0 bg-transparent"
              >
                <EditIcon padded={false} />
              </button>
            </Col>
          </Row>
        }
        item="Demandas"
      />
      <MainPage>
        <Row className="justify-space-between">
          {demand?.statusKey === 'IN_PROGRESS' &&
            dayjs(demand?.deleveryForecast).isAfter(dayjs(nextPrioritySla)) &&
            !demand?.priority && (
              <Col md="5" xxl="auto">
                <ButtonGeneric
                  style={{ color: '#D902A1' }}
                  variant="link"
                  id="prioritizeDemandButton"
                  className="text-decoration-none align-items-center d-flex "
                  onClick={() => {
                    dispatch({
                      type: 'STATE_MODAL_DEMAND',
                      payload: {
                        modalType: 'Priorizar',
                      },
                    });
                  }}
                >
                  <PrioritizedDemandIcon
                    fill="#D902A1"
                    padded={false}
                    width={20}
                    height={20}
                  />
                  Priorizar demanda
                </ButtonGeneric>
              </Col>
            )}
          {demand?.private === false ? (
            <Col md="5" xxl="auto">
              <ButtonGeneric
                id="turnPrivateDemandButton"
                style={{ color: '#C08C00' }}
                variant="link"
                className="text-decoration-none align-items-center d-flex "
                onClick={() => setShowModalPrivateDemand(true)}
              >
                <PrivateDemandIcon
                  fill="#C08C00"
                  padded={false}
                  width={20}
                  height={20}
                />
                Tornar demanda sigilosa
              </ButtonGeneric>
            </Col>
          ) : (
            <Col md="5" xxl="auto">
              <ButtonGeneric
                style={{ color: '#C08C00' }}
                variant="link"
                id="turnUnprivateDemandButton"
                className="text-decoration-none align-items-center d-flex "
                onClick={() => setShowModalUnPrivateDemand(true)}
              >
                <PrivateDemandIcon
                  fill="#C08C00"
                  padded={false}
                  width={20}
                  height={20}
                />
                Tornar demanda não sigilosa
              </ButtonGeneric>
            </Col>
          )}
          {demand?.following?.includes(authUser.id) ? (
            <Col md="5" xxl="auto">
              <ButtonGeneric
                style={{ color: '#00AF53' }}
                variant="link"
                id="turnoOffNotificationsButton"
                className="text-decoration-none align-items-center d-flex "
                onClick={() => setShowModalDeactivateNotifications(true)}
              >
                <NotificationIcon
                  fill="#00AF53"
                  padded={false}
                  width={20}
                  height={20}
                />
                Desativar notificações
              </ButtonGeneric>
            </Col>
          ) : (
            <Col md="5" xxl="auto">
              <ButtonGeneric
                style={{ color: '#00AF53' }}
                variant="link"
                id="turnOnNotificationsButton"
                className="text-decoration-none align-items-center d-flex "
                onClick={() => setShowModalActivateNotifications(true)}
              >
                <NotificationIcon
                  fill="#00AF53"
                  padded={false}
                  width={20}
                  height={20}
                />
                Ativar notificações
              </ButtonGeneric>
            </Col>
          )}

          <Col md="5" xxl="auto">
            <ButtonGeneric
              style={{ color: '#3A85F7' }}
              variant="link"
              id="turnUnreadedButton"
              className="text-decoration-none align-items-center d-flex "
              onClick={() => setShowModalUnreaded(true)}
            >
              <EmailUnreadIcon
                padded={false}
                width={20}
                height={20}
                fill="#3A85F7"
              />
              <div className="ms-1">Marcar como não lida</div>
            </ButtonGeneric>
          </Col>

          <Col md="5" xxl="auto">
            <ButtonGeneric
              style={{ color: '#515151' }}
              variant="link"
              id="addTagButton"
              className="text-decoration-none align-items-center d-flex "
              onClick={() => setShowModalAddTag(true)}
            >
              <PlusIcon fill="#515151" padded={false} width={20} height={20} />
              <div className="ms-2">Adicionar Tag</div>
            </ButtonGeneric>
          </Col>
          <Col md="5" xxl="auto">
            <ButtonGeneric
              style={{ color: '#E51CB9' }}
              variant="link"
              id="associateReminderButton"
              className="text-decoration-none align-items-center d-flex "
              onClick={() => {
                dispatch({
                  type: 'STATE_MODAL_DEMAND',
                  payload: {
                    modalType: !isAssociate
                      ? 'Vincular à um lembrete'
                      : 'Desvincular do lembrete',
                  },
                });
              }}
            >
              <FaRegCalendarAlt fill="#E51CB9" />
              <div className="ms-2">
                {!isAssociate
                  ? 'Vincular à um lembrete'
                  : 'Desvincular lembrete da demanda'}
              </div>
            </ButtonGeneric>
          </Col>
          {demand?.customerMapping && (
            <Col md="5" xxl="auto">
              <ButtonGeneric
                style={{ color: '#C29A7D' }}
                variant="link"
                className="text-decoration-none align-items-center d-flex "
                onClick={() => navigate('/tools/mapping')}
              >
                <MappingIcon
                  fill="#C29A7D"
                  padded={false}
                  width={20}
                  height={20}
                />
                <div className="ms-2">Acessar Mapeamento de Dados</div>
              </ButtonGeneric>
            </Col>
          )}
        </Row>
        <Row className="justify-content-center m-0 p-3">
          <Col className="p-2 border position-relative">
            <Row className="align-items-center">
              {demand?.demandsTags?.length > 0 && (
                <Col md="auto" id="tagsCol">
                  <p className="fs-12 m-0">
                    Tag:{' '}
                    {demand.demandsTags.map((tag) => (
                      <strong>
                        {
                          demandsTagList?.rows?.find((row) => row.id === tag)
                            ?.name
                        }
                        <button
                          style={{
                            backgroundColor: '#ff4a55',
                            color: 'white',
                            border: 'none',
                            borderRadius: '50%',
                            marginLeft: '2px',
                            width: '18px',
                          }}
                          type="button"
                          onClick={() => removeTag(tag)}
                        >
                          X
                        </button>
                      </strong>
                    ))}
                  </p>
                </Col>
              )}
              <Col md="auto" id="demandExternalId">
                <p className="fs-12 m-0">
                  Demanda:
                  <strong className="ms-1">{demand?.externalId}</strong>
                </p>
              </Col>
              <Col md="auto" id="demandType">
                <p className="fs-12 m-0">
                  Tipo:
                  <strong className="ms-1">{demand?.demandTypeName}</strong>
                </p>
              </Col>
              <Col md="auto" id="demandCategory">
                <p className="fs-12 m-0">
                  Categoria:
                  <strong className="ms-1">
                    {category?.name ? category?.name : 'Sem categoria'}
                  </strong>
                </p>
              </Col>
              <Col md="auto" id="responsibleLawyer">
                <p className="fs-12 m-0">
                  Advogado:
                  <strong className="ms-1">{demand?.backofficeUserName}</strong>
                </p>
              </Col>
              <Col md="auto">
                <p className="fs-12 m-0" id="creationDate">
                  Solicitado em:
                  <strong className="ms-1">
                    {DateUtils.formatToLocale(demand?.createdAt)}
                  </strong>
                </p>
              </Col>
              {demand?.deleveryForecast && (
                <Col md="auto" id="deleveryForecast">
                  <p className="fs-12 m-0">
                    Previsto para:
                    <strong className="ms-1">
                      {DateUtils.formatToLocale(demand?.deleveryForecast)}
                    </strong>
                  </p>
                </Col>
              )}
              <Col className="position-absolute top-0 end-0 p-1" xs="auto">
                {demand?.priority && (
                  <PrioritizedDemandIcon padded={false} id="priorityIcon" />
                )}

                {demand?.private && (
                  <PrivateDemandIcon
                    padded={false}
                    striked
                    id="privateDemandIcon"
                  />
                )}

                {demand?.following?.includes(authUser.id) && (
                  <NotificationIcon
                    width="15px"
                    height="15px"
                    padded={false}
                    id="followingIcon"
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </MainPage>
      <div className="mt-3" />
      <MainPage>
        <Row>
          <Col md="auto">
            <span
              id="messagesButton"
              className={` fs-12 p-2   rounded-pill m-2  ${
                showMessages ? 'bg-selected' : 'bg-unselected cursor-pointer'
              } badge`}
              onClick={() => setShowMessages(true)}
            >
              Mensagens
            </span>
            <span
              id="documentsButton"
              className={` fs-12 p-2   rounded-pill m-2  ${
                !showMessages ? 'bg-selected' : 'bg-unselected cursor-pointer'
              } badge`}
              onClick={() => setShowMessages(false)}
            >
              Documentos
            </span>
          </Col>
        </Row>
        <Row>
          {showMessages ? (
            <div className="d-flex flex-column">
              <div
                style={{ height: '400px' }}
                ref={scrollRef}
                className=" overflow-scroll"
              >
                <ChatModalDemand />
              </div>
              <div className="">
                {showRichText && (
                  <FormGeneric
                    onSubmit={handleSubmit}
                    schema={schemaSendMessageDemand}
                    style={{
                      height: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        padding: '5px',
                        paddingTop: '0',
                        height: '100%',
                      }}
                    >
                      <RichTextArea
                        keyId={`cd${demandId}`}
                        saveToType="sendMessage"
                        name="message"
                        setClearRichText={setClearRichText}
                      />
                      <ButtonGeneric
                        type="submit"
                        id="submitMessageButton"
                        className="d-flex justify-content-center align-items-center border-0"
                        style={{
                          borderRadius: '0',
                          width: '45px',
                          height: '100%',
                          padding: '0',
                          margin: '0',
                        }}
                      >
                        <BiSend size={25} color="white" />
                      </ButtonGeneric>
                    </div>
                  </FormGeneric>
                )}
                {showPendingFooter && (
                  <Row className="justify-content-center">
                    <Col md="auto">
                      <ButtonGeneric
                        id="solvePendingButton"
                        onClick={() => {
                          setShowRichText(true);
                          setShowPendingFooter(false);
                        }}
                        className="text-white"
                      >
                        Resolver pendências
                      </ButtonGeneric>
                    </Col>
                  </Row>
                )}
                {showFinishedFooter && (
                  <Row className="justify-content-center gap-2">
                    <Col
                      sm="auto"
                      xs="8"
                      className="d-flex justify-content-center align-items-center order-3 order-sm-1"
                    >
                      <ButtonGeneric
                        id="reopenDemandButton"
                        onClick={() => {
                          setShowRichText(true);
                          setShowFinishedFooter(false);
                        }}
                        variant="outline-primary"
                      >
                        Reabrir demanda
                      </ButtonGeneric>
                    </Col>
                    {(!demand.feedback || demand.feedback?.skipped) && (
                      <Col
                        sm="auto"
                        xs="8"
                        className="d-flex justify-content-center align-items-center order-2 order-sm-2"
                      >
                        <ButtonGeneric
                          id="feedbackDemandButton"
                          onClick={() => {
                            setShowModalFeedbackDemand(true);
                          }}
                          className="text-white"
                        >
                          Dar feedback
                        </ButtonGeneric>
                      </Col>
                    )}
                    {!demand?.validationDate && (
                      <Col
                        sm="auto"
                        xs="8"
                        className="d-flex justify-content-center align-items-center order-1 order-sm-3"
                      >
                        <ButtonGeneric
                          onClick={() => {
                            setShowModalValidateDemand(true);
                            setShowFinishedFooter(false);
                          }}
                          className="text-white"
                          variant="info"
                          id="aproveDemandButton"
                        >
                          Aprovar
                        </ButtonGeneric>
                      </Col>
                    )}
                  </Row>
                )}
              </div>
            </div>
          ) : (
            <Table
              className="mt-5"
              columns={columnsDocuments}
              pagination
              paginationServer
              fetchFunction={DocumentProvider.getAll}
              dispatchString="DOCUMENT_LIST"
              listReducerKey="documentList"
              unStyled
            />
          )}
        </Row>
      </MainPage>
      <ModalPrivateDemand
        close={() => setShowModalPrivateDemand(false)}
        show={showModalPrivateDemand}
        confirm={(users) =>
          submitPrivateDemands(users).then(() =>
            setShowModalPrivateDemand(false),
          )
        }
      />
      <ModalConfirmation
        close={() => setShowModalUnPrivateDemand(false)}
        title="Você realmente deseja tornar esta demanda não sigilosa?"
        description="Está ação irá tornar essa demanda visível para todos usuários"
        open={showModalUnPrivateDemand}
        onConfirmation={() =>
          submitPrivateDemands().then(() => setShowModalUnPrivateDemand(false))
        }
      />
      <ModalConfirmation
        close={() => setShowModalActivateNotifications(false)}
        title="Você realmente deseja ativar as notificações?"
        description="Essa ação fará você receber notificações e e-mails sobre esta demanda."
        open={showModalActivateNotifications}
        onConfirmation={() =>
          toggleNotifications().then(() =>
            setShowModalActivateNotifications(false),
          )
        }
      />
      <ModalConfirmation
        close={() => setShowModalDeactivateNotifications(false)}
        title="Você realmente deseja desativar as notificações?"
        description="Essa ação fará você parar de receber notificações e e-mails sobre esta demanda."
        open={showModalDeactivateNotifications}
        onConfirmation={() =>
          toggleNotifications().then(() =>
            setShowModalDeactivateNotifications(false),
          )
        }
      />
      <ModalConfirmation
        close={() => setShowModalUnreaded(false)}
        title="Você realmente deseja marcar como não lida?"
        description="Essa ação fará você sair da demanda e marcá-la como se não tivesse sido lida."
        open={showModalUnreaded}
        onConfirmation={() =>
          makeUnreaded().then(() => setShowModalUnreaded(false))
        }
      />

      <ModalAddTag
        show={showModalAddTag}
        close={() => setShowModalAddTag(false)}
      />
      <ModalAddReminder />
      <ModalRemoveReminder />
      <ModalConfirmation
        close={() => setShowModalValidateDemand(false)}
        open={showModalValidateDemand}
        onConfirmation={() => {
          submitValidateDemands().then(() => setShowModalValidateDemand(false));
        }}
        title="Ao confirmar, essa demanda será aprovada."
      />
      <FeedbackDemand
        show={showModalFeedbackDemand}
        close={() => {
          fetch();
          setShowModalFeedbackDemand(false);
        }}
      />
      <ModalAlterTitle />
      <ModalPriority />
    </>
  );
}
