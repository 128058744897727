/* eslint-disable default-param-last */
const initialState = {
  notifications: [{ title: 'nothing' }],
  count: 0,
};

const csNotifications = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_NOTIFICATIONS':
      return { ...state, ...action.payload };
    case 'RESET_NOTIFICATIONS':
      return initialState;
    default:
      return state;
  }
};

export default csNotifications;
