/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InputSelect from '../../components/Inputs/Input_Select';
import TitlePage from '../../components/Pages/TitlePage';
import MainPage from '../../components/Pages/MainPage';
import JourneyProvider from '../../providers/journey';
import businessProvider from '../../providers/business';
import DemandTypeProvider from '../../providers/demandType';
import customerProvider from '../../providers/customer';
import {
  defaultGetAllParams,
  searchAllCustomersUnpaginatedParams,
  searchBackofficeParams,
} from '../../utils/params';
import userProvider from '../../providers/user';
import NdmDrive from './NdmDrive';

export default function BackofficeDocumentsList() {
  const customersList = useSelector(
    ({ customerList }) => customerList.customerList?.customerList || [],
  );

  const businessList = useSelector((state) => state.businessList);

  const usersList = useSelector((state) => state.usersList);

  const customerSelected = useSelector((state) => state.customer);

  const dispatch = useDispatch();

  const fetch = async () => {
    const demandListTypes = await DemandTypeProvider.getAllDemandTypes();
    const response = await userProvider.getAll(searchBackofficeParams);
    dispatch({
      type: 'SET_BACKOFFICE_LIST',
      payload: {
        backofficeList: response.rows,
      },
    });
    dispatch({
      type: 'SET_DEMAND_TYPES_LIST',
      payload: {
        demandTypes: demandListTypes.demandTypes,
      },
    });
  };

  const fetchCustomer = async () => {
    const customersResponse = await customerProvider.getAll(
      searchAllCustomersUnpaginatedParams,
    );

    dispatch({
      type: 'SET_CUSTOMER_LIST',
      payload: { customerList: customersResponse.rows },
    });
  };

  const fetchBusiness = async (id) => {
    const businessCustomerResponse = await businessProvider.getByCustomerId(id);

    dispatch({
      type: 'SET_BUSINESS_LIST',
      payload: { rows: businessCustomerResponse },
    });
  };

  const fecthJourneyTypes = async () => {
    const journeyTypesResponse = await JourneyProvider.getJourneyTypes(
      defaultGetAllParams,
    );
    dispatch({
      type: 'SET_JOURNEY_STATE',
      payload: {
        listTypes: journeyTypesResponse.rows,
      },
    });
  };

  const fetchCustomerUsers = async (id) => {
    const usersResponse = await userProvider.getUsersToCustomer(id);

    dispatch({
      type: 'SET_USERS_LIST',
      payload: { rows: usersResponse },
    });
  };

  useEffect(() => {
    fetch();
    fetchCustomer();
    fecthJourneyTypes();
  }, []);

  useEffect(() => {
    if (customerSelected?.id) {
      fetchBusiness(customerSelected.id);
      fetchCustomerUsers(customerSelected.id);
    }
  }, [customerSelected]);

  return (
    <div>
      <TitlePage
        title="Visualizar documentos de um cliente"
        item="Ferramentas"
      />
      <MainPage>
        <InputSelect
          options={customersList?.map((customer) => ({
            value: customer.id,
            label: customer.name,
          }))}
          placeholder="Selecione um Cliente"
          name="customerId"
          label="Cliente*"
          onChange={async (id) => {
            const customerInSelect = customersList.find(
              (customer) => customer.id === id,
            );

            dispatch({
              type: 'SET_CUSTOMER',
              payload: customerInSelect,
            });
          }}
        />

        {customerSelected?.id && (
          <InputSelect
            options={businessList?.rows?.map((business) => ({
              value: business.id,
              label: business.name,
            }))}
            placeholder="Selecione um negócio"
            name="businessId"
            label="Negócio do cliente*"
            onChange={async (id) => {
              const businessInSelect = businessList.rows.find(
                (business) => business.id === id,
              );

              dispatch({
                type: 'SET_SELECTED_BUSINESS',
                payload: businessInSelect,
              });
            }}
          />
        )}

        {businessList.selectedBusiness?.id && (
          <InputSelect
            options={usersList.rows?.map((user) => ({
              value: user.id,
              label: user.name,
            }))}
            placeholder="Usuário"
            name="userId"
            label="Selecione um usuário*"
            onChange={async (id) => {
              const userSelected = usersList.rows.find(
                (user) => user.id === id,
              );

              dispatch({
                type: 'SET_SELECTED_USER',
                payload: userSelected,
              });
            }}
          />
        )}

        {usersList.selectedUser?.id && (
          <NdmDrive
            key={usersList.selectedUser.id}
            userIdToUse={{
              id: usersList.selectedUser.id,
              businessId: businessList.selectedBusiness.id,
            }}
          />
        )}
      </MainPage>
    </div>
  );
}
