/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import SmartLink from '../../components/SmartLink';
import ButtonGeneric from '../../components/Button';
import SendDocumentSign from '../../components/Sign/SendDocumentSign';

const employeesColumnsPJ = (
  generateEmployeeContract,
  generateContractResignation,
  customerId,
  businessId,
  user,
) => [
  {
    name: 'Nome',
    selector: (row) => row.name,
    sortable: true,
    cell: (row) => (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <span style={{ cursor: 'pointer' }}>
        {user === 'CUSTOMER' ? (
          <SmartLink
            className="text-dark text-decoration-none"
            to={`/rh/create/${row?.id}/`}
          >
            {row?.name}
          </SmartLink>
        ) : (
          <SmartLink
            className="text-dark text-decoration-none"
            to={`/rh/create/${row?.id}/${customerId}/${businessId}`}
          >
            {row?.name}
          </SmartLink>
        )}
      </span>
    ),
  },
  {
    name: 'Razão Social',
    selector: (row) => row.pjCorporateName,
    sortable: true,
    cell: (row) => <span>{row?.pjCorporateName}</span>,
  },
  {
    name: 'Tipo de Serviço',
    selector: (row) => row.cargo,
    sortable: true,
    cell: (row) => <span>{row?.cargo}</span>,
  },
  {
    name: 'Ações',
    cell: (row) => (
      <div className="row justify-content-center">
        <div className="col-auto p-1 align-middle text-center justify-content-center">
          <ButtonGeneric
            className="text-center text-white mt-1"
            variant="primary"
            onClick={() => generateEmployeeContract(row)}
          >
            Gerar Contrato
          </ButtonGeneric>
        </div>
        <div className="col-auto p-1 text-center">
          <SendDocumentSign rh={row} />
        </div>
        {!row.resignation && row?.hiringStatus === 'Contratação Finalizada' && (
          <div className="col-auto ">
            <SmartLink
              className="text-decoration-none"
              to={`/rh/resignation/${row?.id}/${customerId}/${businessId}`}
            >
              <ButtonGeneric
                className="text-center text-white"
                variant="primary"
              >
                Iniciar Processo de Rescisão
              </ButtonGeneric>
            </SmartLink>
          </div>
        )}
        {row?.resignation && (
          <div className="col-auto ">
            <ButtonGeneric
              className="text-center text-white bg-danger"
              variant="primary"
              onClick={() => generateContractResignation(row)}
            >
              Gerar Rescisão
            </ButtonGeneric>
          </div>
        )}
      </div>
    ),
  },
];

export default employeesColumnsPJ;
