import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Onboarding from '../../pages/CustomerSuccess/Onboarding';
import ActiveContact from '../../pages/CustomerSuccess/ActiviteContact';
import Contracts from '../../pages/CustomerSuccess/Contracts';
import FeedbackActiviteContact from '../../pages/CustomerSuccess/Feedbacks/FeedbackActiviteContact';
import FeedbackDemand from '../../pages/CustomerSuccess/Feedbacks/FeedbackDemand';
import FeedbackOnboarding from '../../pages/CustomerSuccess/Feedbacks/FeedbackOnboarding';
import CustomerSuccessDemandsBoard from '../../pages/CustomerSuccess/demandsPanel';
import NewActiveContact from '../../pages/CustomerSuccess/NewActiveContact';
import FupsPage from '../../pages/CustomerSuccess/NewActiveContact/fups';
import NewActiveContactMetrics from '../../pages/CustomerSuccess/NewActiveContact/metrics';

export default function CustomerSuccessRoutes() {
  return (
    <Routes>
      <Route path="/onboarding" element={<Onboarding />} />
      <Route path="/demands-panel" element={<CustomerSuccessDemandsBoard />} />
      <Route path="/activeContact" element={<ActiveContact />} />
      <Route path="/contracts" element={<Contracts />} />
      <Route path="/activeContact/new" element={<NewActiveContact />} />
      <Route path="/activeContact/new/fup" element={<FupsPage />} />
      <Route
        path="/activeContact/new/metrics"
        element={<NewActiveContactMetrics />}
      />
      <Route
        path="/feedbacks/activeContact"
        element={<FeedbackActiviteContact />}
      />
      <Route path="/feedbacks/demand" element={<FeedbackDemand />} />
      <Route path="/feedbacks/onboarding" element={<FeedbackOnboarding />} />
    </Routes>
  );
}
