import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Col, Row } from 'react-bootstrap';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import TitlePage from '../../../../components/Pages/TitlePage';
import MainPage from '../../../../components/Pages/MainPage';
import InputCalendar from '../../../../components/Inputs/Input_Calendar';
import MetricsProvider from '../../../../providers/Metrics';
import TableSection from '../../../../components/Table/SectionTable';
import MetricsNewActiveContacts from './MetricsActiveContactColumns';
import InputSelect from '../../../../components/Inputs/Input_Select';
import userProvider from '../../../../providers/user';
import { searchBackofficeParams } from '../../../../utils/params';
import dictionaryOfProps from '../../../../constants/metrics';
import MetricsValueDemands from './MetricsValueDemandsColumns';

dayjs.extend(quarterOfYear);

const arrayOfValueDemandsStatus = [
  { value: 'G', label: 'Geral' },
  {
    value: 'F',
    label: 'Finalizado',
  },

  {
    value: 'N',
    label: 'Não finalizado',
  },
];

const arrayOfDateFilterOnlyForNewActiveContacts = [
  { value: 'contactDate', label: 'Data do contato' },
  { value: 'finishedAt', label: 'Data de finalização' },
];

export default function NewActiveContactMetrics() {
  const [startOfDate, setStartOfDate] = useState(dayjs().startOf('quarter'));
  const [endOfDate, setEndOfDate] = useState(dayjs().endOf('quarter'));
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [valueDemands, setValueDemands] = useState([]);
  const [valueDemandFilter, setValueDemandFilter] = useState({
    value: 'G',
    label: 'Geral',
  });
  const [currFilter, setCurrFilter] = useState({ value: 'G', label: 'Geral' });
  const [markerFilter, setMarkerFilter] = useState({
    value: 'G',
    label: 'Geral',
  });
  const [paramOfDateFilter, setParamOfDateFilter] = useState(
    arrayOfDateFilterOnlyForNewActiveContacts[0],
  );
  const fetchData = async () => {
    const sDate = dayjs(startOfDate).startOf('day').toDate();
    const eDate = dayjs(endOfDate).startOf('day').toDate();

    const UsersbackofficeResponse = await userProvider.getAll(
      searchBackofficeParams,
    );

    setUsers(
      UsersbackofficeResponse?.rows?.map((user) => ({
        value: user.id,
        label: user.name,
      })),
    );

    const where = [
      {
        key: paramOfDateFilter.value,
        operator: 'between',
        value: [sDate, eDate],
      },
    ];
    const whereValue = {
      sDate,
      eDate,
    };

    if (currFilter?.value !== 'G') {
      where.push({
        key: 'backofficeUserId',
        value: currFilter.value,
        operator: 'eq',
      });
      whereValue.backofficeUserId = currFilter.value;
    }

    const valueDemandRes = await MetricsProvider.getValueDemandMetrics(
      whereValue,
    );

    setValueDemands(
      valueDemandRes.filter((item) => {
        if (valueDemandFilter.value === 'G') {
          return true;
        }
        if (valueDemandFilter.value === 'F') {
          return item.finishedCount > 0;
        }
        if (valueDemandFilter.value === 'N') {
          return item.finishedCount === 0;
        }
        return true;
      }),
    );

    let response = await MetricsProvider.getNewActiveContactMetrics({
      where,
    });

    if (Array.isArray(response) && markerFilter?.value !== 'G') {
      response = response.filter((item) => item.marker === markerFilter.value);
    }

    setData(response || []);
  };

  const arrayOfMarkers = [];

  for (const key in dictionaryOfProps) {
    if (Object.hasOwnProperty.call(dictionaryOfProps, key)) {
      arrayOfMarkers.push({
        value: key,
        label: dictionaryOfProps[key].title,
      });
    }
  }

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line
  }, [startOfDate, endOfDate, currFilter , markerFilter, valueDemandFilter, paramOfDateFilter]);

  return (
    <>
      <TitlePage
        title="Metricas"
        item="Metricas do novo contato ativo"
        description="Aqui você pode ver as métricas do novo contato ativo"
      />
      <MainPage>
        <Row className="mt-1">
          <Col xs="4">
            <InputCalendar
              label="De"
              resetNeedDefault
              defaultValue={[startOfDate]}
              onChange={setStartOfDate}
            />
          </Col>
          <Col xs="4">
            <InputCalendar
              label="Até"
              defaultValue={[endOfDate]}
              onChange={setEndOfDate}
            />
          </Col>
          <Col xs="4">
            <InputSelect
              options={[{ value: 'G', label: 'Geral' }, ...users]}
              name="backofficeUserId"
              label="Filtro da página por Advogado"
              value={currFilter}
              onChange={(e) => {
                const currUser = users.find((user) => user.value === e) || {
                  value: 'G',
                  label: 'Geral',
                };
                setCurrFilter(currUser);
              }}
            />
          </Col>

          <Col xs="6">
            <InputSelect
              options={[{ value: 'G', label: 'Geral' }, ...arrayOfMarkers]}
              name="currMarker"
              label="Filtro dos marcadores do novo contato ativo"
              value={markerFilter}
              onChange={(e) => {
                if (!Object.keys(dictionaryOfProps).includes(e)) {
                  setMarkerFilter({
                    value: 'G',
                    label: 'Geral',
                  });
                  return;
                }
                for (const key in dictionaryOfProps) {
                  if (Object.hasOwnProperty.call(dictionaryOfProps, key)) {
                    if (key === e) {
                      setMarkerFilter({
                        value: key,
                        label: dictionaryOfProps[key].title,
                      });
                    }
                  }
                }
              }}
            />
          </Col>

          <Col xs="6">
            <InputSelect
              options={arrayOfValueDemandsStatus}
              name="valueDemand"
              label="Filtro da demanda de valor"
              value={valueDemandFilter}
              onChange={(e) => {
                const dCurrFilter = arrayOfValueDemandsStatus.find(
                  (item) => item.value === e,
                );
                setValueDemandFilter(dCurrFilter);
              }}
            />
          </Col>
        </Row>

        <InputSelect
          options={arrayOfDateFilterOnlyForNewActiveContacts}
          name="dateFilter"
          label="Trocar parametro de data"
          value={paramOfDateFilter}
          onChange={(e) => {
            const dCurrFilter = arrayOfDateFilterOnlyForNewActiveContacts.find(
              (item) => item.value === e,
            );
            setParamOfDateFilter(dCurrFilter);
          }}
        />
        <TableSection
          unStyled
          title="Metricas do novo contato ativo"
          columns={MetricsNewActiveContacts}
          data={data}
          fixedHeader
          fixedHeaderScrollHeight="100%"
        />

        <TableSection
          unStyled
          title="Demanda de Valor"
          columns={MetricsValueDemands}
          data={valueDemands}
          fixedHeader
          fixedHeaderScrollHeight="100%"
        />
      </MainPage>
    </>
  );
}
