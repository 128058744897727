import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { BsPaperclip } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai';
import { Col, Form, Row } from 'react-bootstrap';
import ButtonGeneric from '../Button';
import FileIcon from '../customIcons/FileIcon';

function BigFileInput({
  placeholder = 'Documentos, imagens, vídeos ...',
  secondPlaceHolder = 'Máximo 25MB',
  maxFiles = 5,
  ...props
}) {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (selectedFiles.length + acceptedFiles.length > maxFiles) {
        props?.onLimitExceeded && props.onLimitExceeded();
        return;
      }
      setSelectedFiles((prevFiles) => {
        const newValue = [...prevFiles, ...acceptedFiles];
        props?.onChange &&
          props.onChange({ target: { value: newValue, name: props?.name } });
        return newValue;
      });
    },
  });

  const removeFile = (file) => {
    setSelectedFiles((prevFiles) => {
      const newValue = prevFiles.filter((itemFile) => itemFile !== file);
      props?.onChange &&
        props.onChange({ target: { value: newValue, name: props?.name } });
      return newValue;
    });
  };

  const dropzoneProps = getInputProps();

  const dropzoneRootProps = getRootProps();

  const hookFormProps = props?.register(props?.name, {
    required: false,
    value: selectedFiles,
  });
  return (
    <>
      <Form.Label
        style={{
          color: '#58606C',
          fontSize: '0.85rem',
          width: props?.width || '100%',
        }}
        className={props?.className}
      >
        {props?.label}
      </Form.Label>
      <div className={props?.className || ''}>
        <input {...dropzoneProps} />
        <div
          className="row border-style-dashed"
          {...dropzoneRootProps}
          style={{}}
        >
          <input {...dropzoneProps} {...hookFormProps} />
          <Row className="justify-content-center mt-3">
            <Col md="auto">
              <BsPaperclip
                size={35}
                fill="#AFAFAF"
                className="hover-pointer"
                {...dropzoneRootProps}
              />
            </Col>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col style={{ color: '#515151' }} className="text-center" md="auto">
              <p className="fs-12 mb-0 ">{placeholder}</p>
              <p className="fs-12 mb-0 ">{secondPlaceHolder}</p>
              <p className="fs-14 mb-0 ">
                <strong className="cursor-pointer">
                  {props.textSuport ? props.textSuport : 'Arraste aqui'}
                </strong>
              </p>
              <p className="fs-12 mb-0 ">ou</p>
              <ButtonGeneric type="button" className="fs-12" variant="link">
                Clique para adicionar arquivos
              </ButtonGeneric>
            </Col>
          </Row>
        </div>
        <div className="mt-2" />
        <div className="row overflow-scroll" style={{ maxHeight: '150px' }}>
          {selectedFiles.map((file) => (
            <Row
              key={file.name}
              className="border-style-dashed align-items-center p-1 m-0 mt-2"
            >
              <Col className="p-0" md="auto">
                <FileIcon fill="#AFAFAF" padded={false} />
              </Col>
              <Col className="text-truncate">{file.name}</Col>
              <Col className="ps-0 pe-1" md="auto">
                <AiOutlineClose
                  size={15}
                  fill="#AFAFAF"
                  className="text-info"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeFile(file);
                  }}
                />
              </Col>
            </Row>
          ))}
        </div>
      </div>
    </>
  );
}

export default BigFileInput;
