import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalGeneric from '../ModalGeneric';
import ButtonGeneric from '../../Button';
import FormGeneric from '../../Form';
import schemaUpdateStatusKey from '../../../validators/schemas/demands/updateStatusKey';
import InputGeneric from '../../Inputs/Input_generic';
import demandsProvider from '../../../providers/demands';

export default function ModalAlterTitle() {
  const dispath = useDispatch();
  const demand = useSelector((state) => state.demand.data);
  const modalType = useSelector((state) => state.modalsDemand.modalType);

  const resetDataModal = () => {
    dispath({
      type: 'RESET_MODAL_DEMAND',
    });
  };

  const onSubmit = async (data) => {
    await demandsProvider.alterTitleDemand(demand?.id, data);
    const demandResponse = await demandsProvider.getDemandById(demand?.id);
    dispath({
      type: 'SET_DEMANDS_STATE',
      payload: {
        data: {
          ...demandResponse,
        },
      },
    });
    resetDataModal();
  };

  return (
    <ModalGeneric show={modalType === 'Alterar título'} close={resetDataModal}>
      <h3>Alterar Título</h3>
      <FormGeneric onSubmit={onSubmit} schema={schemaUpdateStatusKey}>
        <InputGeneric
          name="title"
          placeholder="Escreva a mensagem"
          label="Novo título"
          defaultValue={demand?.name}
        />
        <div
          className="mt-3 d-flex justify-content-center align-items-center"
          style={{
            width: '98%',
            bottom: '1rem',
            gap: '5rem',
          }}
        >
          <ButtonGeneric
            type="button"
            variant="secondary"
            size="lg"
            className="text-primary"
            onClick={() => resetDataModal()}
          >
            Cancelar
          </ButtonGeneric>
          <ButtonGeneric
            type="submit"
            variant="primary"
            size="lg"
            className="text-white"
            id="confirmDemandTitleChange"
          >
            Confirmar
          </ButtonGeneric>
        </div>
      </FormGeneric>
    </ModalGeneric>
  );
}
