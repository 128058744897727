import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalGeneric from '../ModalGeneric';
import FormGeneric from '../../Form';
import ButtonGeneric from '../../Button';
import { schemaUpdateStatusKeyDemandDefault } from '../../../validators/schemas/demands/updateStatusKey';
import InputSelect from '../../Inputs/Input_Select';
import DateUtils from '../../../utils/DateUtils';
import demandsProvider from '../../../providers/demands';
import InputSLACalendar from '../../Inputs/Input_SLACalendar';
import userProvider from '../../../providers/user';
import { searchBackofficeParams } from '../../../utils/params';
import DemandTypeProvider from '../../../providers/demandType';
import DemandCategoryProvider from '../../../providers/demandCategory';
import { orderStreakByLastAction } from '../../../constants/demands';

export default function ModalScreen() {
  const dispath = useDispatch();
  const demand = useSelector((state) => state.demand.data);
  const modalType = useSelector((state) => state.modalsDemand.modalType);
  const users = useSelector(
    ({ backofficeList }) => backofficeList.backofficeList,
  );
  const TypesList = useSelector(({ demandTypes }) => demandTypes.demandTypes);
  const demandCategoryReducer = useSelector(
    (state) => state.demandCategory.demandCategory,
  );
  const [SLA, setSLA] = React.useState(null);

  const resetDataModal = () => {
    dispath({
      type: 'RESET_MODAL_DEMAND',
    });
  };

  const onSubmit = async (data) => {
    let screenedSla = SLA;
    if (data.deleveryForecast) {
      screenedSla = DateUtils.formatToLocale(data.deleveryForecast);
    }
    await demandsProvider.screening(demand.id, {
      ...data,
      deleveryForecast: screenedSla,
    });
    await demandsProvider.updateDemand(demand?.id, {
      categoryId: data.categoryId,
    });

    if (
      demand.priority === true &&
      TypesList.demandTypes.find(({ id }) => id === data.demandTypeId).name !==
        'Reunião'
    ) {
      await demandsProvider.prioritizeDemand(demand.id);
    }
    const demandResponse = await demandsProvider.getDemandById(demand?.id);
    dispath({
      type: 'SET_DEMANDS_STATE',
      payload: {
        data: {
          demand,
          ...demandResponse,
        },
      },
    });

    const response = await userProvider.getAll(searchBackofficeParams);

    dispath({
      type: 'SET_BACKOFFICE_LIST',
      payload: {
        backofficeList: response.rows,
      },
    });

    dispath({
      type: 'SET_ORDER_OF_STREAKS',
      payload: {
        title: 'Pré-triagem',
        order: orderStreakByLastAction('desc'),
      },
    });

    setTimeout(() => {
      dispath(
        {
          type: 'SET_ORDER_OF_STREAKS',
          payload: {
            title: 'Pré-triagem',
            order: orderStreakByLastAction('noSort'),
          },
        },
        1000,
      );
    });

    resetDataModal();
    return demandResponse;
  };

  const fetch = async () => {
    if (users?.backofficeList?.length === 0 || !users?.backofficeList) {
      const response = await userProvider.getAll(searchBackofficeParams);

      dispath({
        type: 'SET_BACKOFFICE_LIST',
        payload: {
          backofficeList: response.rows,
        },
      });
    }

    const demandListTypes = await DemandTypeProvider.getAllDemandTypes();
    const demandCategories =
      await DemandCategoryProvider.getAllDemandCategories();
    dispath({
      type: 'SET_DEMAND_CATEGORY_LIST',
      payload: {
        ...demandCategories,
      },
    });

    dispath({
      type: 'SET_DEMAND_TYPES_LIST',
      payload: {
        demandTypes: demandListTypes.demandTypes,
      },
    });

    const { nextPrioritySla } = await demandsProvider.getNextPrioritySla(
      demand.id,
    );
    setSLA(DateUtils.formatToLocale(nextPrioritySla));
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!SLA) return null;

  const optionsDemandCategories = demandCategoryReducer?.demandCategories
    ?.map((demandCategory) => ({
      value: demandCategory.id,
      label: demandCategory.name,
    }))
    ?.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <ModalGeneric
      show={modalType === 'Definir Responsável'}
      close={() => resetDataModal()}
      style={{
        maxHeight: '95%',
        width: '40%',
        minWidth: '550px',
        overflowY: 'auto',
      }}
    >
      <h3 className="text-bold text-center mt-2">Pré-Triagem</h3>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <FormGeneric
          schema={schemaUpdateStatusKeyDemandDefault}
          onSubmit={onSubmit}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <InputSelect
              name="backofficeUserId"
              label="Advogado"
              placeholder="Selecione o Advogado"
              options={users?.backofficeList
                ?.map((e) => ({
                  value: e.id,
                  label: e.name,
                }))
                .sort((a, b) => a.label.localeCompare(b.label))}
              defaultValue={demand?.backofficeUserId}
            />

            <InputSelect
              name="demandTypeId"
              label="Tipo de demandas"
              placeholder="Tipo de demandas"
              options={TypesList?.demandTypes
                ?.map((e) => ({
                  value: e.id,
                  label: e.name,
                }))
                .sort((a, b) => a.label.localeCompare(b.label))}
            />
            <InputSelect
              name="categoryId"
              label="Categoria de demanda"
              options={optionsDemandCategories}
            />
            <div>
              {!demand?.priority ? (
                <InputSLACalendar
                  className="customCalendar"
                  label="Data do SLA"
                  name="deleveryForecast"
                  defaultValue={[DateUtils.createDateFromLocale(SLA)]}
                />
              ) : (
                <div className="d-flex mt-3 fs-14">
                  <span className="">SLA EXTERNO:</span>
                  <strong className="ms-2">{SLA}</strong>
                </div>
              )}
            </div>
            <div
              className="mt-3 d-flex justify-content-center align-items-center"
              style={{
                width: '98%',
                bottom: '1rem',
                gap: '5rem',
              }}
            >
              <ButtonGeneric
                type="button"
                variant="secondary"
                size="lg"
                className="text-primary"
                onClick={() => resetDataModal()}
              >
                Cancelar
              </ButtonGeneric>
              <ButtonGeneric
                type="submit"
                variant="primary"
                size="lg"
                className="text-white"
                id="screenDemandConfirmation"
              >
                Confirmar
              </ButtonGeneric>
            </div>
          </div>
        </FormGeneric>
      </div>
    </ModalGeneric>
  );
}
