import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'react-bootstrap';
import { BiSearchAlt } from 'react-icons/bi';
import TitlePage from '../../../components/Pages/TitlePage';
import MainPage from '../../../components/Pages/MainPage';
import TableSection from '../../../components/Table/SectionTable';
import activeContactColumns from './NewActiveContactColumns';
import NewActiviteContactProvider from '../../../providers/NewActiveContacts';
import ModalAddUser from './ModalNewUser';
import ModalUpdateResponsible from './ModalUpdateResponsible';
import ButtonGeneric from '../../../components/Button';
import InputSelect from '../../../components/Inputs/Input_Select';
import FormGeneric from '../../../components/Form';
import schemaUpdateBackofficeActiviteContact from '../../../validators/schemas/activiteContact';
import ModalPauseNewActiveContact from './ModalPauseNewActiveContact';
import ModalAnnotations from '../../Customer/customerById/ModalAnnotations';

export default function NewActiveContact() {
  const dispatch = useDispatch();

  const activiteContactRedux = useSelector(
    (state) => state.newActiviteContactReducer,
  );

  const fetch = async (params) => {
    const response =
      await NewActiviteContactProvider.getActiveContactsDashboard(
        activiteContactRedux.filters,
      );

    const payload = response || [];
    dispatch({
      type: 'SET_NEW_ACTIVITE_CONTACT_STATE',
      payload: {
        activeContact: payload.map((p) => {
          if (p.tags.some((tag) => tag.name === 'Inadimplente')) {
            return p;
          }
          return {
            ...p,
            tags: null,
          };
        }),
      },
    });
  };

  useEffect(() => {
    fetch();

    // eslint-disable-next-line
  }, [activiteContactRedux.filters]);

  const handleFilterPagePerResponsible = async (data) => {
    const dataFromActiveContactWithoutActiveContacts = {
      ...data,
      activeContact: [],
    };
    dispatch({
      type: 'SET_NEW_ACTIVITE_CONTACT_STATE',
      payload: {
        ...dataFromActiveContactWithoutActiveContacts,
      },
    });

    const params = {
      ...activiteContactRedux?.filters,
      filterId: data.backofficeUserId,
      healthScore: data.healthScore,
    };

    if (!data?.healthScore) delete params.healthScore;
    if (!data?.backofficeUserId) delete params.filterId;
    if (data.backofficeUserId === 'G') {
      delete params.filterId;
    }

    dispatch({
      type: 'SET_NEW_ACTIVITE_CONTACT_STATE',
      payload: {
        filters: params,
      },
    });
  };

  return (
    <>
      <TitlePage
        title="Contato Ativo"
        item="Sucesso do Cliente"
        description="A página exibe tabelas que resumem os contatos ativos com os clientes. Elas fornecem insights sobre os melhores horários para entrar em contato, o momento de maior engajamento, os períodos a evitar e as demandas de valor dos clientes. Isso permite uma abordagem estratégica nas interações, resultando em um contato mais eficaz e alinhado com as necessidades dos clientes."
      />
      <MainPage>
        <Col className="mb-4">
          <FormGeneric
            onSubmit={handleFilterPagePerResponsible}
            schema={schemaUpdateBackofficeActiviteContact}
            className="d-flex align-items-center mb-4"
          >
            <Col className="mb-3">
              <InputSelect
                options={[
                  { value: 'G', label: 'Geral' },
                  ...activiteContactRedux.users,
                ]}
                name="backofficeUserId"
                label="Filtro da página por Advogado"
                defaultValue={
                  [
                    { value: 'G', label: 'Geral' },
                    ...activiteContactRedux.users,
                  ][0]?.value
                }
              />
            </Col>
            <Col className="mb-3 ms-2">
              <InputSelect
                options={[
                  {
                    value: '70-101',
                    label: 'Ok',
                  },
                  {
                    value: '50-70',
                    label: 'Alerta',
                  },
                  {
                    value: '0-50',
                    label: 'De Risco',
                  },
                ]}
                name="healthScore"
                label="Filtro por HS"
                placeholder="Selecione um HS"
              />
            </Col>
            <Col sm={2} md={1} className="mt-3">
              <ButtonGeneric
                className="text-white ms-1"
                size="lg"
                type="submit"
              >
                <BiSearchAlt size={25} />
              </ButtonGeneric>
            </Col>
          </FormGeneric>
        </Col>

        <TableSection
          title="Contatos Ativos"
          columns={activeContactColumns}
          data={activiteContactRedux.activeContact}
          sortServer
          onSort={(selector, direction, rows) => {
            let arrayFinalized = rows
              .filter((row) => row?.NewActiveContacts[0]?.finishedAt)
              .sort(selector.sortFunction);

            let arrayNotFinalized = rows
              .filter((row) => !row?.NewActiveContacts[0]?.finishedAt)
              .sort(selector.sortFunction);

            if (direction === 'desc') {
              arrayFinalized = arrayFinalized.reverse();
              arrayNotFinalized = arrayNotFinalized.reverse();
            }

            dispatch({
              type: 'SET_NEW_ACTIVITE_CONTACT_STATE',
              payload: {
                activeContact: [...arrayNotFinalized, ...arrayFinalized],
              },
            });
          }}
        />
        <ModalPauseNewActiveContact />
        <ModalUpdateResponsible />
        <ModalAddUser />
        <ModalAnnotations />
      </MainPage>
    </>
  );
}
