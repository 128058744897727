/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useRef } from 'react';
import { Col, Row, Dropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import signProvider from '../../providers/sign';
import TitlePage from '../../components/Pages/TitlePage';
import MainPage from '../../components/Pages/MainPage';
import PDFViewerComponent from './PDFViwerComponent';
import ButtonGeneric from '../../components/Button';
import SignaturePad from './SignatureCanvas';
import FormGeneric from '../../components/Form';
import ModalGeneric from '../../components/Modals/ModalGeneric';
import PlusIcon from '../../components/customIcons/PlusIcon';
import InputCheckbox from '../../components/Inputs/Input_Checkbox';
import userProvider from '../../providers/user';

export default function OnlySigningCustomer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, setUser] = useState();
  const [signBenny, setSignBenny] = useState(false);
  const [signLuiz, setSignLuiz] = useState(false);
  const { id, signId, docSignatureId } = useParams();
  const [showSign, setDropSign] = useState(false);
  const { fileURL } = useSelector((state) => state.signReducer);
  const [signatureURL, setSignatureURL] = useState(null);
  const [coordinates, setCoordinates] = useState([]);
  const [awsFileKey, setawsFileKey] = useState([]);
  const [zoom, setZoom] = useState(1.5);
  const [docId, setDocumentId] = useState(id);
  const { showModalPadSign } = useSelector((state) => state.signReducer);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const increaseZoom = () => setZoom((prevZoom) => prevZoom + 0.5);
  const decreaseZoom = () =>
    setZoom((prevZoom) => Math.max(prevZoom - 0.5, 0.5));

  const pdfViewerRef = useRef({});
  const signaturePadRef = useRef({});

  const handleUploadFile = async () => {
    if (signLuiz || signBenny) {
      setShowConfirmationModal(true);
    } else {
      await uploadFile();
    }
  };

  const uploadFile = async () => {
    const { error } = await signProvider.confirmSignature({
      signId,
      docId,
      awsFileKey,
      signBenny: signBenny || null,
      signLuiz: signLuiz || null,
      coordinates,
    });
    if (error) {
      return toast.error(error);
    }
    return navigate(`/sign/`);
  };

  // eslint-disable-next-line consistent-return
  const getPrevious = async () => {
    if (coordinates.length > 0) {
      const versions = await signProvider.getPreviousDocumentVersions({
        where: {},
        signId,
        coordinates,
        docId,
      });

      setDocumentId(versions.documentId);
      setawsFileKey(versions.awsFileKey);
      setCoordinates(() => [...versions.coordinate]);
      return dispatch({
        type: 'SET_FILE_URL',
        payload: { fileURL: versions.url },
      });
    }
  };
  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const geturl = async () => {
      const userType = await userProvider.userLogged();
      setUser(userType.type);
      dispatch({
        type: 'SET_FILE_URL',
        payload: { fileURL: null },
      });

      const versions = await signProvider.getPreviousDocumentVersions({
        where: {
          id: docSignatureId,
        },
        signId,
        docSignatureId,
      });

      if (!versions.url) {
        const url = await signProvider.getUrlView({
          where: {
            id: docId,
          },
        });

        return dispatch({
          type: 'SET_FILE_URL',
          payload: { fileURL: url },
        });
      }
      return dispatch({
        type: 'SET_FILE_URL',
        payload: { fileURL: versions.url },
      });
    };

    geturl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchSignImg = async () => {
      const response = await signProvider.getSignImage({
        where: {
          id: signId,
        },
      });

      if (response && response.imageUrl) {
        setSignatureURL(response.imageUrl);
      }
    };
    fetchSignImg();
  }, [signId]);

  // eslint-disable-next-line consistent-return

  useEffect(() => {}, [fileURL]);
  const handlePositionSelected = async ({ x, y, pageNumber, zooms }) => {
    if (signatureURL) {
      const newCoordinates = {
        x,
        y,
        pageNumber,
        zooms,
        key: coordinates.length + 1,
        signBenny: signBenny || null,
        signLuiz: signLuiz || null,
      };

      const updatedCoordinates = [...coordinates, newCoordinates];
      setCoordinates(updatedCoordinates);

      const { url, error, documentId, awsKey } =
        await signProvider.drawSignature({
          where: { id: docId },
          coordinates: updatedCoordinates,
          awsFileKey,
          signId,
          docSignatureId,
          signBenny: signBenny || null,
          signLuiz: signLuiz || null,
        });

      setDocumentId(documentId);
      setawsFileKey(awsKey);

      if (error) {
        toast.error('Erro ao adicionar assinatura.');
      } else {
        toast.success('Assinatura adicionada com sucesso.');
        dispatch({
          type: 'SET_FILE_URL',
          payload: { fileURL: url },
        });
      }
    } else {
      toast.error(
        'Por favor, crie uma assinatura antes de selecionar o local.',
      );
    }
  };

  return (
    <>
      <TitlePage
        title="NDM Sign"
        description="Ferramenta para assinatura eletronica de documentos."
        className="p-4 border-bottom border-dark"
      />

      <MainPage>
        <div>
          <Row
            sm={12}
            className="d-flex text-center justify-content-between p-0 m-0 border-bottom"
          >
            <FormGeneric
              onSubmit={(e) => {
                uploadFile();
              }}
              className="d-flex justify-content-between"
            >
              <Col sm={12} className="d-flex justify-content-center w-100">
                {signatureURL !== 'onlySender' && (
                  <ButtonGeneric
                    onClick={() => setDropSign((prevShow) => !prevShow)}
                    className="text-white text-center w-50 mb-5"
                    type="button"
                    id="createSignature"
                  >
                    {signatureURL ? 'Editar Assinatura' : 'Criar Assinatura'}
                  </ButtonGeneric>
                )}
                <Dropdown.Menu
                  show={showSign}
                  align="center"
                  title="Adicionar signatarios"
                  className="w-25 mt-5"
                  style={{
                    top: '3%',
                    position: 'absolute',
                    zIndex: '1000',
                  }}
                >
                  {signatureURL && (
                    <Dropdown.Item className="d-flex justify-content-center">
                      {signatureURL !== 'onlySender' && (
                        <img
                          src={signatureURL}
                          className="border"
                          alt="Assinatura do usuário"
                          style={{
                            width: '150px',
                            height: '113px',
                          }}
                        />
                      )}
                    </Dropdown.Item>
                  )}

                  <Dropdown.Divider />
                  <Dropdown.Item
                    onClick={() => {
                      // eslint-disable-next-line no-unused-expressions
                      dispatch({
                        type: 'SET_SHOW_MODAL',
                        payload: {
                          showModalPadSign: true,
                        },
                        // eslint-disable-next-line no-sequences
                      }),
                        setDropSign(false);
                    }}
                    className="d-flex align-items-center"
                  >
                    <PlusIcon className="me-2" /> Editar Assinatura
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Col>
            </FormGeneric>
            <Row sm={12} className="d-flex  ">
              {coordinates.length > 0 && user === 'BACKOFFICE' && (
                <>
                  <InputCheckbox
                    label="Assinar como Luiz"
                    name="admSign"
                    checked={signLuiz}
                    onChange={(e) => {
                      setSignLuiz(e); // Limpa a assinatura do administrador
                    }}
                    className="d-flex justify-content-end  w-50"
                  />

                  <InputCheckbox
                    label="Assinar como Benny"
                    name="admSign"
                    checked={signBenny}
                    onChange={(e) => {
                      setSignBenny(e); // Limpa a assinatura do administrador
                    }}
                    className="d-flex justify-content-start w-50"
                  />
                </>
              )}
            </Row>
          </Row>
          <Row
            sm={12}
            className="d-flex justify-content-start d-flex align-items-center mt-5"
          >
            <Col xl={4} className="  text-center d-flex justify-content-end">
              <ButtonGeneric onClick={decreaseZoom}>-</ButtonGeneric>
              <ButtonGeneric className="ms-3 me-3" onClick={increaseZoom}>
                +
              </ButtonGeneric>
            </Col>

            <Col className="text-center d-flex justify-content-start" xl={2}>
              <ButtonGeneric onClick={() => getPrevious()}>
                Desfazer
              </ButtonGeneric>
            </Col>
            <Col xl={5} className="  text-center d-flex justify-content-start">
              <FormGeneric
                onSubmit={(e) => {
                  handleUploadFile();
                }}
                className="w-100 d-flex justify-content-start align-items-center"
              >
                {coordinates.length > 0 ? (
                  <ButtonGeneric
                    className="w-50"
                    type="submit"
                    style={{
                      top: '10%',
                      left: '70%',
                      // position: 'absolute',
                    }}
                  >
                    Assinar e Enviar
                  </ButtonGeneric>
                ) : (
                  <p>
                    Clique na area do documento para posicionar a assinatura.
                  </p>
                )}
              </FormGeneric>
            </Col>
          </Row>
          <Row className="">
            <PDFViewerComponent
              zoom={zoom}
              signatureURL={signatureURL}
              ref={pdfViewerRef}
              onMouseOverEvent={handlePositionSelected}
            />
          </Row>
        </div>

        <ModalGeneric
          show={showModalPadSign}
          close={() => {
            dispatch({
              type: 'SET_SHOW_MODAL',
              payload: { showModalPadSign: false },
            });
          }}
          className="d-flex w-100 h-100 align-items-center"
          style={{
            height: '600px',
          }}
        >
          <SignaturePad
            ref={signaturePadRef}
            onSave={(url) => setSignatureURL(url)}
            onClose={() => {
              dispatch({
                type: 'SET_SHOW_MODAL',
                payload: { showModalPadSign: false },
              });
            }}
          />
        </ModalGeneric>
        <ModalGeneric
          show={showConfirmationModal}
          close={() => setShowConfirmationModal(false)}
        >
          <Row className="justify-content-center gap-4 text-justify text-center">
            <h4 className="text-center mb-3">Confirmação de Assinatura</h4>
            <p>
              Você está prestes a assinar como Administrador. Deseja continuar?
            </p>
            <ButtonGeneric
              onClick={async () => {
                setShowConfirmationModal(false);
                await uploadFile();
              }}
            >
              Confirmar
            </ButtonGeneric>
            <ButtonGeneric
              variant="outline-danger"
              onClick={() => setShowConfirmationModal(false)}
            >
              Cancelar
            </ButtonGeneric>
          </Row>
        </ModalGeneric>
      </MainPage>
    </>
  );
}
