import React from 'react';
import { Nav } from 'react-bootstrap';
import ButtonGeneric from './Button';

export default function CustomButton({ children, ...rest }) {
  return (
    <Nav.Item>
      <ButtonGeneric
        className="ms-2 mt-2 border-0 text-white"
        style={{
          fontSize: '12px',
          fontWeight: '500',
          whiteSpace: 'nowrap',
          background: rest.bg || '#6c757d',
        }}
        onClick={rest?.onClick}
        id={rest?.id || ''}
      >
        {children}
      </ButtonGeneric>
    </Nav.Item>
  );
}
