/* eslint-disable no-case-declarations */
/* eslint-disable default-param-last */
const initialState = {
  sent: {
    rows: [],
    page: 1,
    limit: 10,
    count: 0,
    where: {},
    order: {},
  },
  received: {
    rows: [],
    page: 1,
    limit: 10,
    count: 0,
    where: {},
    order: {},
  },
};

const backofficeFinance = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SENT_BACKOFFICE_DOCUMENT_LIST':
      return { ...state, sent: { ...state.sent, ...action.payload } };
    case 'SET_RECEIVED_BACKOFFICE_DOCUMENT_LIST':
      return { ...state, received: { ...state.received, ...action.payload } };
    case 'RESET_DOCUMENT_LIST':
      return initialState;
    default:
      return state;
  }
};

export default backofficeFinance;
