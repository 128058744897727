import { toast } from 'react-toastify';
import Api from '.';

const FoldersProvider = {
  getAll: async (params, id, userIdToUse) => {
    try {
      let url = '/folders';
      if (id) {
        url += `/${id}`;
      }
      const response = await Api.get(url, {
        params: { ...params, userIdToUse },
      });
      return response.data;
    } catch (error) {
      toast.error(error?.message);

      return null;
    }
  },
  getAllFoldersToMoveDocument: async (params, userIdToUse) => {
    try {
      const response = await Api.get('/folders/document-to-move', {
        params: { ...params, userIdToUse },
      });
      return response.data;
    } catch (error) {
      toast.error(error?.message);

      return null;
    }
  },
  getAllFoldedrsToMoveFolder: async (params, userIdToUse) => {
    try {
      const response = await Api.get('/folders/move-options', {
        params: { ...params, userIdToUse },
      });
      return response.data;
    } catch (error) {
      toast.error(error?.message);

      return null;
    }
  },
  getByFolderId: async (params, id) => {
    try {
      let url = '/folders/documents';
      if (id) {
        url += `/${id}`;
      }
      const { data } = await Api.get(url, { params });
      data.rows = data.rows.map(({ Document, folderId }) => ({
        ...Document,
        folderId,
      }));
      return data;
    } catch (error) {
      toast.error(error?.message);

      return null;
    }
  },
  create: async (data) => {
    try {
      const response = await Api.post('/folders', data);
      toast.success('Pasta criada com sucesso');
      return response.data;
    } catch (error) {
      toast.error(error?.message);

      return null;
    }
  },
  getSelectectFolder: async (id, userIdToUse) => {
    try {
      const response = await Api.get(`/folders/selected/${id}`, {
        params: { userIdToUse },
      });
      return response.data;
    } catch (error) {
      toast.error(error?.message);

      return null;
    }
  },
  moveToTrash: async (id) => {
    try {
      const response = await Api.delete(`folders/trash/${id}`);
      toast.success('Pasta movida para lixeira!');
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || error?.message);
      return null;
    }
  },
  delete: async (id) => {
    try {
      const response = await Api.delete(`/folders/${id}`);
      toast.success('Pasta excluida permanentemente!');
      return response.data;
    } catch (error) {
      toast.error(error?.message);
      return null;
    }
  },
  updateById: async (id, data) => {
    try {
      const response = await Api.patch(`/folders/${id}`, data);
      toast.success('Pasta atualizada com sucesso');
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || error?.message);
      return null;
    }
  },
  getAllSharedFolders: async (params) => {
    try {
      const response = await Api.get('/folders/shared', {
        params,
      });
      return response.data;
    } catch (error) {
      toast.error(error?.message);
      return null;
    }
  },
};

export default FoldersProvider;
