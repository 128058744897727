import { toast } from 'react-toastify';
import axios from 'axios';
import Api, {
  defaultApiReqInterceptor,
  responseErrorInterceptor,
  responseSuccessInterceptor,
} from '.';

const s3Api = axios.create();

s3Api.interceptors.request.use(defaultApiReqInterceptor);

Api.interceptors.response.use(
  (response) => responseSuccessInterceptor(response),
  (error) => responseErrorInterceptor(error),
);

const s3 = {
  url: '/s3',
  getSignedPutFileUrl: async (path) => {
    try {
      const response = await Api.get(`${s3.url}/signedPutFileUrl`, {
        params: path,
      });
      return response.data;
    } catch (error) {
      toast.error('Erro ao enviar arquivo!');
      throw error;
    }
  },

  getSignedDownloadFileUrl: async (key) => {
    try {
      const response = await Api.get(`${s3.url}/signedDownloadFileUrl`, {
        params: { key },
      });
      toast.success('Arquivo baixado com sucesso!');
      return response.data;
    } catch (error) {
      toast.error('Erro ao baixar arquivo!');
      throw error;
    }
  },

  uploadFileToSignedPutFileUrl: async ({ signedUrl, file }) => {
    return s3Api.put(signedUrl, file, {
      headers: {
        'Content-Type': file.type,
      },
    });
  },
  // TODO apagar linkGenerator ou fazer com que ele reaproveite o código de uploadFile
  linkGenerator: async (path) => {
    const { fileType, fileName, publicRead, file } = path;

    const aws = await s3.getSignedPutFileUrl({
      fileType,
      fileName,
      publicRead,
    });

    await s3.uploadFileToSignedPutFileUrl({
      signedUrl: aws?.signedUrl,
      file,
    });

    if (publicRead === true) {
      return aws?.signedUrl.split('?')[0];
    }

    const response = await s3.getSignedDownloadFileUrl(aws?.key);
    return response?.signedUrl;
  },

  uploadFile: async (file, publicRead = false) => {
    const aws = await s3.getSignedPutFileUrl({
      fileType: file.type,
      fileName: file.name,
      publicRead,
    });

    await s3.uploadFileToSignedPutFileUrl({
      signedUrl: aws?.signedUrl,
      file,
    });

    return {
      fileType: file.type,
      fileName: file.name,
      awsFileKey: aws?.key,
      fileSize: file.size,
    };
  },
  saveFileToUserDisk: (fileBinary, fileType, fileName) => {
    const fileUrl = window.URL.createObjectURL(
      new Blob([fileBinary], {
        type: fileType,
      }),
    );
    const link = document.createElement('a');

    link.href = fileUrl;
    link.target = '_blank';
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },
  downloadFileByUrl: async (url, fileName) => {
    const fileResponse = await axios.get(url, {
      responseType: 'blob',
    });

    s3.saveFileToUserDisk(
      fileResponse.data,
      fileResponse.headers['content-type'],
      fileName,
    );
  },
  fileToBase64: (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });
  },
  formatFileToBase64: async (file) => {
    return {
      base64: await s3.fileToBase64(file),
      type: file.type,
      name: file.name,
      size: file.size,
    };
  },
};

export default s3;
