import React from 'react';
import { BsFillCircleFill } from 'react-icons/bs';
import { motion } from 'framer-motion';
import { ProgressBar } from 'react-bootstrap';

export default function Loading({ loading, percentage }) {
  return (
    loading > 0 && (
      <div
        className="d-flex justify-content-center align-items-center position-absolute w-100 h-100"
        style={{
          background: 'rgba(0, 0, 0, 0.5)',
          zIndex: 99,
        }}
      >
        <motion.main
          className="d-flex justify-content-center align-items-center flex-column"
          style={{
            gap: '1rem',
          }}
          animate={{ rotate: 720 }}
          transition={{ repeat: Infinity, duration: 3 }}
        >
          <div
            className="d-flex"
            style={{
              gap: '1.5rem',
            }}
          >
            <BsFillCircleFill color="#F1BC82" size={28} />
            <BsFillCircleFill color="#C2986A" size={28} />
          </div>
          <BsFillCircleFill color="#FFE0BE" size={28} />
        </motion.main>
        {percentage > 0 && (
          <div
            className="position-absolute w-75"
            style={{
              bottom: '35%',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            <ProgressBar now={percentage} label={`${percentage}%`} animated />
          </div>
        )}
      </div>
    )
  );
}
