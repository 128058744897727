import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ModalForm from '../../../components/Modals/ModalForm';
import FileInput from '../../../components/Inputs/Input_File';
import InputGeneric from '../../../components/Inputs/Input_generic';
import s3 from '../../../providers/urlGenerator';
import demandsProvider from '../../../providers/demands';
import DocumentProvider from '../../../providers/documentProvider';
import { schemaCreateDemandAccounting } from '../../../validators/schemas/demands/create';
import accountingActions from '../../../store/actions/accountingActions';

export default function ModalSendDocumentBackoffice() {
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const resetModal = () => {
    dispatch({ type: 'RESET_MODAL_STATE' });
  };

  const handleFiles = async (attachments) => {
    return Promise.all(
      attachments?.map(async (file) => {
        const uploaders = await s3.uploadFile(file);
        return uploaders;
      }),
    );
  };

  const onSubmitCreateDemand = async (data) => {
    const attachments = (await handleFiles(data.newDocsKey)) || [];

    const request = {
      name: data.name,
      description: data.description,
      customerId: modal?.modalSendDocumentBackoffice?.customerId,
      journeyId: data.journeyId,
      following: [modal?.modalSendDocumentBackoffice?.userId],
      demandUserId: modal?.modalSendDocumentBackoffice?.userId,
      attachments,
      demandUserFollowingIds: [],
      businessId: modal?.modalSendDocumentBackoffice?.businessId,
      fieldType: 'ACCOUNTING_FIRM',
    };

    const demandResponse = await demandsProvider.create(request);
    if (demandResponse?.id) {
      navigate(`?demandId=${demandResponse?.id}`);
      window.location.reload();
    }
  };

  const onSubimitOnlyFile = async (data) => {
    const attachments = (await handleFiles(data.newDocsKey)) || [];
    const req = {
      attachments,
      fieldType: 'ACCOUNTING_FIRM',
      businessId: modal?.modalSendDocumentBackoffice?.businessId,
      customerId: modal?.modalSendDocumentBackoffice?.customerId,
      userId: modal?.modalSendDocumentBackoffice?.userId,
    };
    await DocumentProvider.inserDocument(req);
    await accountingActions.fetchBackofficeSent({
      businessId: modal?.modalSendDocumentBackoffice?.businessId,
      customerId: modal?.modalSendDocumentBackoffice?.customerId,
    });
    resetModal();
  };

  return (
    <ModalForm
      open={modal?.modalSendDocumentBackoffice?.isOpen}
      close={resetModal}
      onSubmit={
        modal?.modalSendDocumentBackoffice?.isToCreateDemand
          ? onSubmitCreateDemand
          : onSubimitOnlyFile
      }
      schema={schemaCreateDemandAccounting}
      style={{ width: '50%', overflowY: 'auto' }}
    >
      {modal?.modalSendDocumentBackoffice?.isToCreateDemand && (
        <>
          <InputGeneric
            label="Título"
            placeholder="Revisão de contrato, ajuste de cláusula, etc..."
            name="name"
            id="demandTitle"
            maxLength={100}
          />
          <InputGeneric
            label="Descrição"
            placeholder="Descrição detalhada da demanda..."
            name="description"
            id="demandDescription"
            as="textarea"
            maxLength={2048}
          />
        </>
      )}

      <FileInput name="newDocsKey" label="Novo documento" />
    </ModalForm>
  );
}
