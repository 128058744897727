import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalGeneric from '../ModalGeneric';
import FormGeneric from '../../Form';
import ButtonGeneric from '../../Button';
import schemaUpdateStatusKey from '../../../validators/schemas/demands/updateStatusKey';
import InputSelect from '../../Inputs/Input_Select';
import userProvider from '../../../providers/user';
import { searchBackofficeParams } from '../../../utils/params';
import demandsProvider from '../../../providers/demands';

export default function ModalUpdateResponsible() {
  const dispath = useDispatch();
  const demand = useSelector((state) => state.demand.data);
  const modalType = useSelector((state) => state.modalsDemand.modalType);
  const users = useSelector(
    ({ backofficeList }) => backofficeList.backofficeList,
  );

  const resetDataModal = () => {
    dispath({
      type: 'RESET_MODAL_DEMAND',
    });
  };

  const onSubmit = async (data) => {
    await demandsProvider.alterBackofficeUser(demand?.id, data);
    const demandResponse = await demandsProvider.getDemandById(demand?.id);
    dispath({
      type: 'SET_DEMANDS_STATE',
      payload: {
        data: {
          demand,
          ...demandResponse,
        },
      },
    });
    resetDataModal();
    const response = await userProvider.getAll(searchBackofficeParams);
    dispath({
      type: 'SET_BACKOFFICE_LIST',
      payload: {
        backofficeList: response?.rows,
      },
    });
  };

  const fetch = async () => {
    if (users?.backofficeList?.length === 0 || !users?.backofficeList) {
      const response = await userProvider.getAll(searchBackofficeParams);
      dispath({
        type: 'SET_BACKOFFICE_LIST',
        payload: {
          backofficeList: response.rows,
        },
      });
    }
  };
  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalGeneric
      style={{
        width: '35%',
      }}
      show={modalType === 'Alterar Responsável'}
      close={() => resetDataModal()}
    >
      <h3 className="text-bold text-center mt-2">Alterar Responsavel</h3>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <FormGeneric schema={schemaUpdateStatusKey} onSubmit={onSubmit}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <InputSelect
              name="backofficeUserId"
              label="Advogado"
              placeholder="Selecione o Advogado"
              options={users?.backofficeList
                ?.map((e) => ({
                  value: e.id,
                  label: e.name,
                }))
                .sort((a, b) => a.label.localeCompare(b.label))}
              defaultValue={demand?.backofficeUserId}
            />
            <div
              className="mt-3 d-flex justify-content-center align-items-center"
              style={{
                width: '98%',
                bottom: '1rem',
                gap: '5rem',
              }}
            >
              <ButtonGeneric
                type="button"
                variant="secondary"
                size="lg"
                className="text-primary"
                onClick={() => resetDataModal()}
              >
                Cancelar
              </ButtonGeneric>
              <ButtonGeneric
                type="submit"
                variant="primary"
                size="lg"
                className="text-white"
                id="changeResponsibleConfirmationButton"
              >
                Confirmar
              </ButtonGeneric>
            </div>
          </div>
        </FormGeneric>
      </div>
    </ModalGeneric>
  );
}
