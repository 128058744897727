import React, { useEffect } from 'react';
import { Dropdown, Row } from 'react-bootstrap';
import { BiStar } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CsNotificationsProvider from '../../providers/csNotifications';
import userProvider from '../../providers/user';

export default function CsNotifications() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const csNotificationsStore = useSelector((state) => state.csNotifications);

  const handleCollapse = () => {
    dispatch({ type: 'SET_COLLAPSE_BUTTON_USING', payload: '' });
  };

  const setCurrntNotificationVisualized = async (notification) => {
    await CsNotificationsProvider.update({
      ...notification,
    });
    fetchNotifications();
  };

  const fetchNotifications = async () => {
    const response = await CsNotificationsProvider.getAll();
    dispatch({
      type: 'SET_NOTIFICATIONS',
      payload: {
        notifications: response || [],
        count: response.length || 0,
      },
    });
  };
  useEffect(() => {
    fetchNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Dropdown id="topBarBellDropDown" align="end">
      <Dropdown.Toggle className="position-relative bg-white text-dark border-0 hover">
        <BiStar className="" size={24} />
        {csNotificationsStore.count > 0 && (
          <span
            className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
            style={{ fontSize: '12px', padding: '4px 6px' }}
          >
            {csNotificationsStore.count > 9 ? '9+' : csNotificationsStore.count}
          </span>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="p-2"
        style={{
          minWidth: '250px',
          position: 'absolute',
        }}
      >
        {csNotificationsStore.notifications.map((n) => (
          <Dropdown.Item
            className=""
            key={n.title}
            onClick={async () => {
              if (n.type === 'POPUP') {
                const user = await userProvider.getById(n.userId);
                const formatedNumber = `+${user?.countryCode || '55'}${
                  user?.phone ? user?.phone.replace(/\D/g, '') : ''
                }`;
                window.location.href = `skype:${formatedNumber}?call`;
              } else {
                navigate(
                  `?customerId=${n.customerId}&newActiveContactId=${n.newActiveContactId}&userId=${n.userId}`,
                );
              }
              const request = {
                ...n,
                visualized: true,
              };
              await setCurrntNotificationVisualized(request);
              handleCollapse();
            }}
          >
            <div className="border-bottom pb-2 mb-1">
              <Row
                className="fs-14 text-bold "
                style={{ whiteSpace: 'normal' }}
              >
                {n.title}
              </Row>
            </div>
          </Dropdown.Item>
        ))}
        {/* <Dropdown.Item onClick={() => navigate('/users/notifications')}>
          <p className="fs-14 text-center pt-2 m-0 pm-2 hover">Ver Tudo</p>
        </Dropdown.Item> */}
      </Dropdown.Menu>
    </Dropdown>
  );
}
