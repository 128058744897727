import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ModalForm from '../../../components/Modals/ModalForm';
import InputGeneric from '../../../components/Inputs/Input_generic';
import s3 from '../../../providers/urlGenerator';
import demandsProvider from '../../../providers/demands';
import BigFileInput from '../../../components/Inputs/Input_BigFile';
import schemaCustomerCreateDemand from '../../../validators/schemas/demands/customerCreate';

export default function ModalSendDocument() {
  const modal = useSelector((state) => state.modal);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.auth.auth);

  const resetModal = () => {
    dispatch({ type: 'RESET_MODAL_STATE' });
  };

  const handleSubmit = async (data) => {
    try {
      const attachments = await Promise.all(
        data?.attachments?.map(async (file) => {
          const uploaders = await s3.uploadFile(file);
          return uploaders;
        }),
      );
      const request = {
        name: data.name,
        description: data.description,
        priority: data.priority,
        following: [data.following],
        demandUserId: authUser.id,
        attachments,
        fieldType: 'ACCOUNTING_FIRM',
      };

      const demandResponse = await demandsProvider.create(request);

      if (demandResponse?.id) {
        navigate(`/demand/${demandResponse?.id}`);
      }
    } catch (error) {
      toast.error('Erro ao criar demanda');
    } finally {
      resetModal();
    }
  };

  return (
    <ModalForm
      open={modal?.modalSendDocument?.isOpen}
      close={resetModal}
      onSubmit={handleSubmit}
      schema={schemaCustomerCreateDemand}
      style={{ width: '50%', overflowY: 'auto' }}
    >
      <InputGeneric
        label="Título"
        placeholder="Revisão de contrato, ajuste de cláusula, etc..."
        name="name"
        id="demandTitle"
        maxLength={100}
      />
      <InputGeneric
        label="Descrição"
        placeholder="Descrição detalhada da demanda..."
        name="description"
        id="demandDescription"
        as="textarea"
        maxLength={2048}
      />
      <BigFileInput label="Adicionar arquivo(s)" name="attachments" />
    </ModalForm>
  );
}
