/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { BiDownload } from 'react-icons/bi';
import TitlePage from '../../../components/Pages/TitlePage';
import MainPage from '../../../components/Pages/MainPage';
import Table from '../../../components/Table/Table';
import ReminderProvider from '../../../providers/reminder';
import {
  columnNameReminders,
  dataColumnTableReminders,
  optionsInputFilterToRemindersList,
} from './data';
import InputFilter from '../../../components/Inputs/Input_Filter';
import ModalsReminder from '../../../components/ModalsReminder';
import customerProvider from '../../../providers/customer';
import DocumentProvider from '../../../providers/documentProvider';
import ModalGeneric from '../../../components/Modals/ModalGeneric';
import SendDocumentSign from '../../../components/Sign/SendDocumentSign';
import s3 from '../../../providers/urlGenerator';

export default function ListReminders() {
  const reminders = useSelector((state) => state.reminder);
  const [doc, setDocList] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const actionSign = async (row) => {
    const docs = await DocumentProvider.getByAwsFileKey(row.attachmentsLinks);

    setDocList(docs);
    setIsModalOpen(true);
  };
  const downloadFile = async (keysList) => {
    const { signedUrl } = await s3.getSignedDownloadFileUrl(
      keysList?.awsFileKey,
    );

    s3.downloadFileByUrl(signedUrl, keysList.name);
  };

  const onSubmit = (data) => {
    const optionWhere = optionsInputFilterToRemindersList()?.find(
      (option) => option.value === data.type,
    );

    const { model, columnModel, operator } = optionWhere;

    dispatch({
      type: 'SET_REMINDER_STATE',
      payload: {
        where: [
          {
            key: data.type,
            operator,
            value: data.filter,
            model,
            columnModel,
          },
        ],
      },
    });
  };

  const resetFilterSearch = () => {
    dispatch({
      type: 'SET_REMINDER_STATE',
      payload: { where: {} },
    });
  };

  const fetch = async (page = null) => {
    const customersRequest = await customerProvider.getAll({
      limit: 1000,
    });

    const pageToFetch = page || reminders.page;
    const customersResponse = await ReminderProvider.getAll({
      page: pageToFetch,
      limit: reminders.limit,
      where: reminders.where,
      order: reminders.order,
    });

    dispatch({
      type: 'SET_REMINDER_STATE',
      payload: {
        rows: customersResponse,
        count: customersResponse?.length,
        customers: customersRequest.rows,
      },
    });
  };

  const handleSort = async (column, sortDirection) => {
    let order = [];
    if (!column.orderArray) {
      order = [[column.columnName, sortDirection]];
    } else {
      order.push([...column.orderArray, sortDirection]);
    }

    if (column.columnName) {
      dispatch({ type: 'SET_REMINDER_STATE', payload: { order } });
    }
  };

  const handlePageChange = async (page) => {
    dispatch({ type: 'SET_REMINDER_STATE', payload: { page } });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reminders.page, reminders.limit, reminders.where, reminders.order]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'RESET_REMINDER_STATE' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TitlePage title="Listagem de Lembretes" item="Lembretes" />
      <MainPage>
        <InputFilter
          onSubmit={onSubmit}
          options={optionsInputFilterToRemindersList(reminders?.customers)}
          reset={resetFilterSearch}
          filterParams={{
            name: 'filter',
          }}
        />
        {reminders?.rows?.length > 0 ? (
          <div
            style={{
              marginTop: '3rem',
              boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.25)',
              height: reminders?.rows?.length > 10 ? '35rem' : 'auto',
              overflowY: reminders?.rows?.length > 10 ? 'auto' : 'hidden',
            }}
          >
            <Table
              data={dataColumnTableReminders(reminders.rows)}
              columns={columnNameReminders(navigate, actionSign)}
              onSort={handleSort}
              onChangePage={handlePageChange}
              sortServer
            />
          </div>
        ) : (
          <h5 className="mt-5 text-center text-info">
            Nenhum Negócio encontrado
          </h5>
        )}
        <ModalsReminder />
        {isModalOpen && (
          <ModalGeneric
            show={isModalOpen}
            style={{ height: '150px' }}
            close={() => setIsModalOpen(false)}
            title="Selecionar Documento"
            className="d-flex text-center flex-column justify-content-around align-items-center"
          >
            <Col className="d-flex flex-column text-center justify-content-center justify-content-around">
              <h4 className=" text-center">
                Clique o Documento que deseja assinar.
              </h4>
              <div className="d-flex justify-content-center align-items-center">
                {doc.length > 0 ? (
                  doc?.map((doc, index) => (
                    <div
                      key={index}
                      className="d-flex border m-2  items-center text-center align-items-center justify-content-center p-2 border-b"
                    >
                      <strong>
                        <p className="d-flex align-items-center mt-3">
                          {doc?.name}
                        </p>
                      </strong>
                      <SendDocumentSign doc={doc} />
                      <BiDownload onClick={() => downloadFile(doc)} />
                    </div>
                  ))
                ) : (
                  <p>Nenhum documento encontrado.</p>
                )}
              </div>
            </Col>
          </ModalGeneric>
        )}
      </MainPage>
    </>
  );
}
