import axios from 'axios';
import { setLoaded, setLoading } from '../utils/loading';

const token = localStorage.getItem('@newndm-token') || '';
const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

if (token) config.headers.Authorization = `Bearer ${token}`;

const Api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  ...config,
});

export const responseSuccessInterceptor = (response) => {
  if (response.config?.params?.loadings === false) return response;
  setLoaded();
  return response;
};

export const responseErrorInterceptor = (error) => {
  if (error.response?.status === 401) {
    localStorage.removeItem('@newndm-token');
    window.location.reload();
  }
  if (error.response.config?.params?.loadings !== false) {
    setLoaded();
  }

  throw error;
};

export const defaultApiReqInterceptor = (instanceConfig) => {
  const businessId = localStorage.getItem('businessId');
  // eslint-disable-next-line no-param-reassign
  instanceConfig.headers.businessId = businessId;

  if (instanceConfig?.params?.loadings !== false) {
    setLoading();
  }

  if (instanceConfig) {
    // eslint-disable-next-line no-param-reassign
    instanceConfig.onUploadProgress = (progressEvent) => {
      const total = progressEvent.total || 1;
      const percentage = Math.round((progressEvent.loaded * 100) / total);
      setLoading({ percentage });
    };
  }

  return instanceConfig;
};

Api.interceptors.request.use(defaultApiReqInterceptor);

Api.interceptors.response.use(
  (response) => responseSuccessInterceptor(response),
  (error) => responseErrorInterceptor(error),
);

export default Api;
