import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import NewActiviteContactProvider from '../../../../providers/NewActiveContacts';
import ModalForm from '../../../../components/Modals/ModalForm';
import InputSelect from '../../../../components/Inputs/Input_Select';
import { fupSendingTypeOptions } from './fupsColumns';
import InputGeneric from '../../../../components/Inputs/Input_generic';
import InputFupCalendar from '../../../../components/Inputs/Input_FUP_Calendar';

export default function ModalCreateFup() {
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const preStart = queryParam.get('preStart');
  const newActiveContactId = queryParam.get('newActiveContactId');
  const resetModal = () => {
    dispatch({ type: 'RESET_MODAL_STATE' });
  };
  const fups = useSelector((state) => state.newActiviteContactReducer.fups);

  const fup = fups.find((f) => f.id === modal?.modalCreateFup?.fupId);

  const onSubmit = async (data) => {
    await NewActiviteContactProvider.createFup({
      fupSendingType: data.fupSendingType,
      dateToSend: dayjs(data.dateToSend).toISOString(),
      fupMessage: data.fupMessage,
      newActiveContactId,
    });

    resetModal();
    window.location.reload();
  };

  const updateRedux = (data) => {
    // sort by date using dayjs
    const fupsUpdated = [
      ...fups,
      { ...data, dateToSend: dayjs(data.dateToSend).toISOString() },
    ].sort((a, b) => {
      if (dayjs(a.dateToSend).isBefore(dayjs(b.dateToSend))) {
        return -1;
      }
      if (dayjs(a.dateToSend).isAfter(dayjs(b.dateToSend))) {
        return 1;
      }
      return 0;
    });

    dispatch({
      type: 'SET_NEW_ACTIVITE_CONTACT_STATE',
      payload: {
        fups: fupsUpdated,
      },
    });

    resetModal();
  };

  return (
    <ModalForm
      open={modal?.modalCreateFup?.isOpen}
      close={resetModal}
      onSubmit={preStart ? updateRedux : onSubmit}
    >
      <h3>Edite o FUP</h3>
      <InputSelect
        name="fupSendingType"
        options={fupSendingTypeOptions}
        label="Nova Pasta do Documento"
        placeholder="Selecione um tipo de envio"
        defaultValue={fup?.fupSendingType}
      />
      <InputFupCalendar name="dateToSend" label="Data" row={fup} />

      <InputGeneric
        type="text"
        as="textarea"
        name="fupMessage"
        label="Mensagem"
        defaultValue={fup?.fupMessage}
      />
    </ModalForm>
  );
}
