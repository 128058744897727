/* eslint-disable no-console */
import { toast } from 'react-toastify';
import Api from './index';

const NewActiviteContactProvider = {
  async getActiveContactsDashboard(params) {
    return Api.get(`/new-active-contact`, { params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error('Erro ao buscar contatos ativos');
      });
  },
  addUserToActiveContact: async (data) => {
    return Api.post(`/new-active-contact/relate-user`, data)
      .then((response) => {
        toast.success('Usuário adicionado com sucesso');
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error('Erro ao adicionar usuário');
      });
  },
  sendDigisacMessage: async (data) => {
    return Api.post(`/new-active-contact/send-digisac-message`, data)
      .then((response) => {
        toast.success('Mensagem enviada com sucesso');
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error('Erro ao enviar mensagem');
      });
  },
  gettingChatBoxMessages: async (data) => {
    return Api.get(`/new-active-contact/chat`, { params: data })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error('Erro ao buscar mensagens');
      });
  },
  usersLinkedToActiveContact: async (data) => {
    return Api.get(`/new-active-contact/users`, { params: data })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error('Erro ao buscar usuários');
      });
  },
  sendEmailMessage: async (data) => {
    return Api.post(`/new-active-contact/send-email`, data)
      .then((response) => {
        toast.success('Mensagem enviada com sucesso');
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error('Erro ao enviar mensagem');
      });
  },
  update: async (data) => {
    return Api.patch(`/new-active-contact/${data.id}`, data)
      .then((response) => {
        toast.success('Contato ativo atualizado com sucesso');
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error('Erro ao iniciar contato ativo');
      });
  },
  getFups: async (data) => {
    return Api.get(`/new-active-contact/fups`, { params: data })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error('Erro ao buscar fups');
      });
  },
  editFup: async (data) => {
    return Api.patch(`/new-active-contact/fups`, data)
      .then((response) => {
        toast.success('Fup editado com sucesso');
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error('Erro ao editar fup');
      });
  },
  async updateActiveContactResponsible(customerId, data = {}) {
    return Api.patch(
      `/new-active-contact/update-responsible/${customerId}`,
      data,
    )
      .then((response) => {
        toast.success('Responsável alterado com sucesso!');
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async makeSkypeCall(newActiveContactId) {
    return Api.patch(`/new-active-contact/skype-call`, { newActiveContactId })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error('Erro ao fazer ligação');
      });
  },
  async preStartOfNewActiveContact(data) {
    return Api.post(`/new-active-contact/pre-start`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error('Erro ao iniciar contato ativo');
      });
  },
  createFup: async (data) => {
    return Api.post(`/new-active-contact/fups`, data)
      .then((response) => {
        toast.success('Fup criado com sucesso');
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error('Erro ao criar fup');
      });
  },
  startOfNewActiveContact: async (data) => {
    return Api.patch(`/new-active-contact/start`, data)
      .then((response) => {
        toast.success('Contato ativo iniciado com sucesso');
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error('Erro ao iniciar contato ativo');
      });
  },
  deleteFup: async (fupId) => {
    return Api.delete(`/new-active-contact/fups/${fupId}`)
      .then((response) => {
        toast.success('Fup excluído com sucesso');
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error('Erro ao excluir fup');
      });
  },
};

export default NewActiviteContactProvider;
