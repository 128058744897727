import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CustomerAccountingDashboard from '../../pages/Contabilidade/customer';
import BackofficeAccountingDashboard from '../../pages/Contabilidade/backoffice';

export default function ContabilidadeRoutes({ isBackOffice = true }) {
  if (isBackOffice !== false) {
    return (
      <Routes>
        <Route path="/" element={<BackofficeAccountingDashboard />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<CustomerAccountingDashboard />} />
    </Routes>
  );
}
