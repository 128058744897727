import { toast } from 'react-toastify';
import Api from '.';

const FolderUserProvider = {
  insert: async (data) => {
    try {
      const response = await Api.post('/shared-folders', data);
      toast.success('Pasta compartilhada com sucesso');
      return response.data;
    } catch (error) {
      toast.error(error?.message);
      return null;
    }
  },
  getAll: async (params, userIdToUse) => {
    try {
      const response = await Api.get('/shared-folders', {
        params: { ...params, userIdToUse },
      });
      return response.data;
    } catch (error) {
      toast.error(error?.message);
      return null;
    }
  },
};

export default FolderUserProvider;
