/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { useSelector } from 'react-redux';
import { BsArrowsAngleContract, BsArrowsAngleExpand } from 'react-icons/bs';
import DEMAND_CONSTANTS, { statusColorByKey } from '../constants/demands';
import CardWithIcon from './CardWithIcon';
import DemandIcon from './customIcons/DemandIcon';
import demandListActions from '../store/actions/demandsListActions';
import SwipperSelector from './SwipperSelector';

export const StatusDemandIcon = {};

DEMAND_CONSTANTS.CUSTOMER_DEMAND_STATUS.forEach((status) => {
  StatusDemandIcon[status.key] = () => (
    <DemandIcon fill={statusColorByKey[status.key]} />
  );
});

export default function DemandStatusSelector({
  isCollapsable = false,
  recentFinishedDemands,
}) {
  const { demandCount, statusSelected } = useSelector(
    (state) => state.demandsList,
  );

  const [isCollapsed, setIsCollapsed] = React.useState(
    JSON.parse(localStorage.getItem('isCollapsed')) || false,
  );

  const toggleCollapse = () => {
    if (!isCollapsable) return;
    setIsCollapsed(!isCollapsed);
    localStorage.setItem('isCollapsed', JSON.stringify(!isCollapsed));
  };

  return (
    <>
      {isCollapsable && (
        <div className="ms-4 mb-2 ps-2">
          {!isCollapsed ? (
            <BsArrowsAngleContract
              onClick={toggleCollapse}
              size={20}
              className="cursor-pointer"
            />
          ) : (
            <BsArrowsAngleExpand
              onClick={toggleCollapse}
              size={20}
              className="cursor-pointer"
            />
          )}
        </div>
      )}
      <SwipperSelector
        statusArray={DEMAND_CONSTANTS.CUSTOMER_DEMAND_STATUS}
        setSelected={demandListActions.setStatusSelected}
        setValidateSelected={demandListActions.setStatusSelected}
        statusSelected={statusSelected}
        breakPoint="lg"
      >
        {DEMAND_CONSTANTS.CUSTOMER_DEMAND_STATUS.map((status, index) => (
          <div className="mb-2">
            <CardWithIcon
              isCollapsed={isCollapsed}
              title={demandCount[status.key]}
              description={status.description}
              helpDescription={status.helpDescription}
              Icon={StatusDemandIcon[status.key]}
              activated={status.key === statusSelected}
              onClick={() => {
                if (status.key === 'FINISHED') {
                  demandListActions.setValidatedSelected(false);
                  if (recentFinishedDemands) {
                    demandListActions.setStatusSelected(status.key, 6);
                  } else {
                    demandListActions.setStatusSelected(status.key, 50);
                  }
                } else {
                  demandListActions.setStatusSelected(status.key, 50);
                }
              }}
            />
          </div>
        ))}
      </SwipperSelector>
    </>
  );
}
