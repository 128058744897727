import { store } from '..';

const demandListActions = {
  setStatusSelected: (statusSelected, limit = 10) => {
    store.dispatch({
      type: 'SET_DEMANDS_LIST',
      payload: { statusSelected, limit },
    });
  },
  setValidatedSelected: (validatedSelected) => {
    store.dispatch({
      type: 'SET_DEMANDS_LIST',
      payload: { validatedSelected },
    });
  },
  setDemandCount: (demandCount) => {
    store.dispatch({
      type: 'SET_DEMANDS_LIST_COUNT',
      payload: demandCount,
    });
  },
};

export default demandListActions;
