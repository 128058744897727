import { toast } from 'react-toastify';
import Api from '.';

const signProvider = {
  getAll: async (query) => {
    try {
      const response = await Api.get('/sign', { params: query });

      return response.data;
    } catch (error) {
      if (error?.response?.data?.error) {
        return toast.error(error.response.data.error);
      }
      toast.error(`Erro ao buscar os documentos `);
      return error;
    }
  },

  getSignedDocs: async (query) => {
    try {
      const response = await Api.get('/sign/getSignedDocs', { params: query });

      return response.data;
    } catch (error) {
      if (error?.response?.data?.error) {
        return toast.error(error.response.data.error);
      }
      toast.error(`Erro ao buscar os documentos `);
      return error;
    }
  },

  getPendingDocs: async (query) => {
    try {
      const response = await Api.get('/sign/getPendingDocs', { params: query });

      return response.data;
    } catch (error) {
      if (error?.response?.data?.error) {
        return toast.error(error.response.data.error);
      }
      toast.error(`Erro ao buscar os documentos `);
      return error;
    }
  },

  getDocsToSign: async (query) => {
    try {
      const response = await Api.get('/sign/getDocsToSign', { params: query });

      return response.data;
    } catch (error) {
      if (error?.response?.data?.error) {
        return toast.error(error.response.data.error);
      }
      toast.error(`Erro ao buscar os documentos `);
      return error;
    }
  },

  getSignatoryType: async (query) => {
    try {
      const response = await Api.get('/sign/getSignType', { params: query });

      return response.data;
    } catch (error) {
      if (error?.response?.data?.error) {
        return toast.error(error.response.data.error);
      }
      toast.error(`Erro ao buscar os documentos `);
      return error;
    }
  },
  getHash: async (data) => {
    try {
      const response = await Api.post('/sign/getAll', data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  convertDocxToPdf: async (data) => {
    try {
      const response = await Api.post('/sign/convertDocxToPdf', data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  insertDocumentsInDrive: async (data) => {
    try {
      const response = await Api.post('/sign/insertDocumentsInDrive', data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  insertSignDocuments: async (data) => {
    try {
      const response = await Api.post('/sign/insertSignDocuments', data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  checkProcessingSign: async (data) => {
    try {
      const response = await Api.post('/sign/checkProcessingSign', data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  endSigning: async (data) => {
    try {
      const response = await Api.post('/sign/endSigning', data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  insertSignImage: async (data) => {
    try {
      const response = await Api.post('/sign/insertSignImage', data);
      return response.data;
    } catch (error) {
      return error;
    }
  },
  getAdminSignature: async (data) => {
    try {
      const response = await Api.post('/sign/getAdminSignature', data);
      return response.data;
    } catch (error) {
      return error;
    }
  },
  getSignImage: async (query) => {
    try {
      const response = await Api.get('/sign/getSignImage', { params: query });

      return response.data;
    } catch (error) {
      if (error?.response?.data?.error) {
        return toast.error(error.response.data.error);
      }

      return error;
    }
  },
  sendSignatoryEmails: async (data) => {
    try {
      const response = await Api.post('/sign/sendSignatoryEmails', data);
      toast.success('Salvo com sucesso');
      return response.data;
    } catch (error) {
      return error;
    }
  },
  getUrlView: async (query, data) => {
    try {
      const response = await Api.get('/sign/getUrlView', {
        params: query,
      });

      return response.data;
    } catch (error) {
      if (error?.response?.data?.error) {
        return toast.error(error.response.data.error);
      }
      toast.error(`Erro ao buscar os documentos `);
      return error;
    }
  },

  getPreviousDocumentVersions: async (query) => {
    try {
      const response = await Api.get('/sign/getPreviousDocumentVersions', {
        params: query,
      });

      return response.data;
    } catch (error) {
      if (error?.response?.data?.error) {
        return toast.error(error.response.data.error);
      }
      toast.error(`Erro ao buscar o buffer do documentos `);
      return error;
    }
  },
  drawSignature: async (query) => {
    try {
      const response = await Api.get('/sign/drawSignature', { params: query });

      return response.data;
    } catch (error) {
      if (error?.response?.data?.error) {
        return toast.error(error.response.data.error);
      }
      toast.error(`Erro ao buscar o buffer do documentos `);
      return error;
    }
  },
  confirmSignature: async (data) => {
    try {
      const response = await Api.post('/sign/confirmSignature', data);

      return response.data;
    } catch (error) {
      if (error?.response?.data?.error) {
        return toast.error(error.response.data.error);
      }
      toast.error(`Erro ao buscar o buffer do documentos `);
      return error;
    }
  },
  signDocument: async (query) => {
    try {
      const response = await Api.get('/sign/signDocument', { params: query });

      return response.data;
    } catch (error) {
      if (error?.response?.data?.error) {
        return toast.error(error.response.data.error);
      }
      toast.error(`Erro ao buscar o buffer do documentos `);
      return error;
    }
  },
  getDecodedToken: async (data) => {
    try {
      const response = await Api.post('/sign/getUrlPdfToken', data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  getHistory: async (data) => {
    try {
      const response = await Api.post('/sign/getHistory', data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
};

export default signProvider;
