import * as yup from 'yup';

const schemaCreateDemand = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  description: yup
    .string()
    .required('Campo obrigatório')
    .max(2048, 'A descrição não pode ter mais de 2048 caracteres'),
  customerId: yup.string().required('Campo obrigatório'),
  following: yup.string().required('Campo obrigatório'),
  businessId: yup.string().required('Campo obrigatório'),
});

export const schemaCreateDemandAccounting = yup.object().shape({
  name: yup.string().notRequired('Campo obrigatório'),
  description: yup
    .string()
    .notRequired('Campo obrigatório')
    .max(2048, 'A descrição não pode ter mais de 2048 caracteres'),
});

export default schemaCreateDemand;
