import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import dictionaryOfProps from '../../../../constants/metrics';
import { compareDates } from '../../Onboarding/waitingOnboardingColumns';

const MetricsNewActiveContacts = [
  {
    name: 'CSM',
    selector: (row) => row?.User?.name,
    sortable: true,
    sortFunction: (a, b) => {
      const aValue = a?.User?.name || '';
      const bValue = b?.User?.name || '';
      return aValue.localeCompare(bValue);
    },
  },
  {
    name: 'Nome',
    cell: (row) => (
      <Link
        to={`/customers/${row.customer.id}`}
        style={{
          textDecoration: 'none',
        }}
      >
        {row.customer.name}
      </Link>
    ),
    sortFunction: (a, b) => {
      const aValue = a.customer.name;
      const bValue = b.customer.name;
      return aValue.localeCompare(bValue);
    },
    sortable: true,
    maxWidth: '12%',
  },
  {
    name: 'Status da Reunião',
    center: true,
    selector: (row) => {
      const { marker } = row;

      return (
        <div
          style={{
            backgroundColor: dictionaryOfProps[marker]?.backgroundColor,
            color: dictionaryOfProps[marker]?.textColor,
            borderRadius: '5px',
            padding: '5px',
            textAlign: 'center',
          }}
        >
          {dictionaryOfProps[marker]?.title}
        </div>
      );
    },
  },

  {
    name: 'Data da Reunião',
    selector: (row) => {
      let text = 'Reunião ainda não realizada';

      if (row.hasBeenMade) {
        text = dayjs(row.finishedAt).format('DD/MM/YYYY');
      }
      return text;
    },
  },

  {
    name: 'Data de Churn',
    selector: (row) => {
      let text = 'Cliente não churnado';

      if (row.churnDate) {
        text = dayjs(row.churnDate).format('DD/MM/YYYY');
      }
      return text;
    },
  },

  {
    name: 'Data de Contato',
    selector: (row) => dayjs(row.contactDate).format('DD/MM/YYYY'),
    sortable: true,
    sortFunction: (a, b) => {
      const aValue = a?.contactDate || '';
      const bValue = b?.contactDate || '';
      return compareDates(aValue, bValue);
    },
  },
];

export default MetricsNewActiveContacts;
