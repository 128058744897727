import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import employeesProvider from '../../providers/employees';
import SignIcon from '../customIcons/Sign';
import ModalConfirmation from '../Modals/ModalConfirmation';
import TooltipGeneric from '../Tooltip';

export default function SendDocumentSign({ doc, rh }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleClicktoRH = async () => {
    const docGenerated = await employeesProvider.generateContracts(rh);

    const data = {
      awsFileKey: docGenerated.doc.awsFileKey,
      fileSize: docGenerated.doc.fileSize,
      fileType: docGenerated.doc.fileType,
      fileName: docGenerated.doc.fileName,
      rh: true,
      isExternal: true,
      docId: docGenerated.doc.id,
      pdfFile: [
        new File([docGenerated.doc.fileSize], docGenerated.doc.name, {
          type: docGenerated.doc.fileType,
          size: docGenerated.doc.fileSize,
          lastModified: docGenerated.doc.lastModified || Date.now(),
        }),
      ],
    };
    dispatch({
      type: 'SET_FILE',
      payload: { data },
    });
    dispatch({
      type: 'SET_SHOW_MODAL',
      payload: { showModalPadSign: true },
    });
    navigate('/sign');
  };

  const handleClickGeneric = () => {
    const data = {
      isExternal: true,
      docId: doc.id,
      awsFileKey: doc.awsFileKey,
      fileSize: doc.fileSize,
      fileType: doc.fileType,
      fileName: doc.fileName,
      pdfFile: [
        new File([doc.fileSize], doc.name, {
          type: doc.fileType,
          size: doc.fileSize,
          lastModified: doc.lastModified || Date.now(),
        }),
      ],
    };
    dispatch({
      type: 'SET_FILE',
      payload: { data },
    });
    dispatch({
      type: 'SET_SHOW_MODAL',
      payload: { showModalPadSign: true },
    });
    navigate('/sign');
  };

  return (
    <>
      <span
        className="text-white text-decoration-none bg-transparent border-none cursor-pointer"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {showTooltip && (
          <TooltipGeneric
            placement="top"
            style={{ zIndex: 9999, rigth: '40', top: '0' }}
            className=" position-absolute d-flex justify-content-end text-end"
            title="Assinar Documento"
          >
            <p
              style={{ right: '55px' }}
              className="position-absolute  text-dark   d-flex justify-content-center align-items-end"
            >
              ?
            </p>
          </TooltipGeneric>
        )}
        <SignIcon
          padCustomStyle={{ backgroundColor: 'transparent' }}
          className="bg-transparent border-none"
          onClick={() => setOpenModal(true)}
        />
      </span>
      <ModalConfirmation
        close={() => setOpenModal(false)}
        open={openModal}
        className="d-flex text-center justify-content-center align-items-center"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          width: '30%',
        }}
        onConfirmation={() => (rh ? handleClicktoRH() : handleClickGeneric())}
        title="Iniciar Processo de Assinatura Eletronica"
        description="Deseja realmente iniciar o processo de assinatura ?."
      />
    </>
  );
}
