import React from 'react';
import {
  BsFillEmojiLaughingFill,
  BsFillEmojiNeutralFill,
  BsFillEmojiFrownFill,
} from 'react-icons/bs';
import { MdSpeakerNotes } from 'react-icons/md';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import TooltipGeneric from '../../../components/Tooltip';
import setStoreRedux from '../../../utils/setStore';
import customerProvider from '../../../providers/customer';
import DispatchButton from '../../../components/Buttons/DispatchButton';
import ButtonGeneric from '../../../components/Button';
import NewActiviteContactProvider from '../../../providers/NewActiveContacts';
import { compareDates } from '../Onboarding/waitingOnboardingColumns';
import ScheduleCheckBox from './ScheduleCheckBox';

const renderHSCell = (customer) => {
  const icons = {
    success: (
      <BsFillEmojiLaughingFill size={22} className="text-success me-1" />
    ),
    warning: (
      <BsFillEmojiNeutralFill size={22} color="orange" className="me-1" />
    ),
    danger: <BsFillEmojiFrownFill size={22} className="text-danger me-1" />,
  };

  const handleModalNotes = async () => {
    const getCustomer = await customerProvider.getById(customer.id);
    setStoreRedux('SET_CUSTOMER', {
      ...getCustomer,
    });
    setStoreRedux('SET_MODAL_STATE', {
      modalAnnotations: {
        isOpen: true,
      },
    });
  };

  let color = '';
  let icon = null;

  if (+customer.healthScore >= 70) {
    color = 'success';
  } else if (+customer.healthScore >= 50) {
    color = 'warning';
  } else {
    color = 'danger';
  }

  icon = icons[color];

  return (
    <div className="d-flex align-items-center p-0 m-0 font-weight-bold">
      {icon}
      <p className={`text-${color} p-0 m-0`}>{customer.healthScore}</p>
      {customer?.tags && (
        <TooltipGeneric title="Cliente Inadimplente" placement="right">
          <p
            className="p-0 m-0 cursor-pointer"
            style={{
              marginLeft: '2px',
            }}
          >
            🚩
          </p>
        </TooltipGeneric>
      )}
      <TooltipGeneric title="Anotações" placement="right">
        <p className="p-0 m-0">
          <MdSpeakerNotes
            className={`text-${color} ms-2 cursor-pointer`}
            size={20}
            onClick={() => handleModalNotes()}
          />
        </p>
      </TooltipGeneric>
    </div>
  );
};

const renderCustomerCell = (name, id) => {
  return (
    <Link
      className="d-flex align-items-center text-decoration-none text-dark"
      to={`/customers/${id}`}
    >
      {name}
    </Link>
  );
};

const generateNextMeeting = (nextActiveContactDate) => (
  <p
    style={{ fontSize: '12px' }}
    className={
      dayjs().isAfter(nextActiveContactDate, 'day') ? 'text-danger fw-bold' : ''
    }
  >
    {dayjs(nextActiveContactDate).format('DD/MM/YYYY')}
  </p>
);

const activeContactColumns = [
  {
    name: 'HS',
    selector: (row) => renderHSCell(row),
    sortFunction: (a, b) => {
      return a.healthScore - b.healthScore;
    },
    sortable: true,
    maxWidth: '12%',
    center: true,
  },
  {
    name: 'Clientes',
    cell: (row) => {
      return renderCustomerCell(row.name, row.customerId || row.id);
    },
    sortFunction: (a, b) => {
      const aValue = a.name;
      const bValue = b.name;

      return aValue.localeCompare(bValue);
    },
    sortable: true,
  },
  {
    name: 'Data de inicio',
    selector: (row) => {
      if (row?.NewActiveContacts[0]?.startedAt)
        return dayjs(row?.NewActiveContacts[0]?.startedAt).format('DD/MM/YYYY');

      if (row?.NewActiveContacts[0]?.userContacts.length > 0) {
        return (
          <Link
            to={`/cs/activeContact/new/fup?newActiveContactId=${row.NewActiveContacts[0].id}&preStart=true`}
            target="_blank"
            // the danger let it red i want this one green
            className="text-success"
            onClick={() => {
              if (
                row?.NewActiveContacts[0]?.userContacts.some(
                  ({ user }) => !user?.phone || user?.phone === '',
                )
              ) {
                toast.error(
                  'Existe um usuário sem telefone, é necessário adicionar um telefone para iniciar o contato ativo',
                );
              }
            }}
          >
            Iniciar
          </Link>
        );
      }

      return (
        <ButtonGeneric
          variant="transparent"
          className="text-danger text-decoration-underline"
          onClick={() => {
            toast.error(
              'É necessário adicionar um usuário com telefone para iniciar o contato ativo',
            );
          }}
        >
          Iniciar
        </ButtonGeneric>
      );
    },
    sortFunction: (a, b) => {
      const aValue = a.NewActiveContacts[0]?.startedAt || '';
      const bValue = b.NewActiveContacts[0]?.startedAt || '';

      return aValue.localeCompare(bValue);
    },
  },
  {
    name: 'Data de término',
    selector: (row) =>
      row?.NewActiveContacts[0]?.finishedAt
        ? dayjs(row?.NewActiveContacts[0]?.finishedAt).format('DD/MM/YYYY')
        : 'Não foi finalizado',
  },
  {
    name: 'Data da próxima reunião',
    selector: (row) => {
      return generateNextMeeting(
        row.NewActiveContacts[0].nextContactDate ||
          row.NewActiveContacts[0]?.contactDate,
      );
    },
    sortFunction: (a, b) => {
      const aValue = a.NewActiveContacts[0]?.contactDate || '';
      const bValue = b.NewActiveContacts[0]?.contactDate || '';

      return compareDates(aValue, bValue);
    },
    sortable: true,
    maxWidth: '8%',
    center: true,
  },
  {
    name: 'Status',
    selector: (row) => {
      if (row?.NewActiveContacts[0]?.finishedAt) {
        return (
          <p
            style={{
              fontSize: '12px',
              color: 'green',
            }}
          >
            Finalizado
          </p>
        );
      }

      return (
        <DispatchButton
          dispatchKey="SET_MODAL_STATE"
          variant="dark"
          size="sm"
          style={{ fontSize: '12px' }}
          disabled={row?.NewActiveContacts[0]?.finishedAt}
          payload={{
            modalPauseActiveContact: {
              isOpen: true,
              customerId: row.customerId || row.id,
              newActiveContactId: row.NewActiveContacts[0].id,
              isPaused: !!row?.NewActiveContacts[0]?.pausedAt,
            },
          }}
        >
          {row?.NewActiveContacts[0]?.pausedAt ? 'Continuar' : 'Pausar'}
        </DispatchButton>
      );
    },
  },
  {
    name: 'Agendado',
    selector: (row) => <ScheduleCheckBox row={row} />,
    sortable: true,
    sortFunction: (a, b) => {
      const sDateA = a.NewActiveContacts[0]?.scheduleDate || '';
      const sDateB = b.NewActiveContacts[0]?.scheduleDate || '';

      return sDateA.localeCompare(sDateB);
    },
  },
  {
    name: 'Responsável',
    selector: (row) => (
      <DispatchButton
        dispatchKey="SET_MODAL_STATE"
        variant="dark"
        size="sm"
        style={{ fontSize: '12px' }}
        payload={{
          currentUserBackoffice: row.NewActiveContacts[0]?.User,
          currentActiveContact: row.NewActiveContacts
            ? row?.NewActiveContacts[0]
            : row?.customer.NewActiveContacts[0],
          modalEditResponsible: {
            isOpen: true,
          },
        }}
      >
        {row.NewActiveContacts[0].User.name}
      </DispatchButton>
    ),
    sortFunction: (a, b) => {
      const aValue = a.NewActiveContacts[0]?.User?.name || '';
      const bValue = b.NewActiveContacts[0]?.User?.name || '';

      return aValue.localeCompare(bValue);
    },
    sortable: true,
    maxWidth: '13%',
    center: true,
  },

  {
    name: 'Ações',
    minWidth: '300px',
    cell: (row) => (
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          gap: '5px',
        }}
      >
        {row?.NewActiveContacts[0]?.startedAt && (
          <Link
            className="me-2"
            to={`/cs/activeContact/new/fup?newActiveContactId=${row.NewActiveContacts[0].id}`}
            target="_blank"
          >
            Editar
          </Link>
        )}

        <Link
          to={`/cs/activeContact/new?customerId=${
            row.customerId || row.id
          }&newActiveContactId=${row.NewActiveContacts[0]?.id}&userId=${
            row.NewActiveContacts[0]?.userContacts[0]?.userId || ''
          }`}
        >
          Chat
        </Link>
        <ButtonGeneric
          size="sm"
          onClick={async () => {
            const formatedNumber = `+${
              row.NewActiveContacts[0]?.userContacts[0]?.user?.countryCode ||
              '55'
            }${
              row.NewActiveContacts[0]?.userContacts[0]?.user?.phone
                ? row.NewActiveContacts[0]?.userContacts[0]?.user?.phone.replace(
                    /\D/g,
                    '',
                  )
                : ''
            }`;
            window.location.href = `skype:${formatedNumber}?call`;
            await NewActiviteContactProvider.makeSkypeCall(
              row.NewActiveContacts[0]?.id,
            );
          }}
        >
          Skype
        </ButtonGeneric>
        <DispatchButton
          dispatchKey="SET_MODAL_STATE"
          variant="dark"
          size="sm"
          style={{ fontSize: '12px' }}
          payload={{
            modalAddUser: {
              isOpen: true,
              customerId: row.customerId || row.id,
              newActiveContactId: row.NewActiveContacts[0].id,
              activeContactUsers: row.NewActiveContacts[0].userContacts,
              newActiveContact: row.NewActiveContacts[0],
            },
          }}
        >
          Adicionar usuários
        </DispatchButton>
      </div>
    ),
  },
];

export default activeContactColumns;
