/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { Row, Col, Nav, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ButtonGeneric from '../../components/Button';
import BigFileInput from '../../components/Inputs/Input_BigFile';
import FormGeneric from '../../components/Form';
import s3 from '../../providers/urlGenerator';
import signProvider from '../../providers/sign';
import Table from '../../components/Table/Table';
import signedColumns from './signedColumns';
import pendingColumns from './pendingColumns';
import pendingCurrentSignerColumns from './pendingCurrentSignerColumns';
import ModalGeneric from '../../components/Modals/ModalGeneric';

export default function UploadPDFForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [onlySign, setOnlySign] = useState();
  const [signNow, setSignNow] = useState(false);

  const [isFileSelected, setIsFileSelected] = useState(false);

  const {
    showModalPadSign,
    where,
    pendingDocs,
    signedDocs,
    pendingCurrentSigner,
    pagePending,
    pagePendingToSign,
    pageSigned,
    limit,
    totalPending,
    totalSigned,
    totalPendingToSign,
    file,
  } = useSelector((state) => state.signReducer);

  const getSignedDocs = async (pages, type) => {
    const docs = await signProvider.getSignedDocs({
      where,
      limit,
      page: pages,
    });
    dispatch({
      type: 'SET_SIGN_LIST',
      payload: {
        signedDocs: docs.signedDocs,
        totalSigned: docs.totalDocs,
      },
    });
  };

  const getPendingDocs = async (pages) => {
    const docs = await signProvider.getPendingDocs({
      where,
      limit,

      page: pages,
    });
    dispatch({
      type: 'SET_SIGN_LIST',
      payload: {
        pendingDocs: docs?.pendingDocs,
        totalPending: docs.totalDocs,
      },
    });
  };

  const handlePagePending = async (pageNumber) => {
    dispatch({ type: 'SET_PAGE', payload: { pagePending: pageNumber } });
    await getPendingDocs(pageNumber);
  };

  const handlePagePendingToSign = async (pageNumber) => {
    dispatch({ type: 'SET_PAGE', payload: { pagePendingToSign: pageNumber } });
    await getDocsToSign(pageNumber);
  };

  const handlePageSigned = async (pageNumber) => {
    dispatch({ type: 'SET_PAGE', payload: { pageSigned: pageNumber } });
    await getSignedDocs(pageNumber);
  };

  const getDocsToSign = async (pages, type) => {
    const docs = await signProvider.getDocsToSign({
      where,
      limit,
      page: pages,
    });
    dispatch({
      type: 'SET_SIGN_LIST',
      payload: {
        pendingCurrentSigner: docs.docsToSign,
        totalPendingToSign: docs.totalDocs,
      },
    });
  };

  const downloadDocs = async (row) => {
    const { url } = await signProvider.getUrlView({
      where: {
        id: row.doc[0].documentId,
      },
    });

    return s3.downloadFileByUrl(url, row.doc[0].document.fileName);
  };

  const getHash = async (row) => {
    const i = await signProvider.getHash(row);

    const filename = row.doc[0].document.fileName;

    const fileNameParsedToPDF = `${filename.replace(/\.[^/.]+$/, '')}.pdf`;

    await s3.downloadFileByUrl(i.newPdfUrl.signedUrl, fileNameParsedToPDF);
    return i;
  };

  const siningPendingDocs = async (row) => {
    navigate(
      `/sign/onlysigning/${row.doc.id}/${row.signatory.id}/${row.docSignatureId}`,
    );
  };

  const askToSender = async (data) => {
    dispatch({
      type: 'SET_FILE',
      payload: {
        data,
      },
    });

    await openModal();
  };
  const openModal = async () => {
    dispatch({
      type: 'SET_SHOW_MODAL',
      payload: { showModalPadSign: true },
    });
    return true;
  };

  const handleFiles = async (attachments) => {
    return Promise.all(
      attachments?.map(async (file) => {
        const uploaders = await s3.uploadFile(file);
        return uploaders;
      }),
    );
  };

  const uploadFile = async () => {
    if (!file.data.isExternal) {
      if (file?.data?.pdfFile?.length === 0) {
        return toast.error('Adicione um arquivo PDF!');
      }
    }

    dispatch({
      type: 'SET_SHOW_MODAL',
      payload: { showModalPadSign: false },
    });
    let uploaders = [];
    if (file?.data?.isExternal === false || !file?.data?.isExternal) {
      uploaders = await handleFiles(file?.data?.pdfFile);
    } else {
      uploaders = {
        awsFileKey: file.data.awsFileKey,
        fileSize: file.data.fileSize,
        fileType: file.data.fileType,
        fileName: file.data.fileName,
      };
    }
    const data = {
      ...uploaders,
      file,
      onlySign,
      isExternal:
        file.data?.isExternal === true ? file.data?.isExternal : false,
    };

    const doc = await signProvider.insertSignDocuments(data);

    dispatch({
      type: 'SET_FILE_URL',
      payload: {
        fileURL: doc.url,
      },
    });
    if (signNow === true) {
      return navigate(
        `/sign/onlysigning/${doc.documentId}/${doc.signatoryId}/${doc.docSignatureId}`,
      );
    }

    return navigate(
      `/sign/signPDFPage/${doc.documentId}/${doc.signatoryId}/${doc.docSignatureId}`,
    );
  };
  useEffect(() => {
    getSignedDocs();
    getPendingDocs();
    getDocsToSign();
    setOnlySign(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row>
      <Row md={12} className="d-flex justify-content-center">
        <FormGeneric
          className="d-flex justify-content-center mt-5 mb-5"
          onSubmit={askToSender}
        >
          <Col md={8} className="d-flex flex-column jusitfy-content-center">
            <Row className="mt-6">
              <h2 className="mb-4">Minha área de assinatura ✍️</h2>
              <h6>
                Arraste ou procure um arquivo .pdf ou .docx que deseja assinar.
              </h6>
            </Row>
            <BigFileInput
              label="Adicionar arquivo(s)"
              placeholder="PDF ou DOCX"
              name="pdfFile"
              maxFiles={1}
              className="cursor pointer"
              textSuport="Clique aqui para adicionar arquivo"
              onChange={setIsFileSelected}
            />

            <Col className=" text-center justify-content-center">
              <ButtonGeneric disabled={!isFileSelected} className="mt-4 p-3">
                ➕ Enviar Documento
              </ButtonGeneric>
            </Col>
          </Col>
        </FormGeneric>
      </Row>
      <ModalGeneric
        show={showModalPadSign}
        close={() => {
          dispatch({
            type: 'SET_SHOW_MODAL',
            payload: { showModalPadSign: false },
          });
        }}
        className="w-100 h-100"
        style={{
          width: '70%',
        }}
      >
        <FormGeneric
          className="d-flex justify-content-center mt-5 mb-5"
          onSubmit={uploadFile}
        >
          <Row className="d-flex justify-content-center text-justify" md={12}>
            <Row>
              <Col sm={12} className="text-center mb-4">
                <h4>Escolha uma das opções abaixo</h4>
              </Col>
            </Row>
            <Row
              sm={12}
              className="d-flex justify-content-center justify-content-around"
            >
              <Col
                xl={3}
                className=" flex-column align-items-center justify-content-between card p-3 border rounded shadow"
              >
                <h5 className="text-center">Vai assinar sozinho?</h5>

                <ButtonGeneric
                  id="btncreatePJ"
                  onClick={() => {
                    // eslint-disable-next-line no-unused-expressions, no-sequences
                    setOnlySign(false), setSignNow(true);
                  }}
                  type="submit"
                  className=" w-100 p-3 text-white"
                >
                  Apenas eu vou assinar este documento
                </ButtonGeneric>
              </Col>

              <Col
                xl={3}
                className="flex-column align-items-center justify-content-between card p-3 border rounded shadow"
              >
                <h6 className="text-center mb-3">
                  Você e outras pessoas vão assinar?
                </h6>
                <ButtonGeneric
                  id="btncreateCLT"
                  onClick={() => setOnlySign(false)}
                  className="w-100 p-3 text-white"
                  type="submit"
                >
                  Vou assinar e enviar este documento para outras pessoas
                  assinarem
                </ButtonGeneric>
              </Col>

              <Col
                xl={3}
                className=" flex-column align-items-center justify-content-between card p-3 border rounded shadow"
              >
                <h6 className="text-center">
                  Vai enviar para assinatura de outras pessoas?
                </h6>

                <ButtonGeneric
                  id="btncreatePJ"
                  onClick={() => setOnlySign(true)}
                  type="submit"
                  className="w-100 p-3 text-white"
                >
                  Apenas outras pessoas vão assinar este documento
                </ButtonGeneric>
              </Col>
            </Row>
          </Row>
        </FormGeneric>
      </ModalGeneric>

      <Row className="mt-5 justify-content-center mt-5">
        <Col xxl={8} xl={12} md={12} lg={12} sm={12} custom={12}>
          <Tab.Container defaultActiveKey="pendingCurrentSigner">
            <Nav
              variant="tabs"
              className="mb-3 justify-content-center table-sign "
            >
              <Nav.Item>
                <Nav.Link
                  eventKey="pendingCurrentSigner"
                  className="text-dark fw-bold"
                  style={{ fontSize: '0.9rem' }}
                >
                  Aguardando sua Assinatura
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="contractedDocuments"
                  className="text-dark fw-bold"
                  style={{ fontSize: '0.9rem' }}
                >
                  Aguardando Assinatura de Outros
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="signedDocuments"
                  className="text-dark fw-bold"
                  style={{ fontSize: '0.9rem' }}
                >
                  Documentos Assinados
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="pendingCurrentSigner">
                <Table
                  className="mt-5"
                  columns={pendingCurrentSignerColumns(siningPendingDocs)}
                  data={pendingCurrentSigner}
                  paginationDefaultPage={pagePendingToSign}
                  paginationTotalRows={totalPendingToSign}
                  onChangePage={handlePagePendingToSign}
                  itemsPerPage={limit}
                  pagination
                  paginationServer
                  listReducerKey="signReducer"
                  unStyled
                />
              </Tab.Pane>
              <Tab.Pane eventKey="contractedDocuments">
                <Table
                  className="mt-5"
                  columns={pendingColumns()}
                  data={pendingDocs.map((doc) => ({ ...doc, key: doc.id }))}
                  paginationDefaultPage={pagePending}
                  paginationTotalRows={totalPending}
                  onChangePage={handlePagePending}
                  itemsPerPage={limit}
                  pagination
                  paginationServer
                  listReducerKey="signReducer"
                  unStyled
                />
              </Tab.Pane>
              <Tab.Pane eventKey="signedDocuments">
                <Table
                  className="mt-5"
                  // onSort={handleSort}
                  columns={signedColumns(downloadDocs, getHash)}
                  data={signedDocs}
                  paginationDefaultPage={pageSigned}
                  paginationTotalRows={totalSigned}
                  onChangePage={handlePageSigned}
                  itemsPerPage={limit}
                  pagination
                  paginationServer
                  listReducerKey="signReducer"
                  unStyled
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
    </Row>
  );
}
