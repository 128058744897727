/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TitlePage from '../../../components/Pages/TitlePage';
import MainPage from '../../../components/Pages/MainPage';
import ButtonGeneric from '../../../components/Button';
import DateUtils from '../../../utils/DateUtils';
import CardForContent from '../../../components/CardForContent';
import Table from '../../../components/Table/Table';
import VisibilityOnIcon from '../../../components/customIcons/VisibilityOnIcon';
import s3 from '../../../providers/urlGenerator';
import DownloadIcon from '../../../components/customIcons/DownloadIcon';

import WarningIcon from '../../../components/customIcons/WarningIcon';
import PrivacyWarnings from '../../../providers/PrivacyWarnings';

export default function ToolsPrivacyWarning() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const viewAnswers = async (row) => {
    navigate(`form/${row?.id}`);
  };
  const DownloadButton = async (row) => {
    const { signedUrl } = await s3.getSignedDownloadFileUrl(
      row?.document?.awsFileKey,
    );

    s3.downloadFileByUrl(signedUrl, row?.document?.fileName);
  };

  const columnsDocuments = [
    {
      name: 'Nome do Arquivo',

      selector: (row) => row?.id,
      sortable: false,
    },
    {
      name: 'Enviado em',
      selector: (row) => DateUtils.formatToLocaleWithHour(row?.createdAt),
      sortable: false,
    },
    {
      name: 'Ações',
      selector: (row) => {
        return (
          <>
            <span onClick={() => DownloadButton(row)}>
              <DownloadIcon padded={false} />
            </span>
            <span onClick={() => viewAnswers(row)}>
              <VisibilityOnIcon padded={false} />
            </span>
          </>
        );
      },
    },
  ];

  const customerMapping = useSelector((state) => {
    return state?.privacyWarning?.selectedPrivacy;
  });

  const fetch = async () => {
    const privacyWarnings = await PrivacyWarnings.getAll({
      where: {},
      limit: 10,
    });

    dispatch({
      type: 'SET_SELECTED_PRIVACY',
      payload: privacyWarnings?.data,
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <TitlePage
        title={
          <Row className="m-0">
            <Col className="p-0 m-0">
              <h3>Aviso de Privacidade e Políticas de Cookies</h3>
            </Col>
            <Col xs="auto">
              <ButtonGeneric
                onClick={() => navigate('form')}
                className="text-white"
              >
                Reponder formulario
              </ButtonGeneric>
            </Col>
          </Row>
        }
        border="none"
        item="Ferramentas"
      />

      <div className="mt-3" />
      <MainPage>
        <CardForContent
          height=""
          Icon={WarningIcon}
          title="Aviso de Privacidade e Políticas de Cookies realizados"
          hasCard={false}
          className="ps-2 pe-2"
        >
          <Table
            data={customerMapping}
            className="mt-5"
            columns={columnsDocuments}
            pagination
            paginationServer
            listReducerKey="privacyWarning"
            unStyled
          />
        </CardForContent>
      </MainPage>
    </>
  );
}
