/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import DemandsStreak from '../../components/DemandsStreak';
import DEMAND_CONSTANTS from '../../constants/demands';
import DemandsStatistics from '../../components/DemandsStatistics';
import InputSelect from '../../components/Inputs/Input_Select';
import { searchBackofficeParams } from '../../utils/params';
import userProvider from '../../providers/user';

export default function BusinessDemandBoard() {
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const customerId = queryParam.get('customerId');
  const businessId = queryParam.get('businessId');
  const [currLawyer, setCurrLawyer] = React.useState(null);
  const [arrayBackofficeUsers, setArrayBackofficeUsers] = React.useState([]);

  useEffect(() => {
    userProvider
      .getAll({ ...searchBackofficeParams })
      .then((responseUsersBackoffice) => {
        setArrayBackofficeUsers(responseUsersBackoffice.rows || []);
      });
  }, []);

  const streaksByDemandStatus = Object.values(
    DEMAND_CONSTANTS.DEMAND_STATUS_KEYS,
  )
    .filter((status) => {
      return status.orderInPanel !== undefined;
    })
    .sort((a, b) => {
      return a.orderInPanel - b.orderInPanel;
    })
    .map((status) => {
      let fetchFilter = [
        {
          key: 'statusKey',
          value: status.key,
        },
        {
          key: 'customerId',
          value: customerId,
        },
      ];

      if (currLawyer) {
        fetchFilter.push({
          key: 'backofficeUserId',
          value: currLawyer,
        });
      } else {
        fetchFilter = fetchFilter.filter(
          (filter) => filter.key !== 'backofficeUserId',
        );
      }

      if (businessId) {
        fetchFilter.push({
          key: 'businessId',
          value: businessId,
        });
      }
      return {
        title: status.description,
        fetchFilter,
      };
    });

  const reportOf = businessId ? 'Business' : 'Customer';
  const interestedId = businessId || customerId;

  return (
    <>
      <Col xs="4" className="p-2">
        <InputSelect
          label="Advogado"
          name="backofficeUserId"
          options={[
            {
              name: 'Todos',
              id: null,
            },
            ...arrayBackofficeUsers,
          ].map((user) => ({
            label: user.name,
            value: user.id,
          }))}
          placeholder="Filtrar por advogado"
          onChange={(value) => {
            setCurrLawyer(value);
          }}
        />
      </Col>
      <Row
        style={{ height: 'calc(100% - 48px)' }}
        className="m-0  flex-row flex-nowrap overflow-scroll"
      >
        {streaksByDemandStatus.map((streak) => (
          <DemandsStreak
            checkedLast={false}
            title={streak.title}
            fetchFilter={streak.fetchFilter}
            pageLimit={9999999}
          />
        ))}
        <DemandsStatistics
          reportOf={reportOf}
          interestedId={interestedId}
          streaksToGenerateStatistics={streaksByDemandStatus}
        />
      </Row>
    </>
  );
}
