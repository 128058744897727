import React from 'react';
import TitlePage from '../../../components/Pages/TitlePage';
import DemandsList from '../../../components/DemandsList';
import DemandFilters from '../../../components/DemandFilters';

export default function CustomerListDemands() {
  return (
    <>
      <TitlePage title="Demandas" border="none" />
      <DemandFilters />
      <div className="mt-4" />
      <DemandsList isCollapsable />
    </>
  );
}
