/* eslint-disable no-alert */
import React, { useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { MdDelete } from 'react-icons/md';
import { IoClose } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ButtonGeneric from '../../Button';
import DEMAND_CONSTANTS from '../../../constants/demands';
import DateUtils from '../../../utils/DateUtils';
import ModalConfirmation from '../ModalConfirmation';
import CustomButton from '../../CustomButton';
import demandsProvider from '../../../providers/demands';

function InfoText({ title, content, onClick, id }) {
  return (
    <ButtonGeneric
      type="button"
      variant="white"
      style={{
        padding: '0',
        margin: '0',
        border: 'none',
      }}
      onClick={onClick}
      id={id || ''}
    >
      <div
        style={{
          fontSize: '0.8rem',
          marginLeft: '1rem',
        }}
      >
        <p className="p-0 m-0">{title}</p>
        <p className="p-0 m-0">{content}</p>
      </div>
    </ButtonGeneric>
  );
}

export default function HeaderModalDemand({ ...props }) {
  const [openModalDeleteDemand, setOpenModalDeleteDemand] = useState(false);
  const auth = useSelector((state) => state.auth.auth);
  const demand = useSelector((state) => state.demand.data);
  const dispath = useDispatch();
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);

  const updateDemand = async () => {
    const demandResponse = await demandsProvider.getDemandById(demand?.id);
    dispath({
      type: 'SET_DEMANDS_STATE',
      payload: {
        data: {
          demand,
          ...demandResponse,
        },
      },
    });
  };

  async function setPinned(pinned) {
    let confirmation = false;
    if (pinned === true) {
      confirmation = window.confirm(
        'Tem certeza que deseja fixar o SLA dessa demanda ? ao fixar ela deixará de ter seu SLA otimizado automaticamente',
      );
    } else {
      confirmation = window.confirm(
        'Tem certeza que deseja desfixar o SLA dessa demanda ? ao desfixar ela poderá ter seu SLA otimizado automaticamente',
      );
    }
    if (confirmation) {
      await demandsProvider
        .updateDemand(demand?.id, {
          pinned,
        })
        .then((res) => {
          toast.success('Fixação atualizada com sucesso.');
          updateDemand();
        });
    }
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modalType = useSelector((state) => state.modalsDemand.modalType);

  const closeModal = () => {
    queryParam.delete('demandId');
    const newUrl = `${location.pathname}?${queryParam.toString()}`;
    navigate(newUrl, { replace: true });
  };

  return (
    <Navbar>
      <Navbar.Collapse>
        <Nav>
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex flex-wrap">
            {demand?.preJourneyTypeId && (
              <CustomButton
                bg="#35722f"
                onClick={() => {
                  window.open(
                    `/journeys/types/${demand.preJourneyTypeId}`,
                    '_blank',
                  );
                }}
              >
                Pré Jornada
              </CustomButton>
            )}
            {demand?.journeyId && (
              <CustomButton
                bg="#D15000"
                onClick={() => {
                  window.open(
                    `/journeys/demands-panel?customerId=${demand?.customerId}&journeyId=${demand?.journeyId}`,
                    '_blank',
                  );
                }}
              >
                Jornada
              </CustomButton>
            )}
            <CustomButton
              bg={
                DEMAND_CONSTANTS.DEMAND_STATUS_KEYS[
                  demand?.statusKey.toUpperCase()
                ]?.color
              }
              className="pe-none"
            >
              {
                DEMAND_CONSTANTS.DEMAND_STATUS_KEYS[
                  demand?.statusKey.toUpperCase()
                ]?.description
              }
            </CustomButton>
            {demand?.private && (
              <CustomButton
                className="pe-none"
                bg="#800080"
                id="privateDemandMarker"
              >
                Sigilosa
              </CustomButton>
            )}
            {demand?.customer.observations && (
              <CustomButton
                bg="#3A85F7"
                onClick={() => {
                  if (modalType !== 'Detalhes') {
                    dispatch({
                      type: 'STATE_MODAL_DEMAND',
                      payload: { modalType: 'Detalhes' },
                    });
                  } else {
                    dispatch({
                      type: 'STATE_MODAL_DEMAND',
                      payload: { modalType: 'Mensagens' },
                    });
                  }
                }}
              >
                Observações
              </CustomButton>
            )}
            <InfoText
              title="Título"
              content={`${demand?.externalId} - ${demand?.name}`}
              onClick={() => {
                dispath({
                  type: 'STATE_MODAL_DEMAND',
                  payload: { modalType: 'Alterar título' },
                });
              }}
              id="demandTitle"
            />
            {demand?.statusKey?.toUpperCase() !==
            DEMAND_CONSTANTS.DEMAND_STATUS_KEYS.OPEN.key ? (
              <InfoText
                title="Atribuído a"
                content={demand?.backofficeUser?.name || 'indefinido'}
                onClick={() => {
                  dispath({
                    type: 'STATE_MODAL_DEMAND',
                    payload: { modalType: 'Alterar Responsável' },
                  });
                }}
                id="demandLawyer"
              />
            ) : (
              <CustomButton
                bg="#C2986A"
                onClick={() => {
                  dispath({
                    type: 'STATE_MODAL_DEMAND',
                    payload: { modalType: 'Definir Responsável' },
                  });
                }}
                id="setResponsibleButton"
              >
                Definir Responsável
              </CustomButton>
            )}
            {demand?.statusKey?.toUpperCase() !==
              DEMAND_CONSTANTS.DEMAND_STATUS_KEYS.FINISHED.key &&
              (demand?.internDeliveryForecast ? (
                <InfoText
                  title="SLA interno"
                  content={DateUtils.formatToLocale(
                    demand?.internDeliveryForecast,
                  )}
                  id="internalSlaButton"
                  onClick={() => {
                    dispath({
                      type: 'STATE_MODAL_DEMAND',
                      payload: { modalType: 'Alterar SLA Interno' },
                    });
                  }}
                />
              ) : (
                <CustomButton
                  bg="#C2986A"
                  onClick={() => {
                    dispath({
                      type: 'STATE_MODAL_DEMAND',
                      payload: { modalType: 'Alterar SLA Interno' },
                    });
                  }}
                >
                  Definir SLA Interno
                </CustomButton>
              ))}

            {demand?.statusKey?.toUpperCase() ===
              DEMAND_CONSTANTS.DEMAND_STATUS_KEYS.IN_PROGRESS.key && (
              <CustomButton
                bg="#00C95F"
                onClick={() => {
                  dispath({
                    type: 'STATE_MODAL_DEMAND',
                    payload: {
                      modalType: 'Alterar status',
                      status: DEMAND_CONSTANTS.DEMAND_STATUS_KEYS.FINISHED.key,
                    },
                  });
                }}
                id="finishDemandButton"
              >
                Finalizar Demanda
              </CustomButton>
            )}
            {demand?.checked ? (
              <CustomButton
                bg="#FFA500"
                onClick={async () => {
                  await demandsProvider
                    .updateDemand(demand?.id, {
                      checked: false,
                    })
                    .then((res) => {
                      toast.success('Demanda atualizada com sucesso.');
                    });
                  dispath({
                    type: 'SET_DEMANDS_STATE',
                    payload: {
                      data: {
                        ...demand,
                        checked: false,
                      },
                    },
                  });
                }}
                id="uncheckDemandButton"
              >
                Marcar como não triado
              </CustomButton>
            ) : (
              <CustomButton
                bg="#FFA500"
                onClick={async () => {
                  await demandsProvider
                    .updateDemand(demand?.id, {
                      checked: true,
                    })
                    .then((res) => {
                      toast.success('Demanda atualizada com sucesso.');
                    });
                  dispath({
                    type: 'SET_DEMANDS_STATE',
                    payload: {
                      data: {
                        ...demand,
                        checked: true,
                      },
                    },
                  });
                }}
                id="checkDemandButton"
              >
                Marcar como triado
              </CustomButton>
            )}

            <CustomButton
              bg="#FF6347"
              onClick={async () => {
                if (demand?.backofficeUserId === auth?.id) {
                  toast.error(
                    'Você é responsável pela demanda, não pode desativar as notificações.',
                  );
                } else {
                  await demandsProvider.addOrRemoveUserFollowingToDemand(
                    demand?.id,
                  );
                  updateDemand();
                }
              }}
              id="notificationButton"
            >
              {demand?.following?.includes(auth?.id)
                ? 'Notificações ativadas'
                : 'Notificações desativadas'}
            </CustomButton>
            {demand?.pinned ? (
              <CustomButton
                bg="#262626"
                onClick={() => setPinned(false)}
                id="unfixSlaButton"
              >
                Desfixar SLA
              </CustomButton>
            ) : (
              <CustomButton
                bg="#262626"
                onClick={() => setPinned(true)}
                id="fixSlaButton"
              >
                Fixar SLA
              </CustomButton>
            )}
            {demand?.demandTypeName ===
              DEMAND_CONSTANTS.DEMAND_TYPES.ROPA_MEETING.description && (
              <CustomButton className="pe-none" bg="#FF1493">
                ROPA
              </CustomButton>
            )}
          </ul>
        </Nav>
      </Navbar.Collapse>
      <div
        className="ml-auto"
        style={{
          minWidth: '4rem',
        }}
      >
        <MdDelete
          className="text-danger hover-pointer"
          size={30}
          onClick={() => setOpenModalDeleteDemand(true)}
        />
        <IoClose
          className="hover-pointer"
          size={30}
          id="closeModalDemand"
          onClick={() => {
            closeModal();
          }}
        />
      </div>
      <ModalConfirmation
        open={openModalDeleteDemand}
        close={() => setOpenModalDeleteDemand(false)}
        onConfirmation={async () => {
          await demandsProvider.deleteDemand(demand?.id);
          setOpenModalDeleteDemand(false);
          closeModal();
        }}
        title="Excluir Demanda"
        description="Tem certeza que deseja excluir essa demanda ? Essa ação não poderá ser desfeita!"
      />
    </Navbar>
  );
}
