import { store } from '..';
import DocumentProvider from '../../providers/documentProvider';

const whereWithinVariable = (obj) => [
  {
    key: 'fieldType',
    value: 'ACCOUNTING_FIRM',
    operator: 'eq',
  },
  obj,
];

const accountingActions = {
  fetchBackofficeSent: async ({ businessId, customerId }) => {
    const { sent } = store.getState().backofficeFinanceReceived;
    const sentWhere = Array.isArray(sent.where) ? sent.where : [];

    const querySent = {
      where: [
        ...sentWhere,
        ...whereWithinVariable({
          key: 'senderType',
          value: 'BACKOFFICE',
          operator: 'eq',
        }),
      ],
      limit: sent.limit,
      order: sent.order,
      page: sent.page,
    };

    const responseCustomer = await DocumentProvider.getAll({
      ...querySent,
      fieldType: 'ACCOUNTING_FIRM',
      businessId,
      customerId,
    });
    store.dispatch({
      type: 'SET_SENT_BACKOFFICE_DOCUMENT_LIST',
      payload: responseCustomer,
    });
  },
  fetchBackofficeReceived: async ({ businessId, customerId }) => {
    const { received } = store.getState().backofficeFinanceReceived;

    const receivedWhere = Array.isArray(received.where) ? received.where : [];

    const queryReceived = {
      where: [
        ...receivedWhere,
        ...whereWithinVariable({
          key: 'senderType',
          value: 'CUSTOMER',
          operator: 'eq',
        }),
      ],
      limit: received.limit,
      order: received.order,
      page: received.page,
    };

    const responseCustomer = await DocumentProvider.getAll({
      ...queryReceived,
      fieldType: 'ACCOUNTING_FIRM',
      businessId,
      customerId,
    });
    store.dispatch({
      type: 'SET_RECEIVED_BACKOFFICE_DOCUMENT_LIST',
      payload: responseCustomer,
    });
  },
};

export default accountingActions;
