import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';

const dataTableHooks = {
  useDataTableHooks: ({ listReducerKey, fetchFunction }) => {
    const list = useSelector((state) => state[listReducerKey]);

    const debouncedFetchFunction = debounce(fetchFunction, 200);

    useEffect(() => {
      debouncedFetchFunction();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list.page, list.limit, list.where, list.order]);
  },
};

export default dataTableHooks;
