import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

import { useDispatch } from 'react-redux';
import TitlePage from '../../../components/Pages/TitlePage';
import MainPage from '../../../components/Pages/MainPage';
import ButtonGeneric from '../../../components/Button';
import FileInput from '../../../components/Inputs/Input_File';
import FormGeneric from '../../../components/Form';

import schemaUrlGenerate from '../../../validators/schemas/urlGenerate';
import s3 from '../../../providers/urlGenerator';

export default function Url() {
  const [link, setLink] = useState([]);
  const dispatch = useDispatch();
  const handleFileChange = async (data) => {
    const listLink = await Promise.all(
      data?.files?.map(async (file) => {
        const generateUrl = await s3.linkGenerator({
          fileType: file?.type,
          fileName: file?.name,
          publicRead: true,
          file,
        });

        return { url: generateUrl, fileName: file?.name };
      }),
    );
    listLink.length > 0 && setLink(listLink);
    if (listLink.length > 0) {
      dispatch({
        type: 'LOADED',
        payload: { loading: 0, percentage: 0 },
      });
    }
  };

  return (
    <>
      <TitlePage
        title="Gerador de URL"
        item="Ferramenta"
        description="Esta página é uma ferramenta simples e eficiente que tem como objetivo oferecer a você a possibilidade de gerar uma URL personalizada para qualquer arquivo que você enviar. Com essa funcionalidade, você poderá compartilhar seus arquivos com facilidade e segurança, garantindo que apenas as pessoas com o link específico possam acessá-los."
      />
      <MainPage>
        <FormGeneric onSubmit={handleFileChange} schema={schemaUrlGenerate}>
          <Form.Label>Anexos</Form.Label>
          <FileInput name="files" />
          <div className="pt-3 d-flex justify-content-end">
            <ButtonGeneric variant="outline-dark" type="submit">
              Enviar
            </ButtonGeneric>
          </div>
        </FormGeneric>

        {link.map((item, index) => (
          <a
            key={item?.url}
            href={item?.url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-dark ms-3"
          >
            {index + 1} - {item?.fileName}
          </a>
        ))}
      </MainPage>
    </>
  );
}
