import Api from '../../providers/index';
import { store } from '..';

const PrivacyWarningActions = {
  getAll: async (params) => {
    const { data } = await Api.get('/privacy-warning', { params });
    store.dispatch({ type: 'SET_PRIVACY_LIST', payload: data });
    return data;
  },
  getById: async (id) => {
    const { data } = await Api.get(`/privacy-warning/${id}`);
    store.dispatch({ type: 'SET_SELECTED_PRIVACY', payload: data });
    return data;
  },
  getSelf: async (id) => {
    const { data } = await Api.get(`/privacy-warning/self`);
    store.dispatch({ type: 'SET_SELECTED_PRIVACY', payload: data });
    return data;
  },
  create: async (privacy) => {
    const { data } = await Api.post('/privacy-warning', {
      ...privacy,
    });
    store.dispatch({ type: 'SET_SELECTED_PRIVACY', payload: data });
    return data;
  },
  update: async (privacy) => {
    const { data } = await Api.patch(`/privacy-warning/${privacy.id}`, privacy);
    store.dispatch({ type: 'SET_SELECTED_PRIVACY', payload: data });
    return data;
  },
  delete: async (privacy) => {
    const { data } = await Api.delete(`/privacy-warning/${privacy.id}`);
    store.dispatch({ type: 'RESET_PRIVACY_LIST' });
    return data;
  },
};

export default PrivacyWarningActions;
