import Api from '.';

const PrivacyWarnings = {
  insertAnswerQuestions: async (data) => {
    const response = await Api.post('/privacy-warning/insertAnswerQuestions', {
      data,
    });
    return response;
  },

  getAll: async (query) => {
    const response = await Api.get('/privacy-warning/', { params: query });
    return response;
  },
  getONePrivacyQuestions: async (query) => {
    const response = await Api.get('/privacy-warning/getONePrivacyQuestions', {
      params: query,
    });
    return response;
  },
  getOnePrivacyWarning: async (query) => {
    const response = await Api.get('/privacy-warning/getOnePrivacyWarning', {
      params: query,
    });
    return response;
  },

  // getOnePrivacyWarning
};

export default PrivacyWarnings;
