/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useRef } from 'react';
import { Col, Row, Dropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import InputCheckbox from '../../components/Inputs/Input_Checkbox';
import signProvider from '../../providers/sign';
import TitlePage from '../../components/Pages/TitlePage';
import MainPage from '../../components/Pages/MainPage';
import PDFViewerComponent from './PDFViwerComponent';
import InputGeneric from '../../components/Inputs/Input_generic';
import ButtonGeneric from '../../components/Button';
import SignaturePad from './SignatureCanvas';
import PlusIcon from '../../components/customIcons/PlusIcon';
import FormGeneric from '../../components/Form';
import ModalGeneric from '../../components/Modals/ModalGeneric';
import userProvider from '../../providers/user';

export default function SignPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [signBenny, setSignBenny] = useState(false);
  const [signLuiz, setSignLuiz] = useState(false);
  const { id, signId, docSignatureId, onlySign } = useParams();
  const { fileURL } = useSelector((state) => state.signReducer);
  const [show, setShowSignList] = useState(false);
  const [showSign, setDropSign] = useState(false);
  const [signatureURL, setSignatureURL] = useState(null);
  const [coordinates, setCoordinates] = useState([]);
  const [awsFileKey, setawsFileKey] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [zoom, setZoom] = useState(1.5);
  const [docId, setDocumentId] = useState(id);
  const { signatorysList, showModalSignatorys, showModalPadSign } = useSelector(
    (state) => state.signReducer,
  );

  const increaseZoom = () => setZoom((prevZoom) => prevZoom + 0.5);
  const decreaseZoom = () =>
    setZoom((prevZoom) => Math.max(prevZoom - 0.5, 0.5));

  const pdfViewerRef = useRef({});
  const signaturePadRef = useRef({});

  const handleUploadFile = async () => {
    if (signLuiz || signBenny) {
      setShowConfirmationModal(true);
    } else {
      await uploadFile();
    }
  };

  const addRecipient = (data) => {
    if (data.name && data.email) {
      dispatch({
        type: 'SET_SIGNATORYS_LIST',
        payload: {
          newSignatory: {
            name: data.name,
            email: data.email,
          },
        },
      });
      toast.success('Signatário adicionado !');
      dispatch({
        type: 'SET_SHOW_MODAL',
        payload: { showModalSignatorys: false },
      });
      return;
    }

    toast.error('Erro ao adicionar signatario.');
  };

  const removeRecipient = (index) => {
    dispatch({
      type: 'REMOVE_SIGNATORY',
      payload: { index },
    });
    return toast.success('Signatario Removido');
  };

  const uploadFile = async () => {
    const requestData = {
      docSignatureId,
      documentId: docId,
      signatorysList,
    };
    if (signatureURL === 'onlySender') {
      await signProvider.sendSignatoryEmails(requestData);
      toast.success('Documento Enviado');
    }
    if (signatureURL !== 'onlySender') {
      await signProvider.sendSignatoryEmails(requestData);
      const { error } = await signProvider.confirmSignature({
        signId,
        docId,
        awsFileKey,
        signBenny: signBenny || null,
        signLuiz: signLuiz || null,
        coordinates,
      });
      if (error) {
        return toast.error(error);
      }
      toast.success('Documento Assinado e Enviado');
    }
    dispatch({
      type: 'SET_SIGN_LIST',
      payload: {
        signatorysList: [],
      },
    });
    navigate(`/sign/`);
    return true;
  };
  // eslint-disable-next-line consistent-return
  const getPrevious = async () => {
    if (coordinates.length > 0) {
      const versions = await signProvider.getPreviousDocumentVersions({
        where: {},
        signId,
        coordinates,
        docId,
      });

      setDocumentId(versions.documentId);
      setawsFileKey(versions.awsFileKey);
      setCoordinates(() => [...versions.coordinate]);
      return dispatch({
        type: 'SET_FILE_URL',
        payload: { fileURL: versions.url },
      });
    }
  };
  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const geturl = async () => {
      const userType = await userProvider.userLogged();
      setUser(userType.type);
      if (fileURL === null || !fileURL || fileURL) {
        dispatch({
          type: 'SET_FILE_URL',
          payload: { fileURL: null },
        });

        const url = await signProvider.getUrlView({
          where: {
            id: docId,
          },
        });
        return dispatch({
          type: 'SET_FILE_URL',
          payload: { fileURL: url },
        });
      }
    };

    geturl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchSignImg = async () => {
      const response = await signProvider.getSignImage({
        where: {
          id: signId,
        },
      });
      if (response.onlySender) {
        setSignatureURL('onlySender');
      }
      if (response && response.imageUrl) {
        setSignatureURL(response.imageUrl);
      }
    };
    fetchSignImg();
  }, [signId]);

  useEffect(() => {}, [fileURL]);
  const handlePositionSelected = async ({ x, y, pageNumber, zooms }) => {
    let newCoordinates = {};
    if (signatureURL) {
      if (x >= 500) {
        newCoordinates = {
          x: x - 300,
          y,
          pageNumber,
          zooms,
          key: coordinates.length + 1,
          signBenny: signBenny || null,
          signLuiz: signLuiz || null,
        };
      } else {
        newCoordinates = {
          x,
          y,
          pageNumber,
          zooms,
          key: coordinates.length + 1,
          signBenny: signBenny || null,
          signLuiz: signLuiz || null,
        };
      }

      const updatedCoordinates = [...coordinates, newCoordinates];
      setCoordinates(updatedCoordinates);

      const { url, error, documentId, awsKey } =
        await signProvider.drawSignature({
          where: { id: docId },
          coordinates: updatedCoordinates,
          awsFileKey,
          signId,
          docSignatureId,
        });

      setDocumentId(documentId);
      setawsFileKey(awsKey);

      if (error) {
        toast.error('Erro ao adicionar assinatura.');
      } else {
        toast.success('Assinatura adicionada com sucesso.');
        dispatch({
          type: 'SET_FILE_URL',
          payload: { fileURL: url },
        });
      }
    } else {
      toast.error(
        'Por favor, crie uma assinatura antes de selecionar o local.',
      );
    }
  };

  return (
    <>
      <TitlePage
        title="NDM Sign"
        description="Ferramenta para assinatura eletronica de documentos."
        className="p-4 border-bottom border-dark"
      />

      <MainPage>
        <div>
          <Row
            sm={12}
            className="df-flex text-center justify-content-between p-0 m-0 border-bottom"
          >
            <Col sm={6} className="d-flex flex-column align-items-center">
              <Dropdown className="w-100">
                <Col className="d-flex w-100 justify-content-center align-items-center">
                  {/* <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Dropdown Button
                    </Dropdown.Toggle> */}
                  {(!onlySign || onlySign === undefined) && (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>
                      {signatorysList.length > 0 ? (
                        <Dropdown.Toggle
                          id="dropdown-menu-align-responsive-1"
                          className="d-flex text-white w-50 align-items-center justify-content-between"
                          type="button"
                        >
                          <span className="me-5">Ver signatários</span>
                        </Dropdown.Toggle>
                      ) : (
                        <Dropdown.Toggle
                          id="dropdown-menu-align-responsive-1"
                          className="d-flex text-white w-50 align-items-center justify-content-between"
                          type="button"
                        >
                          <span className="me-5">Adicionar Signatários</span>
                        </Dropdown.Toggle>
                      )}
                    </>
                  )}
                </Col>
                <Col className="d-flex justify-content-center align-items-center mt-2">
                  <Dropdown.Menu
                    show={show}
                    align="center"
                    title="Adicionar signatarios"
                    // id="dropdown-menu-align-responsive-1"
                    className=" "
                    style={{
                      top: '2%',
                      position: 'absolute',
                      zIndex: '1000',
                    }}
                  >
                    {signatorysList.map((recipient, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() =>
                          toast.info(`Selecionado: ${recipient.name}`)
                        }
                        className="d-flex flex-column flex-md-row align-items-md-center "
                        style={{ gap: '5px' }}
                      >
                        <div>
                          <strong>{recipient?.name}</strong> -{' '}
                          {recipient?.email}
                        </div>
                        <ButtonGeneric
                          variant="outline-danger"
                          size="sm"
                          onClick={() => removeRecipient(index)}
                          className="ms-2"
                        >
                          Remover
                        </ButtonGeneric>
                      </Dropdown.Item>
                    ))}
                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={() => {
                        // eslint-disable-next-line no-unused-expressions
                        dispatch({
                          type: 'SET_SHOW_MODAL',
                          payload: { showModalSignatorys: true },
                          // eslint-disable-next-line no-sequences
                        }),
                          setShowSignList(false);
                      }}
                      className="d-flex align-items-center"
                    >
                      <PlusIcon className="me-2" /> Adicionar Signatário
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Col>
              </Dropdown>
            </Col>

            <Col sm={6} className="">
              {signatureURL !== 'onlySender' && (
                <ButtonGeneric
                  onClick={() => setDropSign((prevShow) => !prevShow)}
                  className="text-white text-center w-50 "
                  type="button"
                  id="createSignature"
                >
                  {signatureURL ? 'Editar Assinatura' : 'Criar Assinatura'}
                </ButtonGeneric>
              )}
              <Col className="d-flex  justify-content-center align-items-center mt-5">
                <Dropdown.Menu
                  show={showSign}
                  align="center"
                  title="Adicionar signatarios"
                  className="w-50 mt-5"
                  style={{
                    top: '2%',
                    position: 'absolute',
                    zIndex: '1000',
                  }}
                >
                  {signatureURL && (
                    <Dropdown.Item className="d-flex justify-content-center">
                      {signatureURL !== 'onlySender' && (
                        <img
                          src={signatureURL}
                          className="border"
                          alt="Assinatura do usuário"
                          style={{
                            width: '150px',
                            height: '113px',
                          }}
                        />
                      )}
                    </Dropdown.Item>
                  )}

                  <Dropdown.Divider />
                  <Dropdown.Item
                    onClick={() => {
                      // eslint-disable-next-line no-unused-expressions
                      dispatch({
                        type: 'SET_SHOW_MODAL',
                        payload: {
                          showModalPadSign: true,
                        },
                        // eslint-disable-next-line no-sequences
                      }),
                        setDropSign(false),
                        setShowSignList(false);
                    }}
                    className="d-flex align-items-center"
                  >
                    <PlusIcon className="me-2" /> Editar Assinatura
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Col>
            </Col>

            <Row sm={12} className="d-flex  ">
              {coordinates.length > 0 &&
                signatorysList.length > 0 &&
                user === 'BACKOFFICE' && (
                  <>
                    <InputCheckbox
                      label="Assinar como Luiz"
                      name="admSign"
                      checked={signLuiz}
                      onChange={(e) => {
                        setSignLuiz(e); // Limpa a assinatura do administrador
                      }}
                      className="d-flex justify-content-end  w-50"
                    />

                    <InputCheckbox
                      label="Assinar como Benny"
                      name="admSign"
                      checked={signBenny}
                      onChange={(e) => {
                        setSignBenny(e); // Limpa a assinatura do administrador
                      }}
                      className="d-flex justify-content-start w-50"
                    />
                  </>
                )}
            </Row>
          </Row>

          <Row
            sm={12}
            className="d-flex justify-content-start d-flex align-items-center mt-5"
          >
            <Col sm={4} className="  text-center d-flex justify-content-end">
              <ButtonGeneric onClick={decreaseZoom}>-</ButtonGeneric>
              <ButtonGeneric className="ms-3 me-3" onClick={increaseZoom}>
                +
              </ButtonGeneric>
            </Col>

            <Col className="text-center d-flex justify-content-start" sm={2}>
              <ButtonGeneric onClick={() => getPrevious()}>
                Desfazer
              </ButtonGeneric>
            </Col>
            <Col sm={4} className="  text-center d-flex justify-content-center">
              <FormGeneric
                onSubmit={(e) => {
                  handleUploadFile();
                }}
                className="w-100 d-flex justify-content-center align-items-center"
              >
                {signatureURL !== 'onlySender' ? (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {coordinates.length > 0 && signatorysList.length > 0 ? (
                      <ButtonGeneric
                        className="w-50"
                        type="submit"
                        style={{
                          top: '10%',
                          left: '70%',
                          // position: 'absolute',
                        }}
                      >
                        Assinar e Enviar
                      </ButtonGeneric>
                    ) : (
                      <p>
                        Clique na area do documento para posicionar a
                        assinatura.
                      </p>
                    )}
                  </>
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {signatorysList.length > 0 ? (
                      <ButtonGeneric
                        className="w-50"
                        type="submit"
                        style={{
                          top: '10%',
                          left: '70%',
                          // position: 'absolute',
                        }}
                      >
                        Enviar
                      </ButtonGeneric>
                    ) : (
                      <p>Adicione no minimo um signtario</p>
                    )}
                  </>
                )}
              </FormGeneric>
            </Col>
          </Row>

          <Row className="">
            <PDFViewerComponent
              zoom={zoom}
              signatureURL={signatureURL}
              ref={pdfViewerRef}
              onMouseOverEvent={handlePositionSelected}
            />
          </Row>
        </div>

        <ModalGeneric
          show={showModalSignatorys}
          close={() => {
            dispatch({
              type: 'SET_SHOW_MODAL',
              payload: { showModalSignatorys: false },
            });
          }}
        >
          <FormGeneric onSubmit={addRecipient} className="">
            <Row className="justify-content-center gap-4 text-justify">
              <h4 className="text-center mb-3">
                Informe o nome e email do Signatário
              </h4>

              <InputGeneric
                type="text"
                name="name"
                label="Nome do destinatário"
                helpText="Adicione o nome do destinatário"
              />
              <InputGeneric
                name="email"
                type="email"
                label="Email do destinatário"
                helpText="Adicione o email do destinatário"
              />

              <ButtonGeneric type="submit">
                Adicionar Destinatário
              </ButtonGeneric>
            </Row>
          </FormGeneric>
        </ModalGeneric>

        <ModalGeneric
          show={showModalPadSign}
          close={() => {
            dispatch({
              type: 'SET_SHOW_MODAL',
              payload: { showModalPadSign: false },
            });
          }}
          className="d-flex w-100 h-100 align-items-center"
          style={{
            height: '600px',
          }}
        >
          <SignaturePad
            ref={signaturePadRef}
            onSave={(url) => setSignatureURL(url)}
            onClose={() => {
              dispatch({
                type: 'SET_SHOW_MODAL',
                payload: { showModalPadSign: false },
              });
            }}
          />
        </ModalGeneric>
        <ModalGeneric
          show={showConfirmationModal}
          close={() => setShowConfirmationModal(false)}
        >
          <Row className="justify-content-center gap-4 text-justify text-center">
            <h4 className="text-center mb-3">Confirmação de Assinatura</h4>
            <p>
              Você está prestes a assinar como Administrador. Deseja continuar?
            </p>
            <ButtonGeneric
              onClick={async () => {
                setShowConfirmationModal(false);
                await uploadFile();
              }}
            >
              Confirmar
            </ButtonGeneric>
            <ButtonGeneric
              variant="outline-danger"
              onClick={() => setShowConfirmationModal(false)}
            >
              Cancelar
            </ButtonGeneric>
          </Row>
        </ModalGeneric>
      </MainPage>
    </>
  );
}
