import React from 'react';
import Table from './Table';

export default function TableSection({
  title,
  description,
  doNotDisplayQuantity,
  data,
  columns,
  filters,
  onRowClicked = undefined,
  unStyled = false,
  fixedHeader = false,
  fixedHeaderScrollHeight = 'auto',
  onSort = undefined,
  sortServer = undefined,
}) {
  return (
    <>
      <h5 className="text-center fw-bold mt-5">{title}</h5>
      <p className="text-center">
        {!doNotDisplayQuantity && `Total: ${data?.length} ${description || ''}`}
      </p>
      {filters && <div className="mb-3">{filters}</div>}
      <div
        style={{
          boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.25)',
          height: data?.length > 10 ? '35rem' : 'auto',
          overflowY: data?.length > 10 ? 'auto' : 'hidden',
        }}
      >
        {data?.length > 0 ? (
          <Table
            data={data}
            columns={columns}
            onRowClicked={onRowClicked}
            unStyled={unStyled}
            fixedHeader={fixedHeader}
            fixedHeaderScrollHeight={fixedHeaderScrollHeight}
            onSort={onSort}
            sortServer={sortServer}
          />
        ) : (
          <p className="text-center text-info pb-4 pt-4">
            {`Nenhum ${title?.toLowerCase()}`}
          </p>
        )}
      </div>
    </>
  );
}
