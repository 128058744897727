import React from 'react';
import { IoDocumentOutline } from 'react-icons/io5';
import { DownloadButton } from '../../Documents/ActionsIndex';
import DateUtils from '../../../utils/DateUtils';

const documentsColumns = [
  {
    name: 'Nome do Arquivo',
    columnName: 'fileName',
    orderArray: ['fileName'],
    selector: (row) => (
      <span id={`document-name-${row?.fileName}`}>
        <IoDocumentOutline style={{ marginRight: '5px' }} size={16} />
        {row.DocumentsMetrics?.length > 0 ? (
          <span>{row?.fileName}</span>
        ) : (
          <strong>{row?.fileName}</strong>
        )}
      </span>
    ),
    sortable: true,
  },
  {
    name: 'Enviado em',
    maxWidth: '120px',
    columnName: 'createdAt',
    orderArray: ['createdAt'],
    selector: (row) => DateUtils.formatToLocale(row?.createdAt),
    sortable: true,
  },

  {
    name: 'Ações',
    columnName: 'actions',
    maxWidth: '200px',
    selector: (row) => (
      <div className="d-flex">
        <DownloadButton row={row} />
      </div>
    ),
  },
];

export default documentsColumns;
