import { toast } from 'react-toastify';
import Api from '.';

const healthScoreProvider = {
  getHealthScore: async ({ id, version }) => {
    try {
      const response = await Api.get(`/healthScore/${id}`, {
        params: { version },
      });
      return response.data;
    } catch (error) {
      toast.error(error?.message);
      return null;
    }
  },
};

export default healthScoreProvider;
