import React, { useEffect, useState } from 'react';
import { Button, Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { IoMdOptions } from 'react-icons/io';

import InputGeneric from './Inputs/Input_generic';
import InputSelect from './Inputs/Input_Select';
import userProvider from '../providers/user';
import DemandsTagProvider from '../providers/demandsTag';
import { defaultGetAllParams } from '../utils/params';
import ButtonGeneric from './Button';

import CloseIcon from './customIcons/CloseIcon';
import DemandCategoryProvider from '../providers/demandCategory';

export default function DemandFilters() {
  const [businessUsers, setBusinessUsers] = useState([]);
  const [demandsTags, setDemandsTags] = useState([]);

  const demandsList = useSelector((state) => state.demandsList);
  const dispatch = useDispatch();
  const demandCategoryReducer = useSelector(
    (state) => state.demandCategory.demandCategory,
  );
  const { screenSize } = useSelector((state) => state.layout);

  const initialFormState = {
    startDate: '',
    finalDate: '',
    userId: '',
    demandsTags: '',
    name: '',
    DemandProcessItemContent: '',
    categoryId: '',
    externalId: '',
    fullSearchText: '',
  };
  const [form, setForm] = useState(initialFormState);

  onchange = (e) => {
    const newFormData = { ...form, [e.target.name]: e.target.value };
    setForm(newFormData);
    onSubmit(newFormData);
  };

  const fetch = async () => {
    const demandTypes = await DemandCategoryProvider.getAllDemandCategories();
    dispatch({
      type: 'SET_DEMAND_CATEGORY_LIST',
      payload: {
        ...demandTypes,
      },
    });
  };

  useEffect(() => {
    fetch();
    userProvider.getUsersToBusiness().then((response) => {
      setBusinessUsers(
        response.map((e) => ({
          value: e.id,
          label: e.name,
        })),
      );
    });
    DemandsTagProvider.getAll(defaultGetAllParams).then((response) => {
      setDemandsTags(
        response.rows.map((e) => ({
          value: e.id,
          label: e.name,
        })),
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    const fieldsFilled = Object.keys(data).filter(
      (key) => data[key] && data[key] !== 'null',
    );

    const blankFields = Object.keys(data).filter(
      (key) => !data[key] || data[key] === 'null',
    );

    const whereToApply = fieldsFilled.map((key) => {
      if (key === 'userId') {
        return { key, value: data[key] };
      }
      if (key === 'demandsTags') {
        return { key, operator: 'contains', value: `{${data[key]}}` };
      }
      if (key === 'startDate') {
        return { key: 'createdAt', operator: 'gte', value: data[key] };
      }
      if (key === 'finalDate') {
        return { key: 'createdAt', operator: 'lte', value: data[key] };
      }
      if (key === 'name') {
        return { key, operator: 'iLike', value: data[key] };
      }
      if (key === 'fullSearchText') {
        return { key, operator: 'iLike', value: data[key] };
      }
      if (key === 'DemandProcessItemContent') {
        return {
          key: 'content',
          model: 'DemandProcessItems',
          operator: 'iLike',
          value: data[key],
        };
      }

      if (key === 'categoryId' && data[key] === 'X') {
        return { key: 'categoryId', operator: 'is', value: null };
      }

      if (key === 'categoryId') {
        return { key, operator: 'eq', value: data[key] };
      }

      if (key === 'externalId') {
        return { key, operator: 'eq', value: data[key] };
      }

      return { key, value: { [key]: data[key] } };
    });

    if (
      fieldsFilled.includes('startDate') &&
      fieldsFilled.includes('finalDate')
    ) {
      whereToApply.filter((e) => e.key !== 'createdAt');
      whereToApply.push({
        key: 'createdAt',
        operator: 'between',
        value: [data.startDate, data.finalDate],
      });
    }

    const demandListWhere = [...demandsList.where];

    const whereMerged = [...whereToApply];
    demandListWhere.forEach((e) => {
      if (blankFields.includes(e.key)) {
        return;
      }
      const index = whereMerged.findIndex((i) => i.key === e.key);

      if (index === -1) {
        whereMerged.push(e);
      }
    });

    return dispatch({
      type: 'SET_DEMANDS_LIST',
      payload: { where: whereMerged },
    });
  };

  const cleanReduxInsertedWhere = () => {
    setForm(initialFormState);
    onSubmit(initialFormState);
  };

  const optionsDemandCategories =
    demandCategoryReducer?.demandCategories
      ?.map((demandCategory) => ({
        value: demandCategory.id,
        label: demandCategory.name,
      }))
      ?.sort((a, b) => a.label.localeCompare(b.label)) || [];

  let widthToUse = '80%';

  if (screenSize === 'sm' || screenSize === 'xs' || screenSize === 'md') {
    widthToUse = '99%';
  }

  const allCategoryOptions = [
    {
      value: 'X',
      label: 'Sem categoria',
    },
    ...optionsDemandCategories,
  ];

  const popover = (
    <Popover
      id="popover-basic"
      style={{ maxWidth: widthToUse, width: widthToUse }}
    >
      <Popover.Header as="h3">Filtro avançado</Popover.Header>
      <Popover.Body as="div">
        <Row className="m-0 align-items-end">
          <Col xs="6" sm="4" className="pe-2 pt-2 pb-2">
            <InputGeneric
              type="date"
              label="Data inicial"
              name="startDate"
              value={form.startDate || ''}
              onChange={onchange}
            />
          </Col>
          <Col xs="6" sm="4" className="p-2">
            <InputGeneric
              type="date"
              label="Data final"
              name="finalDate"
              value={form.finalDate || ''}
              onChange={onchange}
            />
          </Col>
          <Col xs="6" sm="4" className="p-2">
            <InputSelect
              name="userId"
              options={businessUsers}
              label="Usuário"
              placeholder="Selecione"
              value={
                businessUsers.find(
                  (businessUser) => businessUser.value === form.userId,
                ) || ''
              }
              onChange={(e) =>
                onchange({
                  target: {
                    name: 'userId',
                    value: e,
                  },
                })
              }
            />
          </Col>
          <Col xs="6" className="p-2">
            <InputSelect
              name="demandsTags"
              options={demandsTags}
              label="Tags"
              placeholder="Selecione"
              value={
                demandsTags.find(
                  (demandsTag) => demandsTag.value === form.demandsTags,
                ) || ''
              }
              onChange={(e) =>
                onchange({
                  target: {
                    name: 'demandsTags',
                    value: e,
                  },
                })
              }
            />
          </Col>
          <Col xs="6" className="p-2">
            <InputGeneric
              label="Titulo"
              name="name"
              placeholder="digite o titulo"
              value={form.name || ''}
              onChange={onchange}
            />
          </Col>
          <Col xs="6" className="p-2">
            <InputGeneric
              label="Mensagens"
              name="DemandProcessItemContent"
              placeholder="Digite a mensagem"
              value={form.DemandProcessItemContent || ''}
              onChange={onchange}
            />
          </Col>
          <Col xs="6" className="p-2">
            <InputGeneric
              label="Nº Demanda"
              name="externalId"
              placeholder="Digite o Nº Demanda"
              value={form.externalId || ''}
              onChange={onchange}
            />
          </Col>
          <Col xs="6" className="p-2">
            <InputSelect
              name="categoryId"
              label="Categoria de demanda"
              options={allCategoryOptions}
              value={
                allCategoryOptions.find(
                  (category) => category.value === form.categoryId,
                ) || ''
              }
              onChange={(e) =>
                onchange({
                  target: {
                    name: 'categoryId',
                    value: e,
                  },
                })
              }
            />
          </Col>
        </Row>
      </Popover.Body>
    </Popover>
  );

  return (
    <Row className="m-0 align-items-end">
      <Col>
        <InputGeneric
          label="Pesquisa"
          name="fullSearchText"
          placeholder="Digite o texto para pesquisa"
          value={form.fullSearchText || ''}
          onChange={onchange}
        />
      </Col>

      <Col xs="auto" className=" ">
        <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
          <Button variant="primary">
            <IoMdOptions size={25} />
          </Button>
        </OverlayTrigger>
        <ButtonGeneric
          type="button"
          className="ms-2"
          onClick={() => {
            cleanReduxInsertedWhere();
          }}
          variant="danger"
        >
          <CloseIcon padded={false} fill="#ffffff" />
        </ButtonGeneric>
      </Col>
    </Row>
  );
}
