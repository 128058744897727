const dictionaryOfProps = {
  HAS_BEEN_MADE: {
    title: 'Realizada',
    textColor: '#76864f',
    backgroundColor: '#d4edbc',
  },
  DELAYED: {
    title: 'Atrasada',
    textColor: '#b61922',
    backgroundColor: '#ffcfc9',
  },
  CHURNED_BEFORE: {
    title: 'Churn - CS Fora da Hora',
    textColor: '#000000',
    backgroundColor: '#e8eaed',
  },
  CHURNED_AFTER: {
    title: 'Churn - CS Atrasado',
    textColor: '#713490',
    backgroundColor: '#e6cff2',
  },
  MADE_AND_CHURNED: {
    title: 'Churn - CS Realizado',
    textColor: '#000000',
    backgroundColor: '#ffe5a0',
  },
  NORMAL: {
    title: 'Ainda não realizada',
    textColor: '#000000',
    backgroundColor: '#e8eaed',
  },
};

export default dictionaryOfProps;
