/* eslint-disable import/prefer-default-export */
export const questions = [
  {
    title:
      'Os termos de uso e aviso de privacidade são para um site, aplicativo ou SaaS?',
    inputType: 'text',
  },
  {
    title:
      'Com qual nome vocês querem que a plataforma seja mencionada nos documentos?',
    inputType: 'text',
  },
  {
    title:
      'De qual forma vocês irão se comunicar com o usuário e o usuário também poderá falar com a empresa? Informe endereços e números específicos.',
    inputType: 'text',
  },
  {
    title:
      'Descreva de forma completa todos os serviços que serão prestados na plataforma.',
    inputType: 'text',
  },
  {
    title:
      'Caso a sua operação envolva terceiros, especifique o terceiro e defina quais são os serviços prestados por eles.',
    inputType: 'text',
  },
  {
    title:
      'Os usuários terão que criar uma conta de acesso (login e senha) para acessar a plataforma e os serviços?',
    inputType: 'select',
    options: [
      { label: 'Sim', value: 'Sim' },
      { label: 'Não', value: 'Não' },
    ],
  },
  {
    title:
      'Quais são os tipos de Usuários da plataforma e quais são suas funcionalidades?',
    inputType: 'text',
  },
  {
    title: 'O Usuário deverá pagar valores para usar a plataforma?',
    inputType: 'select',
    options: [
      { label: 'Sim', value: 'Sim' },
      { label: 'Não', value: 'Não' },
    ],
  },
  {
    title:
      'Caso a resposta anterior tenha sido SIM, como será feita essa cobrança? Explique de forma completa.',
    inputType: 'text',
  },
  {
    title:
      'Os usuários poderão fazer pagamentos diretamente pela plataforma ou serão redirecionados para outro endereço? Informe os meios de pagamento e sistemas de segurança.',
    inputType: 'text',
  },
  {
    title:
      'Serão feitas avaliações dentro da plataforma? Se sim, em que formato?',
    inputType: 'text',
  },
  {
    title: 'Como o usuário poderá cancelar o serviço? Será cobrada multa?',
    inputType: 'text',
  },
  {
    title:
      'Cite todos os dados que os usuários terão que fornecer diretamente para vocês, separados por tipo de usuário, se aplicável.',
    inputType: 'text',
  },
  {
    title: 'Explique a finalidade de cada um dos dados coletados.',
    inputType: 'text',
  },
  {
    title:
      'Além de cookies, haverá coleta de dados de forma indireta? Para que?',
    inputType: 'text',
  },
  {
    title:
      'Os dados dos clientes serão repassados para terceiros? Se sim, cite o parceiro, os dados compartilhados e o objetivo do repasse.',
    inputType: 'text',
  },
  {
    title:
      'Vocês monitorarão a localização dos usuários? Se sim, por qual razão?',
    inputType: 'text',
  },
  {
    title:
      'Como vocês irão garantir a segurança da plataforma e do armazenamento dos dados dos usuários?',
    inputType: 'text',
  },
  {
    title: 'Onde os servidores estão localizados?',
    inputType: 'select',
    options: [
      { label: 'Brasil', value: 'Brasil' },
      { label: 'Estados Unidos', value: 'Estados Unidos' },
      { label: 'Europa', value: 'Europa' },
      { label: 'Outro', value: 'Outro' },
    ],
  },
  {
    title:
      'Informe o canal de comunicação específico de privacidade e, se houver, o Encarregado de Dados.',
    inputType: 'text',
  },
  {
    title: 'Como o titular poderá solicitar a exclusão de seus dados pessoais?',
    inputType: 'text',
  },
  {
    title:
      'Vocês utilizam chat online? Qual? As conversas serão monitoradas e/ou armazenadas? Por qual motivo?',
    inputType: 'text',
  },
  {
    title:
      'Existe mais alguma informação importante que você gostaria que constasse nos documentos?',
    inputType: 'text',
  },
  {
    title: 'Quais os nomes dos cookies que são coletados no site?',
    inputType: 'text',
  },
  {
    title: 'Como vocês classificam cada um desses cookies?',
    inputType: 'select',
    options: [
      { label: 'Necessários', value: 'Necessários' },
      { label: 'Analíticos', value: 'Analíticos' },
      { label: 'Publicidade', value: 'Publicidade' },
    ],
  },
  {
    title: 'Quais são as empresas responsáveis por cada um desses cookies?',
    inputType: 'text',
  },
  {
    title: 'Qual é a finalidade de cada um desses cookies?',
    inputType: 'text',
  },
  {
    title: 'Por quanto tempo cada um desses cookies armazena dados?',
    inputType: 'text',
  },
  {
    title:
      'Vocês irão compartilhar os dados coletados por meio de cookies com terceiros?',
    inputType: 'text',
  },
];
