import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { HiOutlineIdentification } from 'react-icons/hi';

import { useSelector } from 'react-redux';

import InputCheckbox from '../../../components/Inputs/Input_Checkbox';
import InputMask from '../../../components/Inputs/Input_Mask';

export default function FormToPF() {
  const customer = useSelector((state) => state.customer);

  return (
    <>
      <Row>
        <Col>
          <InputMask
            type="cpf"
            name="cpf"
            label="CPF"
            placeholder="Digite o CPF do cliente"
            icon={HiOutlineIdentification}
            defaultValue={
              customer?.data?.cpf &&
              customer?.data?.cpf?.replace(
                /(\d{3})(\d{3})(\d{3})(\d{2})/,
                '$1.$2.$3-$4',
              )
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="fs-14 mb-2 mt-2 ms-1">Acesso a Ferramentas</p>
          <Row>
            <Col md={6}>
              <InputCheckbox
                name="customerAccess_Gerador de Documento"
                label="Gerador de Documento"
                defaultValue={customer?.data?.customerAccess?.includes(
                  'Gerador de Documento',
                )}
              />
            </Col>
            <Col md={6}>
              <InputCheckbox
                name="customerAccess_Mapeamento de dados"
                label="Mapeamento de dados"
                defaultValue={customer?.data?.customerAccess?.includes(
                  'Mapeamento de dados',
                )}
              />
            </Col>
            <Col md={6}>
              <InputCheckbox
                name="customerAccess_Registrar marcas"
                label="Registrar marcas"
                defaultValue={customer?.data?.customerAccess?.includes(
                  'Registrar marcas',
                )}
              />
            </Col>
            <Col md={6}>
              <InputCheckbox
                name="customerAccess_Análise Interna de Risco"
                label="Análise Interna de Risco"
                defaultValue={customer?.data?.customerAccess?.includes(
                  'Análise Interna de Risco',
                )}
              />
            </Col>
            <Col md={6}>
              <InputCheckbox
                name="customerAccess_Lembretes"
                label="Lembretes"
                defaultValue={customer?.data?.customerAccess?.includes(
                  'Lembretes',
                )}
              />
            </Col>
            <Col md={6}>
              <InputCheckbox
                name="customerAccess_Contabil Financeiro"
                label="Contabilidade"
                defaultValue={customer?.data?.customerAccess?.includes(
                  'Contabil Financeiro',
                )}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
