/* eslint-disable default-param-last */
const initialState = {
  loading: 0,
  percentage: 0,
};

const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING':
      if (action.payload?.percentage) {
        return {
          ...state,
          percentage: action.payload.percentage,
        };
      }
      return {
        ...state,
        loading: state.loading + 1,
      };
    case 'LOADED': {
      const newLoading = state.loading - 1 < 0 ? 0 : state.loading - 1;
      return {
        ...state,
        loading: newLoading,
        percentage: newLoading === 0 ? 0 : state.percentage,
      };
    }
    default:
      return state;
  }
};

export default loadingReducer;
