/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import dayjs from 'dayjs';
import newActiveContactActions from '../../../store/actions/newActiveContactActions';
import NewActiviteContactProvider from '../../../providers/NewActiveContacts';

import InputCheckbox from '../../../components/Inputs/Input_Checkbox';
// import FormGeneric from '../../../components/Form';

export default function ScheduleCheckBox({ row }) {
  const onChange = async () => {
    let date = null;

    if (!row?.NewActiveContacts[0]?.scheduleDate) {
      date = dayjs().toISOString();
    }

    await NewActiviteContactProvider.update({
      id: row?.NewActiveContacts[0].id,
      scheduleDate: date,
    });
    await newActiveContactActions.fetchNewActiveContacts();
  };

  return (
    <InputCheckbox
      onChange={() => {
        onChange();
      }}
      name="scheduleDate"
      defaultValue={row?.NewActiveContacts[0]?.scheduleDate}
    />
  );
}
