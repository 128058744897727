import {
  BsCalendar2WeekFill,
  BsFillArrowUpCircleFill,
  BsFillCalendarDateFill,
  BsFillChatLeftTextFill,
  BsFillClockFill,
  BsFillInfoSquareFill,
  BsFillUnlockFill,
} from 'react-icons/bs';
import { FaListCheck } from 'react-icons/fa6';
import { MdBusinessCenter, MdTitle } from 'react-icons/md';
import { BiSolidCategoryAlt, BiSolidMessageX } from 'react-icons/bi';
import { RiNotification3Fill, RiRouteLine } from 'react-icons/ri';
import { TbNumber } from 'react-icons/tb';

const DEMAND_CONSTANTS = {
  DEMAND_DESCRIPTIONS: {
    FIRST_CONTACT:
      'Olá, [nome do cliente]. Como vai?\nMeu nome é [seu nome], sou uma das advogadas da NDM e entro em contato para saber se podemos marcar uma reunião de contato ativo e conversar sobre a [nome da empresa]/o seu negócio/projeto.\nNosso objetivo com essa call é fazer o nosso ponto de contato trimestral, ver quais são as necessidades jurídicas da empresa no momento e também o que poderíamos acrescentar para vocês.\nVocê possui disponibilidade na [quarta-feira (18/08) às 10h00]? Se não, peço que me indique uma data e um horário que sejam melhores para você, para que eu possa me adaptar!\nAtenciosamente,',
    SUCCESIVE_CONTACTS:
      'Bom dia, XXXXXXXX, como está? Espero que esteja bem! Aqui é a xxxxxx, da NDM Advogados.\n\nEstou entrando em contato para agendarmos a reunião de contato ativo deste trimestre. Nesse momento vamos nos atualizar sobre a empresa e definir juntos qual será o objetivo principal de vocês para o trimestre que está se iniciando.\n\nVamos também conversar sobre outros pontos da empresa para ver se estão correndo algum risco jurídico ou se precisam de alguma adequação.\n\nSegue o link da minha agenda para você escolher o melhor dia e horário para você:\nXXXXXX\nFico no aguardo.',
    LOW_ENGAGEMENT:
      'Olá, (NOME DO CLIENTE).\nComo você está? Espero que bem.\nNotamos que não tivemos retorno de vocês nos últimos dias. O que acha de marcarmos uma reunião para alinharmos os próximos passos jurídicos da (NOME DA EMPRESA)? Essa reunião será importante para entendermos o momento de vocês e propor novas demandas que trarão mais segurança.\nTenho disponibilidade na (DATA E HORÁRIO). Caso não seja possível nesse dia e horário, gentileza indicar uma data e um horário que sejam melhores para você para que eu possa me adaptar.',
  },
  // continuar criando array de status
  CUSTOMER_DEMAND_STATUS: [
    {
      key: 'OPEN',
      color: '#EFAE00',
      description: 'Aberta',
      helpDescription:
        'São as demandas que foram recebidas pela NDM e estão em processo de triagem para o especialista.',
    },
    {
      key: 'IN_PROGRESS',
      color: '#00AF53',
      description: 'Em andamento',
      helpDescription:
        'Significa que o(a) advogado(a) especialista já está trabalhando para entregar a demanda no prazo estipulado.',
    },
    {
      key: 'PENDING',
      color: '#DA00A1',
      description: 'Pendentes',
      helpDescription:
        'Significa que você precisa nos retornar com alguma informação ou documento e sem isso não conseguimos prosseguir.',
    },
    {
      key: 'FINISHED',
      color: '#3A85F7',
      description: 'Entregues',
      helpDescription:
        'São as demandas que o nosso time já entregou e que foram encaminhadas para a sua aprovação.',
    },
    {
      key: 'BLOCKED',
      color: '#B40000',
      description: 'Bloqueada',
      helpDescription:
        'São as demandas que dependem ou estão aguardando a finalização de demandas anteriores, como nos casos das jornadas.',
    },
    {
      key: 'ALL',
      color: '#262626',
      description: 'Todas',
      helpDescription: 'Todas as demandas, independente do status.',
    },
  ],
  DEMAND_STATUS_KEYS: {
    OPEN: {
      key: 'OPEN',
      color: '#6C757D',
      description: 'Aberta',
      isKey: true,
      orderInPanel: 1,
    },
    IN_PROGRESS: {
      key: 'IN_PROGRESS',
      color: '#17A2B8',
      description: 'Em andamento',
      isKey: true,
      orderInPanel: 2,
    },
    EXPIRE_TODAY: {
      key: 'EXPIRE_TODAY',
      color: '#FEC52B',
      description: 'Vence hoje!',
      isKey: false,
    },
    EXPIRE_TOMORROW: {
      key: 'EXPIRE_TOMORROW',
      color: '#17A2B8',
      description: 'Vence amanhã!',
      isKey: false,
    },
    UNCHECKED: {
      color: '#2e2e2e',
      description: 'Não triada',
    },
    DELAYED: {
      key: 'DELAYED',
      color: '#ff4a55',
      description: 'Atrasado!',
      isKey: false,
    },
    INTERNAL_DELAYED: {
      key: 'INTERNAL_DELAYED',
      color: '#a1a500',
      description: 'Atraso interno!',
      isKey: false,
    },
    FINISHED: {
      key: 'FINISHED',
      color: '#28A745',
      description: 'Finalizada',
      isKey: true,
      orderInPanel: 3,
    },
    BLOCKED: {
      key: 'BLOCKED',
      color: '#DC3545',
      description: 'Bloqueada',
      isKey: true,
      orderInPanel: 4,
    },
    ALL: {
      key: 'ALL',
      color: '#262626',
      description: 'Todas',
      isKey: false,
      orderInPanel: 4,
    },
    PENDING: {
      key: 'PENDING',
      color: '#FFC107',
      description: 'Pendente',
      isKey: true,
      orderInPanel: 2,
    },
  },
  DEMAND_STATUS_KEYS_CUSTOMER: {
    OPEN: {
      key: 'OPEN',
      color: '#EFAE00',
      description: 'Aberta',
      isKey: true,
      orderInPanel: 1,
    },
    IN_PROGRESS: {
      key: 'IN_PROGRESS',
      color: '#00AF53',
      description: 'Em andamento',
      isKey: true,
      orderInPanel: 2,
    },
    EXPIRE_TODAY: {
      key: 'EXPIRE_TODAY',
      color: '#FEC52B',
      description: 'Vence hoje!',
      isKey: false,
    },
    EXPIRE_TOMORROW: {
      key: 'EXPIRE_TOMORROW',
      color: '#17A2B8',
      description: 'Vence amanhã!',
      isKey: false,
    },
    UNCHECKED: {
      color: '#2e2e2e',
      description: 'Não triada',
    },
    DELAYED: {
      key: 'DELAYED',
      color: '#ff4a55',
      description: 'Atrasado!',
      isKey: false,
    },
    INTERNAL_DELAYED: {
      key: 'INTERNAL_DELAYED',
      color: '#a1a500',
      description: 'Atraso interno!',
      isKey: false,
    },
    FINISHED: {
      key: 'FINISHED',
      color: '#3A85F7',
      description: 'Entregue',
      isKey: true,
      orderInPanel: 3,
    },
    BLOCKED: {
      key: 'BLOCKED',
      color: '#B40000',
      description: 'Bloqueada',
      isKey: true,
      orderInPanel: 4,
    },
    PENDING: {
      key: 'PENDING',
      color: '#DA00A1',
      description: 'Pendente',
      isKey: true,
      orderInPanel: 2,
    },
  },
  DEMAND_TYPES: {
    ONBOARDING_MEETING: {
      description: 'Reunião de Onboarding',
    },
    ACTIVE_CONTACT_MEETING: {
      description: 'Reunião de Contato Ativo',
    },
    ENGAGEMENT_MEETING: {
      description: 'Reunião de Acompanhamento',
    },
    CHURN_MEETING: {
      description: 'Reunião de Alinhamento',
    },
    ROPA_MEETING: {
      description: 'Geração do ROPA',
    },
  },
  MODALS_DEMAND: {
    TAGS: {
      key: 'TAGS',
      description: 'Tags do cliente',
    },
    DETAILS: {
      key: 'DETAILS',
      description: 'Detalhes',
      icon: BsFillInfoSquareFill,
      checked: {
        key: 'MESSAGE',
        description: 'Mensagens',
        icon: BsFillChatLeftTextFill,
      },
    },
    PREVISION_IN: {
      key: 'PREVISION_IN',
      description: 'Previsto para',
      icon: BsFillCalendarDateFill,
      notClicable: false,
    },
    CREATE_AT: {
      key: 'CREATE_AT',
      description: 'Criado em',
      icon: BsCalendar2WeekFill,
      notClicable: false,
    },
    UPDATE_STATUS: {
      key: 'UPDATE_STATUS',
      description: 'Alterar status',
      icon: FaListCheck,
    },
    SIGILOSE: {
      key: 'SIGILOSE',
      description: 'Tornar sigiloso',
      icon: BsFillUnlockFill,
    },
    CHANGE_BUSINESS: {
      key: 'CHANGE_BUSINESS',
      description: 'Alterar negócio',
      icon: MdBusinessCenter,
    },
    CHANGE_SLA: {
      key: 'CHANGE_SLA',
      description: 'Alterar SLA',
      icon: BsFillClockFill,
      render: (e) => e,
    },
    CHANGE_TYPE: {
      key: 'CHANGE_TYPE',
      description: 'Alterar tipo',
      icon: BiSolidCategoryAlt,
    },
    PRIORITIZE: {
      key: 'PRIORITIZE',
      description: 'Priorizar',
      icon: BsFillArrowUpCircleFill,
    },
    PRIVATE_MESSAGE: {
      key: 'PRIVATE_MESSAGE',
      description: 'Mensagem privada',
      icon: BiSolidMessageX,
      render: (e) => e,
    },
    CHANGE_TITLE: {
      key: 'CHANGE_TITLE',
      description: 'Alterar título',
      icon: MdTitle,
    },
    PROCESS_NUMBER: {
      key: 'PROCESS_NUMBER',
      description: 'Número de Processo',
      icon: TbNumber,
    },
    NOTIFY_USER: {
      key: 'NOTIFY_USER',
      description: 'Notificar Usuário',
      icon: RiNotification3Fill,
    },
    ADD_JOURNEY: {
      key: 'ADD_JOURNEY',
      description: 'Adicionar a Jornada',
      icon: RiRouteLine,
    },
  },
  TAGS_COLORS: {
    Inadimplente: 'danger',
    Premium: 'success',
  },
};

export const statusColorByKey = {
  OPEN: '#EFAE00',
  IN_PROGRESS: '#00AF53',
  PENDING: '#DA00A1',
  FINISHED: '#3A85F7',
  BLOCKED: '#B40000',
  ALL: '#262626',
};

export const orderStreakByLastAction = (sortDirection) => [
  { label: 'Sla Externo', name: 'deleveryForecast', sortDirection: 'noSort' },
  {
    label: 'Sla Interno',
    name: 'internDeliveryForecast',
    sortDirection: 'noSort',
  },
  {
    label: 'Última Atualização',
    name: 'lastBackofficeAction',
    sortDirection,
  },
];

export default DEMAND_CONSTANTS;
