/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import InputSelect from '../../components/Inputs/Input_Select';
import TitlePage from '../../components/Pages/TitlePage';
import MainPage from '../../components/Pages/MainPage';
import JourneyProvider from '../../providers/journey';
import InputGeneric from '../../components/Inputs/Input_generic';
import ButtonGeneric from '../../components/Button';
import schemaAddCustomerJourney from '../../validators/schemas/journey/addCustomerJourney';
import businessProvider from '../../providers/business';
import CustomerJourneyProvider from '../../providers/CustomerJourney';
import DemandTypeProvider from '../../providers/demandType';
import customerProvider from '../../providers/customer';
import {
  defaultGetAllParams,
  searchAllCustomersUnpaginatedParams,
  searchBackofficeParams,
} from '../../utils/params';
import userProvider from '../../providers/user';

export default function CreateCustomerJourney() {
  const demandTypesList = useSelector(
    ({ demandTypes }) => demandTypes?.demandTypes?.demandTypes || [],
  );
  const customersList = useSelector(
    ({ customerList }) => customerList.customerList?.customerList || [],
  );

  const [backofficeUserId, setBackofficeUserId] = React.useState(null);

  const businessList = useSelector((state) => state.businessList);

  const usersList = useSelector((state) => state.usersList);

  const users = useSelector(
    ({ backofficeList }) => backofficeList.backofficeList,
  );

  const customerSelected = useSelector((state) => state.customer);

  const journeyTypes = useSelector((state) => state.journey.listTypes);
  const selectedJourneyType = useSelector(
    (state) => state.journey.selectedJourneyType,
  );

  const dispatch = useDispatch();

  const fetch = async () => {
    const demandListTypes = await DemandTypeProvider.getAllDemandTypes();
    const response = await userProvider.getAll(searchBackofficeParams);
    dispatch({
      type: 'SET_BACKOFFICE_LIST',
      payload: {
        backofficeList: response.rows,
      },
    });
    dispatch({
      type: 'SET_DEMAND_TYPES_LIST',
      payload: {
        demandTypes: demandListTypes.demandTypes,
      },
    });
  };

  const fetchCustomer = async () => {
    const customersResponse = await customerProvider.getAll(
      searchAllCustomersUnpaginatedParams,
    );

    dispatch({
      type: 'SET_CUSTOMER_LIST',
      payload: { customerList: customersResponse.rows },
    });
  };

  const fetchBusiness = async (id) => {
    const businessCustomerResponse = await businessProvider.getByCustomerId(id);

    dispatch({
      type: 'SET_BUSINESS_LIST',
      payload: { rows: businessCustomerResponse },
    });
  };

  const fecthJourneyTypes = async () => {
    const journeyTypesResponse = await JourneyProvider.getJourneyTypes(
      defaultGetAllParams,
    );
    dispatch({
      type: 'SET_JOURNEY_STATE',
      payload: {
        listTypes: journeyTypesResponse.rows,
      },
    });
  };

  const fetchCustomerUsers = async (id) => {
    const usersResponse = await userProvider.getUsersToCustomer(id);

    dispatch({
      type: 'SET_USERS_LIST',
      payload: { rows: usersResponse },
    });
  };

  const fetchJourneyTypeDetails = async (id) => {
    const journeyTypeResponse = await JourneyProvider.getJourneyTypeById(id);

    dispatch({
      type: 'SET_SELECTED_JOURNEY_TYPE',
      payload: journeyTypeResponse,
    });
  };

  useEffect(() => {
    if (selectedJourneyType?.id) {
      fetchJourneyTypeDetails(selectedJourneyType.id);
    }
  }, [selectedJourneyType?.id]);

  useEffect(() => {
    fetch();
    fetchCustomer();
    fecthJourneyTypes();
  }, []);

  useEffect(() => {
    if (customerSelected?.id) {
      fetchBusiness(customerSelected.id);
      fetchCustomerUsers(customerSelected.id);
    }
  }, [customerSelected]);

  const onDemandDefaultChange = ({ e, index, demand }) => {
    const { name, value } = e.target || e;

    const newDemands = selectedJourneyType.defaultDemands.map(
      (demandItem, indexDemand) => {
        if (indexDemand === index) {
          if (name === 'title') {
            return { ...demandItem, title: value };
          }
          if (name === 'description') {
            return { ...demandItem, description: value };
          }
          return {
            ...demandItem,
            demandTypeId: value,
          };
        }
        return demandItem;
      },
    );

    dispatch({
      type: 'SET_SELECTED_JOURNEY_TYPE',
      payload: {
        ...selectedJourneyType,
        defaultDemands: newDemands,
      },
    });
  };

  const deleteDemandDefault = ({ index }) => {
    const newDemands = selectedJourneyType.defaultDemands.filter(
      (demand, indexDemand) => indexDemand !== index,
    );
    dispatch({
      type: 'LOADING',
    });

    dispatch({
      type: 'SET_SELECTED_JOURNEY_TYPE',
      payload: {
        ...selectedJourneyType,
        defaultDemands: newDemands,
      },
    });
    setTimeout(() => {
      dispatch({
        type: 'LOADED',
      });
    }, 500);
  };

  const addEmptyDemandDefault = () => {
    const newDemands = [
      ...selectedJourneyType.defaultDemands,
      {
        title: '',
        description: '',
        demandTypeId: '',
      },
    ];

    dispatch({
      type: 'SET_SELECTED_JOURNEY_TYPE',
      payload: {
        ...selectedJourneyType,
        defaultDemands: newDemands,
      },
    });
  };

  const navigate = useNavigate();

  const createCustomerJourney = async () => {
    if (
      selectedJourneyType.defaultDemands.some((demand) => !demand.demandTypeId)
    ) {
      toast.error(
        'Por favor, selecione um tipo de demanda para todas as demandas.',
      );
      return;
    }
    try {
      selectedJourneyType.defaultDemands.forEach((defaultDemand, index) => {
        try {
          schemaAddCustomerJourney.validateSync(defaultDemand);
        } catch (error) {
          toast.error(`Demanda ${index + 1}: ${error.message}`);
          throw error;
        }
      });
    } catch (error) {
      toast.error(`Demandas invalidas: ${error}`);
      return;
    }

    if (!backofficeUserId) {
      toast.error('Por favor, selecione um advogado responsável');
      return;
    }

    const request = {
      customerId: customerSelected.id,
      journeyTypeId: selectedJourneyType.id,
      journeyCustomerUserId: usersList.selectedUser.id,
      customerResponsibleId: usersList.selectedUser.id,
      following: [usersList.selectedUser.id],
      businessId: businessList.selectedBusiness.id,
      backofficeUserId,
      demands: selectedJourneyType.defaultDemands.map((defaultDemand) => {
        return {
          name: defaultDemand.title,
          description: defaultDemand.description,
          demandTypeId: defaultDemand.demandTypeId,
          demandTypeName: demandTypesList?.find(
            (demandType) => demandType.id === defaultDemand.demandTypeId,
          )?.name,
          demandUserId: usersList.selectedUser.id,
          customerId: customerSelected.id,
          businessId: businessList.selectedBusiness.id,
          following: [usersList.selectedUser.id],
        };
      }),
    };

    const customerJourney = await CustomerJourneyProvider.create(request);

    navigate(
      `/journeys/demands-panel?customerId=${customerSelected.id}&customerJourneyId=${customerJourney.id}`,
    );
  };
  return (
    <div>
      <TitlePage title="Criar Jornada para um Cliente" item="Jornadas" />
      <MainPage>
        <InputSelect
          options={customersList?.map((customer) => ({
            value: customer.id,
            label: customer.name,
          }))}
          placeholder="Selecione um Cliente"
          name="customerId"
          label="Cliente*"
          onChange={async (id) => {
            const customerInSelect = customersList.find(
              (customer) => customer.id === id,
            );

            dispatch({
              type: 'SET_CUSTOMER',
              payload: customerInSelect,
            });
          }}
        />

        {customerSelected?.id && (
          <InputSelect
            options={businessList?.rows?.map((business) => ({
              value: business.id,
              label: business.name,
            }))}
            placeholder="Selecione um negócio"
            name="businessId"
            label="Negócio do cliente*"
            onChange={async (id) => {
              const businessInSelect = businessList.rows.find(
                (business) => business.id === id,
              );

              dispatch({
                type: 'SET_SELECTED_BUSINESS',
                payload: businessInSelect,
              });
            }}
          />
        )}

        {businessList.selectedBusiness?.id && (
          <InputSelect
            options={journeyTypes?.map((journeyType) => ({
              value: journeyType.id,
              label: journeyType.title,
            }))}
            placeholder="Jornada"
            name="journeyId"
            label="Selecione um tipo de jornada*"
            onChange={async (id) => {
              const journeyTypeInSelect = journeyTypes.find(
                (journeyType) => journeyType.id === id,
              );

              dispatch({
                type: 'SET_SELECTED_JOURNEY_TYPE',
                payload: journeyTypeInSelect,
              });
            }}
          />
        )}
        {selectedJourneyType?.id && (
          <InputSelect
            options={usersList.rows?.map((user) => ({
              value: user.id,
              label: user.name,
            }))}
            placeholder="Usuário"
            name="userId"
            label="Selecione um usuário*"
            onChange={async (id) => {
              const userSelected = usersList.rows.find(
                (user) => user.id === id,
              );

              dispatch({
                type: 'SET_SELECTED_USER',
                payload: userSelected,
              });
            }}
          />
        )}
        {usersList.selectedUser?.id && (
          <div className="mt-2">
            <Row>
              <h5>Demandas base</h5>
              <p>
                Essas demandas abaixo serão as criadas inicialmente. Somente a
                primeira será criada no status ABERTA, as restantes serão todas
                criadas com o status BLOQUEADA. Você poderá editar a ordem das
                demandas após a criação da jornada.
              </p>
            </Row>
            <Row>
              <InputSelect
                name="backofficeUserId"
                label="Advogado Responsavel pela Jornada*"
                placeholder="Selecione o Advogado Responsável pela Jornada"
                options={users?.backofficeList
                  ?.map((e) => ({
                    value: e.id,
                    label: e.name,
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label))}
                onChange={(e) => setBackofficeUserId(e)}
              />
            </Row>
            {selectedJourneyType?.defaultDemands?.map((demand, index) => {
              return (
                <div className="mt-2">
                  <Row>
                    <span className="align-text-bottom">
                      Demanda {index + 1}{' '}
                      <MdDelete
                        className="hover-pointer"
                        size={22}
                        color="#D10000"
                        onClick={() => deleteDemandDefault({ index })}
                      />
                    </span>

                    <Col>
                      <InputGeneric
                        label="Título*"
                        name="title"
                        defaultValue={demand.title}
                        onChange={(e) =>
                          onDemandDefaultChange({ demand, index, e })
                        }
                      />
                    </Col>
                    <Col>
                      <InputSelect
                        options={demandTypesList?.map((demandType) => ({
                          value: demandType.id,
                          label: demandType.name,
                        }))}
                        placeholder="Tipo de demanda"
                        value={{
                          value: demand.demandTypeId,
                          label: demandTypesList?.find(
                            (demandType) =>
                              demandType.id === demand.demandTypeId,
                          )?.name,
                        }}
                        name="demandTypeId"
                        label="Selecione um tipo de demanda*"
                        onChange={(e) => {
                          onDemandDefaultChange({
                            demand,
                            index,
                            e: {
                              value: e,
                            },
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <InputGeneric
                      label="Descrição*"
                      name="description"
                      value={demand.description}
                      onChange={(e) =>
                        onDemandDefaultChange({ demand, index, e })
                      }
                    />
                  </Row>
                </div>
              );
            })}
            <Row className="mt-3 ">
              <Col className="d-flex justify-content-center">
                <ButtonGeneric
                  className="mx-auto"
                  variant="info"
                  onClick={addEmptyDemandDefault}
                >
                  Adicionar
                </ButtonGeneric>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <ButtonGeneric
                  onClick={createCustomerJourney}
                  id="buttonCreateJourney"
                >
                  Criar jornada
                </ButtonGeneric>
              </Col>
            </Row>
          </div>
        )}
      </MainPage>
    </div>
  );
}
