/* eslint-disable no-console */

import { toast } from 'react-toastify';
import Api from './index';

const CsNotificationsProvider = {
  async getAll(loadings = false) {
    return Api.get(`/cs-notifications`, { params: { loadings } })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast.error('Erro ao buscar notificações');
        console.log(error);
      });
  },
  async update(body) {
    return Api.patch(`/cs-notifications/${body.id}`, body)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast.error('Erro ao atualizar notificação');
        console.log(error);
      });
  },
};

export default CsNotificationsProvider;
