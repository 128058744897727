import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';

const MetricsValueDemands = [
  {
    name: 'CSM',
    selector: (row) => row?.customer?.NewActiveContacts[0]?.User?.name,
    sortable: true,
    sortFunction: (a, b) => {
      const aValue = a?.customer?.NewActiveContacts[0]?.User?.name || '';
      const bValue = b?.customer?.NewActiveContacts[0]?.User?.name || '';
      return aValue.localeCompare(bValue);
    },
  },
  {
    name: 'Nome',
    cell: (row) => (
      <Link
        to={`/customers/${row.customer.customerId || row.customer.id}`}
        style={{
          textDecoration: 'none',
        }}
      >
        {row.customer.name}
      </Link>
    ),
    sortFunction: (a, b) => {
      const aValue = a.customer.name;
      const bValue = b.customer.name;
      return aValue.localeCompare(bValue);
    },
    sortable: true,
    maxWidth: '12%',
  },
  {
    name: 'Demanda de Valor',
    center: true,
    selector: (row) => {
      return (
        <Link
          to={`?demandId=${row.valueDemand.id}`}
          style={{
            textAlign: 'center',
            textDecoration: 'none',
          }}
        >
          {row.valueDemand.name}
        </Link>
      );
    },
  },

  {
    name: 'Data de Finalização',
    selector: (row) => {
      let text = 'Demanda ainda não realizada';

      if (row.lastFinishedAt) {
        text = dayjs(row.lastFinishedAt).format('DD/MM/YYYY');
      }
      return text;
    },
  },

  {
    name: 'Contagem de Finalizações',
    selector: (row) => {
      return row?.finishedCount;
    },
    sortable: true,
    sortFunction: (a, b) => {
      return a.finishedCount - b.finishedCount;
    },
  },
];

export default MetricsValueDemands;
