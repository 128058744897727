import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import TitlePage from '../../../components/Pages/TitlePage';
import MainPage from '../../../components/Pages/MainPage';
import ButtonGeneric from '../../../components/Button';
import InputGeneric from '../../../components/Inputs/Input_generic';
import InputRadio from '../../../components/Inputs/Input_Radio';
import BigFileInput from '../../../components/Inputs/Input_BigFile';
import InputCheckbox from '../../../components/Inputs/Input_Checkbox';
import marcasProvider from '../../../providers/marcas';
import s3 from '../../../providers/urlGenerator';
import Loading from '../../../components/Loading';

const validateImage = async (imageFile) => {
  const img = new Image();
  img.src = URL.createObjectURL(imageFile);
  await img.decode();
  if (img.naturalWidth !== 945 || img.naturalHeight !== 945) {
    return false;
  }
  return true;
};

const validateIsJPEG = (file) => {
  if (file.type !== 'image/jpeg') {
    return false;
  }
  return true;
};

export default function CreateMark() {
  const navigate = useNavigate();
  const [isloading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);

  const authUser = useSelector((state) => state.auth.auth);

  const [formState, setFormState] = React.useState({
    nameMarca: '',
    apresentacao: '',
    natureza: '',
    markImage: [],
    socialContract: [],
  });

  const onChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const showMarkImage =
    formState.apresentacao !==
    'Nominativa (sua marca é somente um nome, sem design específico).';

  const showSocialContract =
    (formState.isUser === 'Não' && formState.isPJ === 'Sim') ||
    (formState.isUser === 'Sim' && authUser.Businesses[0].personType === 'PJ');

  // eslint-disable-next-line consistent-return
  const createMark = async () => {
    try {
      if (
        formState.apresentacao !==
        'Nominativa (sua marca é somente um nome, sem design específico).'
      ) {
        if (formState.markImage[0]?.size > 2097152) {
          return toast.error('Seu arquivo é muito grande');
        }
        const isJPEG = formState.markImage.every(validateIsJPEG);

        if (!isJPEG) {
          return toast.error('Seu arquivo precisa ser um JPG ou JPEG');
        }

        const isValid = await validateImage(formState.markImage[0]);

        if (!isValid) {
          return toast.error(
            'Sua imagem precisa ter exatamente 945x945 pixels | (8cm x 8cm)',
          );
        }
      }

      const attachments = await Promise.all([
        ...formState.markImage.map(async (file) => {
          const uploaders = await s3.uploadFile(file);
          return uploaders;
        }),
        ...formState.socialContract.map(async (file) => {
          const uploaders = await s3.uploadFile(file);
          return uploaders;
        }),
      ]);

      const document = // eslint-disable-next-line no-nested-ternary
        formState.isUser === 'Sim'
          ? authUser.Businesses[0].personType === 'PJ'
            ? authUser.Customer.cnpj
            : authUser.Customer.cpf
          : formState.cpf;

      const zipCode =
        formState.isUser === 'Sim' ? authUser.Businesses[0].cep : formState.cep;

      const addressCity =
        formState.isUser === 'Sim'
          ? authUser.Businesses[0].addressCity
          : formState.city;

      await marcasProvider.createMarca({
        name: formState.nameMarca,
        natureza: formState.natureza,
        apresentacao: formState.apresentacao,
        emailINPI: formState.email,
        description: formState.description,
        CEP: zipCode,
        cep: zipCode,
        address:
          formState.isUser === 'Sim'
            ? authUser.Businesses[0].address
            : formState.address,
        city: addressCity,
        addressCity,
        uf:
          formState.isUser === 'Sim'
            ? authUser.Businesses[0].addressState
            : formState.uf,
        country: 'Brasil',
        cpf: document,
        cnpj: document,
        attachments,
        isCreate: true,
        formState,
      });
      toast.success('Dados enviados com sucesso!');

      return navigate('/tools/marks');
    } catch (error) {
      toast.error('Houve um problema. O suporte ja foi acionado.');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Loading loading={isloading} />
      <TitlePage title="Registrar marca" border="none" />
      <MainPage>
        <Row className="justify-content-center align-items-center">
          <Col md={12}>
            {currentPage === 1 && (
              <>
                <InputGeneric
                  name="nameMarca"
                  label="Qual é o nome da sua Marca?"
                  placeholder="Nome da sua marca"
                  value={formState.nameMarca}
                  onChange={onChange}
                />

                <InputRadio
                  options={[
                    {
                      value:
                        'Nominativa (sua marca é somente um nome, sem design específico).',
                      label:
                        'Nominativa (sua marca é somente um nome, sem design específico).',
                    },
                    {
                      value:
                        'Mista (sua marca é o nome junto com um design específico ou com uma figura).',
                      label:
                        'Mista (sua marca é o nome junto com um design específico ou com uma figura).',
                    },
                    {
                      value: 'Figura (sua marca é somente uma figura/símbolo).',
                      label: 'Figura (sua marca é somente uma figura/símbolo).',
                    },
                  ]}
                  name="apresentacao"
                  label="Qual é a apresentação da sua Marca?"
                  defaultValue={formState.apresentacao}
                  rowClasses="d-flex flex-column pb-3 gap-3"
                  className="checkbox-custom text-justify pt-3"
                  md="auto"
                  sm={12}
                  register={() => {}}
                  onChange={onChange}
                />
                <InputRadio
                  options={[
                    {
                      value: 'Produto',
                      label: 'Produto',
                    },
                    {
                      value: 'Serviço',
                      label: 'Serviço',
                    },
                    {
                      value: 'Ambas',
                      label: 'Ambas',
                    },
                  ]}
                  name="natureza"
                  label="Qual é a natureza da sua marca?"
                  defaultValue={formState.natureza}
                  rowClasses="d-flex flex-column"
                  className=""
                  md="auto"
                  register={() => {}}
                  onChange={onChange}
                />
              </>
            )}
            {currentPage === 2 && (
              <>
                <InputRadio
                  options={[
                    {
                      value: 'Sim',
                      label: 'Sim',
                    },
                    {
                      value: 'Não',
                      label: 'Não',
                    },
                  ]}
                  name="isUser"
                  label={`O titular/responsável da sua marca será o ${authUser.Customer.name}?`}
                  defaultValue={formState.isUser}
                  rowClasses="d-flex flex-column"
                  md="auto"
                  register={() => {}}
                  onChange={onChange}
                />
                {formState.isUser === 'Não' && (
                  <>
                    <InputRadio
                      options={[
                        {
                          value: 'Sim',
                          label: 'Sim',
                        },
                        {
                          value: 'Não',
                          label: 'Não',
                        },
                      ]}
                      name="isPJ"
                      label="O titular da Marca é Pessoa Jurídica?"
                      defaultValue={formState.isPJ}
                      rowClasses="d-flex flex-column"
                      md="auto"
                      register={() => {}}
                      onChange={onChange}
                    />
                    <InputGeneric
                      name="user"
                      label="Qual é o nome do titular da marca?"
                      placeholder="Nome do titular da marca"
                      value={formState.user}
                      onChange={onChange}
                    />
                    <InputGeneric
                      name="cpf"
                      label="Qual o CPF ou CNPJ do titular da marca?"
                      placeholder="CPF ou CNPJ do titular da marca"
                      value={formState.cpf}
                      onChange={onChange}
                    />
                    <InputGeneric
                      name="cep"
                      label="Qual o CEP de correspondência do titular da marca?"
                      placeholder="CEP de correspondência do titular da marca"
                      value={formState.cep}
                      onChange={onChange}
                    />

                    <InputGeneric
                      name="uf"
                      label="Qual o Estado (UF) do titular da marca?"
                      placeholder="Estado (UF) do titular da marca"
                      value={formState.uf}
                      onChange={onChange}
                    />

                    <InputGeneric
                      name="city"
                      label="Qual a cidade do titular da marca?"
                      placeholder="Cidade do titular da marca"
                      value={formState.city}
                      onChange={onChange}
                    />

                    <InputGeneric
                      name="address"
                      label="Qual o endereço do titular da marca?"
                      placeholder="Endereço do titular da marca"
                      value={formState.address}
                      onChange={onChange}
                    />
                  </>
                )}
                <InputGeneric
                  name="email"
                  label="Qual e-mail do titular da marca devemos utilizar para registro no INPI - Instituto Nacional de Propriedade Intelectual?"
                  placeholder="Email do titular da marca"
                  value={formState.email}
                  onChange={onChange}
                />
              </>
            )}
            {currentPage === 3 && (
              <>
                {showMarkImage && (
                  <BigFileInput
                    label="Adicionar imagem da sua Marca"
                    placeholder={
                      <>
                        <p className="p-0 m-0">
                          - Formato de arquivo válido: JPG;
                        </p>
                        <p className="p-0 m-0">
                          - Tamanho exato: 945 x 945 pixels (8 cm x 8 cm);
                        </p>
                        <p className="p-0 m-0">- Resolução mínima: 300 dpis</p>
                        <p className="p-0 m-0">
                          - Tamanho máximo do arquvo: 2 MB.
                        </p>
                      </>
                    }
                    secondPlaceHolder=""
                    name="markImage"
                    onChange={onChange}
                    register={() => {}}
                  />
                )}
                {showSocialContract && (
                  <BigFileInput
                    label="Faça o upload do seu Contrato Social mais atualizado"
                    placeholder="Seu Contrato Social"
                    name="socialContract"
                    onChange={onChange}
                    register={() => {}}
                  />
                )}
                <InputGeneric
                  name="description"
                  label="Faça um breve resumo dos serviços e/ou produtos que a sua marca vai identificar"
                  placeholder="Descrição da marca"
                  as="textarea"
                  value={formState.description}
                  onChange={onChange}
                />
                <div className="mt-3" />
                <InputCheckbox
                  name="ciente"
                  title="Aviso:"
                  label="O INPI leva cerca de 12 meses para decidir sobre o registro da sua marca. Tendo novidades, te avisaremos pela plataforma. Se tiver dúvidas, pode nos chamar a qualquer momento."
                  defaultValue={formState.ciente}
                  className="text-justify mt-4 mb-3"
                  onChange={(value) => {
                    onChange({
                      target: {
                        name: 'ciente',
                        value,
                      },
                    });
                  }}
                />
                <InputCheckbox
                  name="monitoring"
                  label={
                    <span>
                      Contratar Monitoramento Vigilância da Marca: O serviço tem
                      o <strong>custo de R$100,00 mensais</strong> e impede que
                      marcas idênticas ou similares sejam registradas no INPI.
                      Caso haja um pedido protocolado, agiremos rapidamente
                      apresentando oposições para impedir que seja aprovado,
                      garantindo segurança. Selecionando esse serviço,
                      entraremos em contato para mais detalhes em breve.
                    </span>
                  }
                  defaultValue={formState.monitoring}
                  className="text-justify mt-4 mb-3"
                  onChange={(value) => {
                    onChange({
                      target: {
                        name: 'monitoring',
                        value,
                      },
                    });
                  }}
                />
              </>
            )}
          </Col>
          <Row sm={12} className="mt-4">
            <Col
              className="text-center d-flex justify-content-between align-items-center "
              sm={12}
            >
              <ButtonGeneric
                disabled={currentPage === 1}
                className="text-white"
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                {'<'}
              </ButtonGeneric>
              {currentPage}/3
              <ButtonGeneric
                disabled={(() => {
                  if (currentPage === 3) {
                    return true;
                  }
                  if (
                    currentPage === 1 &&
                    (!formState.nameMarca ||
                      !formState.natureza ||
                      !formState.apresentacao)
                  ) {
                    return true;
                  }
                  if (currentPage === 2) {
                    if (
                      formState.isUser === 'Não' &&
                      (!formState.user ||
                        !formState.cpf ||
                        !formState.cep ||
                        !formState.uf ||
                        !formState.city ||
                        !formState.address ||
                        !formState.email)
                    ) {
                      return true;
                    }
                    if (!formState.isUser || !formState.email) {
                      return true;
                    }
                  }

                  return false;
                })()}
                className="text-white"
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                {'>'}
              </ButtonGeneric>
            </Col>
          </Row>
        </Row>

        {currentPage === 3 && (
          <Row className="justify-content-center align-items-center">
            <Col sm={12} className="text-center">
              <ButtonGeneric
                disabled={(() => {
                  if (
                    !formState.ciente ||
                    !formState.description ||
                    (showMarkImage &&
                      (!formState.markImage ||
                        formState.markImage.length === 0)) ||
                    (showSocialContract &&
                      (!formState.socialContract ||
                        formState.socialContract.length === 0)) ||
                    isloading
                  ) {
                    return true;
                  }

                  return false;
                })()}
                className="text-white mt-3"
                onClick={() => {
                  // eslint-disable-next-line no-unused-expressions, no-sequences
                  setIsLoading(true), createMark();
                }}
              >
                Registrar
              </ButtonGeneric>
            </Col>
          </Row>
        )}
      </MainPage>
    </>
  );
}
