/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import MainPage from '../../../components/Pages/MainPage';
import TitlePage from '../../../components/Pages/TitlePage';
import ModalForm from '../../../components/Modals/ModalForm';
import InputGeneric from '../../../components/Inputs/Input_generic';
import ButtonGeneric from '../../../components/Button';
import CustomerSectorProvider from '../../../providers/CustomerSector';
import { questions } from '../../../constants/privacy-questoins';
import InputSelect from '../../../components/Inputs/Input_Select';
import PrivacyWarnings from '../../../providers/PrivacyWarnings';

export default function PrivacyWarningForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [responses, setResponses] = useState({});
  const customerSector = useSelector((state) => {
    return state.CustomerSectorList;
  });

  if (customerSector.rows.length === 0) {
    CustomerSectorProvider.getAll().then((data) => {
      dispatch({
        type: 'SET_CUSTOMER_SECTOR_LIST',
        payload: { rows: data.rows, count: data.count },
      });
    });
  }

  const [showModalEditActivity, setShowModalEditActivity] = useState(false);

  const mappingActivity = useSelector((state) => {
    return state.MappingActivityList.selected;
  });

  const { onlyRead } = useParams();

  const fetch = async (loadings = true) => {
    setResponses({});
    const privacyWarning = await PrivacyWarnings.getONePrivacyQuestions({
      where: {
        id: onlyRead,
      },
    });
    const fetchedAnswers = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= 29; i++) {
      fetchedAnswers.push(privacyWarning.data.answers[`question${i}`]);
    }

    setResponses(fetchedAnswers);
  };

  useEffect(() => {
    setResponses(null);
    dispatch({
      type: 'SET_SELECTED_MAPPING_ACTIVITY',
      payload: null,
    });

    if (onlyRead) {
      fetch();
    }
  }, [onlyRead]);

  useEffect(() => {
    if (!onlyRead) {
      setResponses({});
    }
  }, [!onlyRead]);

  const onChange = (e, nameSelect) => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { name, value } = e.target || e;
    const selectValue = e;
    dispatch({
      type: 'SET_SELECTED_MAPPING_ACTIVITY',
      payload: {
        ...mappingActivity,
        [name || nameSelect]: value || selectValue,
      },
    });
  };

  const updateActivity = async (data) => {
    const mappingActivityToUpdate = {
      ...mappingActivity,
    };

    await PrivacyWarnings.insertAnswerQuestions(mappingActivityToUpdate);
    navigate(`/tools/privacy-warning`);
  };

  return (
    <>
      <TitlePage
        title="Ferramentas / Termos de Uso, Aviso de Privacidade e Políticas de Cookies"
        border="none"
      />
      <MainPage>
        <h1 className="mt-2">
          Questionário - Termos de Uso, Aviso de Privacidade e Políticas de
          Cookies
        </h1>
        <p>
          Este questionário tem o objetivo de colher o máximo de informações
          sobre sua plataforma, a fim de que os Termos de Uso, Aviso de
          Privacidade e Política de Cookies sejam feitos de forma adequada e o
          seu negócio fique mais protegido. É muito importante que as respostas
          sejam preenchidas da maneira mais completa possível.
        </p>
        <hr />

        <Row className="m-0 mt-2 justify-content-center p-5">
          {questions
            .slice(0, 2)
            .map((question, index) =>
              question.inputType === 'text' ? (
                <InputGeneric
                  disabled={onlyRead}
                  key={index}
                  name={`question${index}`}
                  label={`${index + 1} ${question.title}`}
                  onChange={(e) => onChange(e)}
                  defaultValue={responses ? responses[`${index}`] : ''}
                  required
                />
              ) : (
                <InputSelect
                  disabled={onlyRead}
                  key={index}
                  name={`question${index}`}
                  label={`${index + 1} ${question.title}`}
                  onChange={(e) => onChange(e, `question${index}`)}
                  options={question.options}
                  defaultValue={responses ? responses[`${index}`] : ''}
                  required
                />
              ),
            )}

          {/* Primeiro setor: Termos de Uso */}
          <h3 className="mt-4">Termos de Uso</h3>
          <p>
            Nesta parte iremos solicitar algumas informações sobre os termos de
            uso.
          </p>
          {questions
            .slice(2, 12)
            .map((question, index) =>
              question.inputType === 'text' ? (
                <InputGeneric
                  disabled={onlyRead}
                  key={index + 2}
                  name={`question${index + 2}`}
                  label={`${index + 3} ${question.title}`}
                  onChange={(e) => onChange(e)}
                  defaultValue={responses ? responses[`${index}`] : ''}
                  required
                />
              ) : (
                <InputSelect
                  disabled={onlyRead}
                  key={index + 2}
                  name={`question${index + 2}`}
                  label={`${index + 3} ${question.title}`}
                  onChange={(e) => onChange(e, `question${index + 2}`)}
                  options={question.options}
                  defaultValue={responses ? responses[`${index}`] : ''}
                  required
                />
              ),
            )}

          {/* Segundo setor: Aviso de Privacidade */}
          <h3 className="mt-4">Aviso de Privacidade</h3>
          <p>
            Agora, vamos solicitar informações para elaborar as aviso de
            privacidade.
          </p>
          {questions
            .slice(12, 23)
            .map((question, index) =>
              question.inputType === 'text' ? (
                <InputGeneric
                  disabled={onlyRead}
                  key={index + 12}
                  name={`question${index + 12}`}
                  label={`${index + 13} ${question.title}`}
                  onChange={(e) => onChange(e)}
                  defaultValue={responses ? responses[`${index}`] : ''}
                  required
                />
              ) : (
                <InputSelect
                  key={index + 12}
                  disabled={onlyRead}
                  name={`question${index + 12}`}
                  label={`${index + 13} ${question.title}`}
                  onChange={(e) => onChange(e, `question${index + 12}`)}
                  options={question.options}
                  defaultValue={responses ? responses[`${index}`] : ''}
                  required
                />
              ),
            )}

          {/* Terceiro setor: Política de Cookies */}
          <h3 className="mt-4">Política de Cookies</h3>
          <p>
            Aqui vamos solicitar informações para a elaboração das políticas de
            cookies.
          </p>
          {questions
            .slice(23, 29)
            .map((question, index) =>
              question.inputType === 'text' ? (
                <InputGeneric
                  disabled={onlyRead}
                  key={index + 23}
                  name={`question${index + 23}`}
                  label={`${index + 24} ${question.title}`}
                  onChange={(e) => onChange(e)}
                  defaultValue={responses ? responses[`${index}`] : ''}
                  required
                />
              ) : (
                <InputSelect
                  key={index + 23}
                  disabled={onlyRead}
                  name={`question${index + 23}`}
                  label={`${index + 24} ${question.title}`}
                  onChange={(e) => onChange(e, `question${index + 23}`)}
                  options={question.options}
                  defaultValue={responses ? responses[`${index}`] : ''}
                  required
                />
              ),
            )}

          <div className="mt-3" />
          <Col xs="auto">
            <ButtonGeneric
              variant="outline-primary"
              onClick={() => navigate(`/tools/privacy-warning`)}
            >
              Voltar
            </ButtonGeneric>
          </Col>

          <Col xs="auto">
            <ButtonGeneric
              className="text-white"
              disabled={onlyRead}
              onClick={() => {
                updateActivity().then(() => {
                  toast.success('Adicionado com sucesso');
                });
              }}
            >
              Adicionar
            </ButtonGeneric>
          </Col>
        </Row>
      </MainPage>
      <ModalForm
        close={() => setShowModalEditActivity(false)}
        open={showModalEditActivity}
        onSubmit={updateActivity}
        title="Atividade"
      >
        <InputGeneric name="label" label="Novo nome da atividade*" required />
      </ModalForm>
    </>
  );
}
