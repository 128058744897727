import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import TitlePage from '../../../components/Pages/TitlePage';
import MainPage from '../../../components/Pages/MainPage';
import FormGeneric from '../../../components/Form';
import ButtonGeneric from '../../../components/Button';
import businessProvider from '../../../providers/business';
import customerProvider from '../../../providers/customer';
import InputSelect from '../../../components/Inputs/Input_Select';
import schemaCreateDemand from '../../../validators/schemas/demands/create';
import userProvider from '../../../providers/user';
import Table from '../../../components/Table/Table';
import documentsColumns from './docummentsColumns';
import ModalSendDocumentBackoffice from './ModalSendDocumentBackoffice';
import accountingActions from '../../../store/actions/accountingActions';

function BackofficeAccountingDashboard() {
  const [businessCustomer, setBusinessCustomer] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [usersByCustomer, setUsersByCustomer] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [user, setUser] = useState(null);

  const dispatch = useDispatch();
  const backofficeFinance = useSelector(
    (state) => state.backofficeFinanceReceived,
  );

  const fetchCustomers = async () => {
    const customersRequest = await customerProvider.getAll({
      limit: 1000,
    });
    setCustomers(customersRequest.rows);
  };

  const fetchBackofficeReceived = async () => {
    await accountingActions.fetchBackofficeReceived({
      businessId: selectedBusiness.id,
      customerId: selectedCustomer.id,
    });
  };

  const fetchBackofficeSent = async () => {
    await accountingActions.fetchBackofficeSent({
      businessId: selectedBusiness.id,
      customerId: selectedCustomer.id,
    });
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    if (selectedBusiness && selectedCustomer) fetchBackofficeReceived();
    // eslint-disable-next-line
  }, [
    backofficeFinance.received.page,
    backofficeFinance.received.limit,
    backofficeFinance.received.order,
    backofficeFinance.received.where,
    selectedBusiness,
    selectedCustomer,
  ]);

  useEffect(() => {
    if (selectedBusiness && selectedCustomer) fetchBackofficeSent();
    // eslint-disable-next-line
  }, [
    backofficeFinance.sent.page,
    backofficeFinance.sent.limit,
    backofficeFinance.sent.order,
    backofficeFinance.sent.where,
    selectedBusiness,
    selectedCustomer,
  ]);

  const getBusinessesLinkedToCustomer = async (id) => {
    setBusinessCustomer([]);

    const businessCustomerResponse = await businessProvider.getByCustomerId(id);
    setBusinessCustomer(businessCustomerResponse);
    const customer = customers.find((c) => c.id === id);

    setSelectedCustomer(customer);

    setUsersByCustomer([]);
    setSelectedBusiness(null);
    dispatch({
      type: 'RESET_DOCUMENT_LIST',
    });
  };

  const selectingBusiness = async (id) => {
    const usersResponse = await userProvider.getAll({
      where: [
        {
          key: '$Business.id$',
          value: id,
        },
      ],
      limit: 999,
    });
    setUsersByCustomer(usersResponse.rows);
    const business = businessCustomer.find((b) => b.id === id);
    setSelectedBusiness(business);
  };

  const handleSort = (dispatchKey) => async (column, sortDirection) => {
    let order = [];
    if (!column.orderArray) {
      order = [[column.columnName, sortDirection]];
    } else {
      order.push([...column.orderArray, sortDirection]);
    }
    if (column.columnName) {
      dispatch({
        type: dispatchKey,
        payload: { order, page: 1 },
      });
    }
  };

  const handlePageChange = (dispatchKey) => async (pageNumber) => {
    dispatch({
      type: dispatchKey,
      payload: { page: pageNumber },
    });
  };

  const handlePerRowsChange = (dispatchKey) => async (newPerPage) => {
    dispatch({
      type: dispatchKey,
      payload: { limit: newPerPage, page: 1 },
    });
  };

  const handleSubmit = () => {};
  return (
    <>
      <TitlePage
        title="Dashboard Contábil"
        item="Contabilidade"
        description="Aqui você encontra os documentos e informações contábeis"
      />
      <Row className="mt-2 mb-2 d-flex justify-content-between">
        <Col xs="auto">
          <ButtonGeneric
            type="submit"
            variant="primary"
            size="lg"
            className="text-white"
            id="modalFormConfirmationButton"
            onClick={() => {
              dispatch({
                type: 'SET_MODAL_STATE',
                payload: {
                  modalSendDocumentBackoffice: {
                    isOpen: true,
                    businessId: selectedBusiness.id,
                    customerId: selectedCustomer.id,
                    userId: user.id,
                  },
                },
              });
            }}
            disabled={!(selectedBusiness && selectedCustomer && user)}
          >
            Enviar
          </ButtonGeneric>
        </Col>
        <Col xs="auto">
          <ButtonGeneric
            type="submit"
            variant="primary"
            size="lg"
            className="text-white"
            onClick={() => {
              dispatch({
                type: 'SET_MODAL_STATE',
                payload: {
                  modalSendDocumentBackoffice: {
                    isOpen: true,
                    isToCreateDemand: true,
                    businessId: selectedBusiness.id,
                    customerId: selectedCustomer.id,
                    userId: user.id,
                  },
                },
              });
            }}
            disabled={!(selectedBusiness && selectedCustomer && user)}
          >
            Enviar Criando Demanda
          </ButtonGeneric>
        </Col>
      </Row>

      <MainPage>
        <FormGeneric onSubmit={handleSubmit} schema={schemaCreateDemand}>
          <InputSelect
            options={customers?.map((customer) => ({
              value: customer.id,
              label: customer.name,
            }))}
            placeholder="Selecione um Cliente"
            name="customerId"
            label="Cliente*"
            value={{
              value: selectedCustomer?.id,
              label: selectedCustomer?.name,
            }}
            onChange={async (id) => {
              await getBusinessesLinkedToCustomer(id);
            }}
          />
          {businessCustomer?.length > 0 && (
            <InputSelect
              options={businessCustomer?.map((u) => ({
                value: u.id,
                label: u.name,
              }))}
              value={
                selectedBusiness
                  ? {
                      value: selectedBusiness.id,
                      label: selectedBusiness.name,
                    }
                  : null
              }
              onChange={(id) => {
                selectingBusiness(id);
              }}
              placeholder="Selecione um negócio"
              name="businessId"
              label="Negócio do cliente*"
            />
          )}
          {usersByCustomer?.length > 0 && (
            <InputSelect
              options={usersByCustomer?.map((u) => ({
                value: u.id,
                label: u.name,
              }))}
              placeholder="Selecione um Usuário"
              name="following"
              label="Usuário do cliente*"
              onChange={(id) => {
                const u = usersByCustomer.find((item) => item.id === id);
                setUser(u);
              }}
            />
          )}
        </FormGeneric>

        {/* received documents table */}
        <h3 className="mt-4 mb-2">Documentos recebidos do Customer</h3>
        <Table
          columns={documentsColumns}
          data={backofficeFinance.received.rows}
          onSort={handleSort('SET_RECEIVED_BACKOFFICE_DOCUMENT_LIST')}
          onChangePage={handlePageChange(
            'SET_RECEIVED_BACKOFFICE_DOCUMENT_LIST',
          )}
          onChangeRowsPerPage={handlePerRowsChange(
            'SET_RECEIVED_BACKOFFICE_DOCUMENT_LIST',
          )}
          paginationTotalRows={backofficeFinance.received.count}
          paginationDefaultPage={backofficeFinance.received.page}
          itemsPerPage={backofficeFinance.received.limit}
          paginationServer
          pagination
          sortServer
          hideXmsButton
        />
        <div />
        {/* Documents sent table  */}
        <div />
        <h3 className="mt-4 mb-2">Documentos enviados do Backoffice</h3>
        <Table
          columns={documentsColumns}
          data={backofficeFinance.sent.rows}
          onSort={handleSort('SET_SENT_BACKOFFICE_DOCUMENT_LIST')}
          onChangePage={handlePageChange('SET_SENT_BACKOFFICE_DOCUMENT_LIST')}
          onChangeRowsPerPage={handlePerRowsChange(
            'SET_SENT_BACKOFFICE_DOCUMENT_LIST',
          )}
          paginationTotalRows={backofficeFinance.sent.count}
          paginationDefaultPage={backofficeFinance.sent.page}
          itemsPerPage={backofficeFinance.sent.limit}
          paginationServer
          pagination
          sortServer
          hideXmsButton
        />
      </MainPage>
      <ModalSendDocumentBackoffice />
    </>
  );
}

export default BackofficeAccountingDashboard;
