/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
import { React } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import roboto from '../../assets/img/roboto.png';
import CardForContent from '../../components/CardForContent';
import ContentIcon from '../../components/customIcons/ContentIcon';
import SmartLink from '../../components/SmartLink';
import CalendarIcon from '../../components/customIcons/CalendarIcon';
import Table from '../../components/Table/Table';
import ReminderProvider from '../../providers/reminder';
import DateUtils from '../../utils/DateUtils';
import {
  contentFilterReminderUse,
  styleDataTableRemindersInDashboard,
} from '../../constants/reminders';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import indicacaoImg from '../../assets/img/ndm_indicacao.png';
import DemandsList from '../../components/DemandsList';
import MainPage from '../../components/Pages/MainPage';
import ButtonGeneric from '../../components/Button';
import demandsProvider from '../../providers/demands';

const indicatorStyles = {
  background: '#555',
  borderRadius: '50%',
  width: 8,
  height: 8,
  display: 'inline-block',
  margin: '0 8px',
};

const renderStatus = (row) => {
  if (row?.reminderFiled) return 'filed';
  if (dayjs(row?.duoDate).isBefore(dayjs(), 'day')) return 'defeated';
  if (dayjs(row?.duoDate).isSame(dayjs(), 'day')) return 'today';
  if (['legalObrigation', 'task', 'event'].includes(row?.type)) return row.type;
  return 'all';
};

const verifyType = (type) => {
  switch (type) {
    case 'task':
      return 'Tarefa';
    case 'legalObrigation':
      return 'Obrigação Legal';
    case 'event':
      return 'Evento';
    default:
      return 'Indefinido';
  }
};

const columnsRemindersInDashboard = [
  {
    cell: (row) => (
      <Row className="align-items-center">
        <Col xs="auto" className="p-0 ms-2 ps-1">
          <div
            style={{
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              marginLeft: '-10px',
              marginRight: '10px',
              backgroundColor: contentFilterReminderUse.find(
                (item) => item.value === renderStatus(row),
              )?.color,
            }}
          />
        </Col>
        <Col className="p-0">
          <p className="m-0">
            {row.name.length > 100
              ? `${row.name.substring(0, 100)}...`
              : row.name}
          </p>
        </Col>
      </Row>
    ),
  },
  {
    maxWidth: '10px',
    cell: (row) => <p>{verifyType(row.type)}</p>,
  },
  {
    maxWidth: '10px',
    cell: (row) => (
      <p
        style={{
          color:
            contentFilterReminderUse.find(
              (item) => item.value === renderStatus(row),
            )?.value === 'defeated'
              ? contentFilterReminderUse.find(
                  (item) => item.value === renderStatus(row),
                )?.color
              : contentFilterReminderUse.find(
                  (item) => item.value === renderStatus(row),
                )?.value === 'today'
              ? '#FFB800'
              : '#000',
        }}
      >
        {DateUtils.formatToLocale(row.duoDate)}
      </p>
    ),
  },
];

const renderCarouselItems = (demandsList, goToPendetings) => {
  const items = [];

  if (demandsList?.demandCount?.PENDING > 0) {
    items.push(
      <div className="p-1" key="pending">
        <p className="fs-14">
          Você possui {demandsList.demandCount.PENDING} demanda(s) pendente(s)
          aguardando.
        </p>
        <ButtonGeneric className="text-white" onClick={goToPendetings}>
          Resolver Pendências
        </ButtonGeneric>
      </div>,
    );
  }

  items.push(
    <div className="p-1" key="feedback">
      <p className="fs-14">
        Você possui uma demanda aguardando seu feedback. Sua opinião é muito
        importante para nós.
      </p>
      <SmartLink to={`/demands/${1}?isDemandFeedbackModalOpen=true`}>
        Enviar feedback
      </SmartLink>
    </div>,
  );

  items.push(
    <div key="indicacao">
      <a target="_blank" rel="noreferrer" href="https://bit.ly/3QSxPRI">
        <div style={{ height: '155px' }} className="p-0">
          <img
            height="125px"
            style={{ maxWidth: '401px' }}
            src={indicacaoImg}
            alt="Programa de indicação"
          />
        </div>
      </a>
    </div>,
  );

  return items;
};

function CustomerIndex() {
  const authUser = useSelector((state) => state.auth.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const demandsList = useSelector((state) => state.demandsList);
  const isDemandSpecific = !!authUser.Customer?.tags?.find(
    (tag) => tag.name === 'Demanda Específica',
  );

  const goToPendetings = async () => {
    if (demandsList.demandCount.PENDING === 1) {
      const demands = await demandsProvider.getDemands();
      demands.rows.map((demand) => {
        if (demand.statusKey === 'PENDING') {
          return navigate(`demand/${demand.id}`);
        }
      });
    }
    dispatch({
      type: 'SET_DEMANDS_LIST',
      payload: { statusSelected: 'PENDING' },
    });
  };

  const accessToReminders =
    (!!authUser.Customer.customerAccess
      ?.split(',')
      .find((e) => e === 'Lembretes') &&
      isDemandSpecific) ||
    !isDemandSpecific;

  return (
    <MainPage background="#F5F5F5">
      <section className="row">
        <div className="col col-auto">
          <h3>Olá, {authUser?.name.split(' ')[0]}</h3>
          <p>Seja bem-vindo(a) à NDM</p>
        </div>
        <div className="col col-auto">
          <img width={98} height={64} src={roboto} alt="Robô" />
        </div>
      </section>
      <section className="row">
        <Col lg={6} xl="3" className="order-md-1 order-lg-3 mt-2 pe-2">
          <CardForContent title="Conteúdos" Icon={ContentIcon}>
            <p className="fs-14 ms-2 me-2">
              Veja conteúdos atualizados e produzidos para ajudar o seu negócio.
            </p>
            <Row className="justify-content-center">
              <Col xs="auto">
                <SmartLink
                  target="blank"
                  to="https://ndmadvogados.com.br/noticias-blog-juridico-artigos-assessoria-empresarial-advogado-startup"
                >
                  Visitar blog
                </SmartLink>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs="auto">
                <SmartLink
                  target="blank"
                  to="https://www.youtube.com/c/NDMAdvogados"
                >
                  Visitar canal do YouTube
                </SmartLink>
              </Col>
            </Row>
          </CardForContent>
        </Col>
        {accessToReminders && (
          <Col
            lg={12}
            xl="5"
            className="order-md-3 order-lg-0 order-xl-2 mt-2 ps-2 pe-2"
          >
            <CardForContent
              onClick={() => navigate('/tools/reminder')}
              title="Lembretes a vencer"
              Icon={CalendarIcon}
            >
              <Table
                columns={columnsRemindersInDashboard}
                dispatchString="REMINDER_LIST_STATE"
                listReducerKey="reminderList"
                fetchFunction={ReminderProvider.getAllForLists}
                externalStyles={styleDataTableRemindersInDashboard}
                hideXmsButton
              />
            </CardForContent>
          </Col>
        )}
        <Col className="order-md-3 order-lg-1 mt-2 ps-2">
          <CardForContent title="Avisos" Icon={CalendarIcon}>
            <Carousel
              showArrows={false}
              infiniteLoop
              showStatus={false}
              interval="10000"
              renderIndicator={(onClickHandler, isSelected, index, label) => {
                return isSelected ? (
                  <li
                    style={{ ...indicatorStyles, background: '#000' }}
                    aria-label={`Selected: ${label} ${index + 1}`}
                    title={`Selected: ${label} ${index + 1}`}
                  />
                ) : (
                  <li
                    style={indicatorStyles}
                    onClick={onClickHandler}
                    onKeyDown={onClickHandler}
                    value={index}
                    key={index}
                    role="button"
                    tabIndex={0}
                    title={`${label} ${index + 1}`}
                    aria-label={`${label} ${index + 1}`}
                  />
                );
              }}
            >
              {renderCarouselItems(demandsList, goToPendetings)}
            </Carousel>
          </CardForContent>
        </Col>
      </section>
      <div className="mt-4">
        <DemandsList recentFinishedDemands />
      </div>
    </MainPage>
  );
}

export default CustomerIndex;
