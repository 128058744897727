/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Col, Row } from 'react-bootstrap';
import ModalGeneric from '../../../components/Modals/ModalGeneric';
import healthScoreProvider from '../../../providers/healthScore';

export default function ModalHealthScore() {
  const modal = useSelector((state) => state.modal);
  const customer = useSelector((state) => state.customer);
  const [healthScore, setHealthScore] = React.useState({});
  const [healthScore2, setHealthScore2] = React.useState({});
  const dispatch = useDispatch();
  const resetModal = () => {
    dispatch({ type: 'RESET_MODAL_STATE' });
  };

  const fecth = async (version) => {
    const hs = await healthScoreProvider.getHealthScore({
      id: customer.id,
      version: 1,
    });
    const hs2 = await healthScoreProvider.getHealthScore({
      id: customer.id,
      version: 2,
    });

    setHealthScore(hs);
    setHealthScore2(hs2);
  };
  useEffect(() => {
    if (modal?.modalHealthScore?.isOpen) {
      fecth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.modalHealthScore?.isOpen]);

  return (
    <ModalGeneric
      show={modal?.modalHealthScore?.isOpen}
      close={resetModal}
      style={{
        width: '80%',
      }}
    >
      <h3 className="text-center mb-4">Relatório HS do Cliente</h3>
      <Row>
        <Col>
          <h5 className="text-center">Health Score</h5>
          {healthScore?.description?.map((item, key) => (
            <span key={key}>
              {key + 1} - {item}
              <br />
            </span>
          ))}
          <p className="text-end" style={{ fontSize: '13px' }}>
            Realizado em:{' '}
            {dayjs(healthScore?.createdAt).format('DD/MM/YYYY HH:mm')}
          </p>
        </Col>

        <Col xs="auto" className="d-flex align-items-center">
          <div
            style={{
              borderLeft: '2px solid #ccc',
              height: '100%',
            }}
          />
        </Col>

        <Col>
          <h5 className="text-center">Novo Health Score</h5>
          {healthScore2?.description?.map((item, key) => (
            <span key={key}>
              {key + 1} - {item}
              <br />
            </span>
          ))}
          <p className="text-end" style={{ fontSize: '13px' }}>
            Realizado em:{' '}
            {dayjs(healthScore2?.createdAt).format('DD/MM/YYYY HH:mm')}
          </p>
        </Col>
      </Row>
    </ModalGeneric>
  );
}
