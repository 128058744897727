import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import TitlePage from '../../../components/Pages/TitlePage';
import MainPage from '../../../components/Pages/MainPage';
import DocumentProvider from '../../../providers/documentProvider';
import ButtonGeneric from '../../../components/Button';
import Table from '../../../components/Table/Table';
import documentsColumns from '../backoffice/docummentsColumns';
import ModalSendDocument from './modalSendDocument';
import ModalConfirmation from '../../../components/Modals/ModalConfirmation';

const whereCauses = (type) => [
  {
    key: 'fieldType',
    value: 'ACCOUNTING_FIRM',
    operator: 'eq',
  },
  {
    key: 'senderType',
    value: type,
    operator: 'eq',
  },
];

function CustomerAccountingDashboard() {
  const authUser = useSelector((state) => state.auth.auth);
  const customerFinance = useSelector((state) => state.customerFinance);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validator = () => {
    const customerAcess = authUser?.Customer?.customerAccess;
    const isUserAllowed =
      authUser?.customerIsFinance ||
      authUser?.customerSuperAdmin ||
      authUser?.customerAdmin;
    if (!customerAcess?.includes('Contabil Financeiro') || !isUserAllowed) {
      return false;
    }

    return true;
  };

  const isValid = validator();

  const fetchSentByCustomer = async () => {
    if (!isValid) {
      return;
    }

    const customerDocs = await DocumentProvider.getAll({
      fieldType: 'ACCOUNTING_FIRM',
      where: [...whereCauses('CUSTOMER')],
      order: customerFinance.sent.order,
      limit: customerFinance.sent.limit,
      page: customerFinance.sent.page,
    });

    dispatch({
      type: 'SET_SENT_CUSTOMER_DOCUMENT_LIST',
      payload: customerDocs,
    });
  };

  const fetchReceivedByCustomer = async () => {
    if (!isValid) {
      return;
    }

    const customerDocs = await DocumentProvider.getAll({
      fieldType: 'ACCOUNTING_FIRM',
      where: [...whereCauses('BACKOFFICE')],
      order: customerFinance.received.order,
      limit: customerFinance.received.limit,
      page: customerFinance.received.page,
    });

    dispatch({
      type: 'SET_RECEIVED_CUSTOMER_DOCUMENT_LIST',
      payload: customerDocs,
    });
  };

  useEffect(() => {
    fetchSentByCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customerFinance.sent.page,
    customerFinance.sent.limit,
    customerFinance.sent.order,
    customerFinance.sent.where,
  ]);

  useEffect(() => {
    fetchReceivedByCustomer();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customerFinance.received.page,
    customerFinance.received.limit,
    customerFinance.received.order,
    customerFinance.received.where,
  ]);

  const handleSort = (dispatchKey) => async (column, sortDirection) => {
    let order = [];
    if (!column.orderArray) {
      order = [[column.columnName, sortDirection]];
    } else {
      order.push([...column.orderArray, sortDirection]);
    }
    if (column.columnName) {
      dispatch({
        type: dispatchKey,
        payload: { order, page: 1 },
      });
    }
  };

  const handlePageChange = (dispatchKey) => async (pageNumber) => {
    dispatch({
      type: dispatchKey,
      payload: { page: pageNumber },
    });
  };

  const handlePerRowsChange = (dispatchKey) => async (newPerPage) => {
    dispatch({
      type: dispatchKey,
      payload: { limit: newPerPage, page: 1 },
    });
  };

  return (
    <>
      <TitlePage
        title="Dashboard Contábil"
        item="Contabilidade"
        description="Aqui você encontra tudo que importa da sua contabilidade com a gente."
      />
      {!isValid ? (
        <ModalConfirmation
          show
          title="Você ainda não contratou esse serviço."
          description="Para ter acesso aos serviços contábeis da NDM, entre em contato com o nosso time de vendas."
          buttonTextConfirm="Falar com vendas"
          buttonTextCancel="Voltar"
          onConfirmation={() => {
            window.open(
              `https://api.whatsapp.com/send?phone=553492112558&text=Olá, sou ${authUser?.name} da empresa ${authUser?.Customer?.name} e gostaria de entender mais sobre os serviços contábeis e financeiros da NDM`,
            );
            navigate('/');
          }}
          close={() => navigate('/')}
        />
      ) : (
        <>
          <MainPage>
            {/* received documents table */}
            <div />
            <h4 className="mt-4 mb-2">Arquivos recebidos da NDM</h4>
            <Table
              columns={documentsColumns}
              data={customerFinance.received.rows}
              onSort={handleSort('SET_RECEIVED_CUSTOMER_DOCUMENT_LIST')}
              onChangePage={handlePageChange(
                'SET_RECEIVED_CUSTOMER_DOCUMENT_LIST',
              )}
              onChangeRowsPerPage={handlePerRowsChange(
                'SET_RECEIVED_CUSTOMER_DOCUMENT_LIST',
              )}
              paginationTotalRows={customerFinance.received.count}
              paginationDefaultPage={customerFinance.received.page}
              itemsPerPage={customerFinance.received.limit}
              paginationServer
              pagination
              sortServer
              hideXmsButton
              unStyled
            />
            <div />
            {/* Documents sent table  */}
            <div />
            <Row className="mt-4 mb-2 align-items-center">
              <Col md="12">
                <h4>Arquivos enviados para NDM</h4>
              </Col>
              <Col md="12">
                <ButtonGeneric
                  type="submit"
                  variant="primary"
                  className="text-white"
                  id="modalFormConfirmationButton"
                  onClick={() =>
                    dispatch({
                      type: 'SET_MODAL_STATE',
                      payload: {
                        modalSendDocument: {
                          isOpen: true,
                        },
                      },
                    })
                  }
                >
                  Enviar
                </ButtonGeneric>
              </Col>
            </Row>
            <Table
              columns={documentsColumns}
              data={customerFinance.sent.rows}
              onSort={handleSort('SET_SENT_CUSTOMER_DOCUMENT_LIST')}
              onChangePage={handlePageChange('SET_SENT_CUSTOMER_DOCUMENT_LIST')}
              onChangeRowsPerPage={handlePerRowsChange(
                'SET_SENT_CUSTOMER_DOCUMENT_LIST',
              )}
              unStyled
              paginationTotalRows={customerFinance.sent.count}
              paginationDefaultPage={customerFinance.sent.page}
              itemsPerPage={customerFinance.sent.limit}
              paginationServer
              pagination
              sortServer
              hideXmsButton
            />
          </MainPage>
          <ModalSendDocument />
        </>
      )}
    </>
  );
}

export default CustomerAccountingDashboard;
