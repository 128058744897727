import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import ModalForm from '../../../components/Modals/ModalForm';
import schemaGeneric from '../../../validators/schemas/schemaGeneric';
import InputGeneric from '../../../components/Inputs/Input_generic';
import InputSelect from '../../../components/Inputs/Input_Select';
import CONSTANTE_CUSTOMER from '../../../constants/customer';
import businessProvider from '../../../providers/business';
import InputMask from '../../../components/Inputs/Input_Mask';
import InputCalendar from '../../../components/Inputs/Input_Calendar';
import customerProvider from '../../../providers/customer';
import customersActions from '../../../store/actions/customersActions';
import FormAddressData from '../create/FormAddressData';
import { setAddressKeyToUse } from '../../../store/actions/addressActions';
import NewActiviteContactProvider from '../../../providers/NewActiveContacts';

export default function ModalCreateBusiness() {
  const modal = useSelector((state) => state.modal);
  const customer = useSelector((state) => state.customer);
  const backofficeList = useSelector((state) => state.backofficeList);
  const form = useForm();

  const dispatch = useDispatch();

  const resetModal = () => {
    dispatch({ type: 'RESET_MODAL_STATE' });
    form.reset();
  };
  const setFormAndReset = () => {
    dispatch({
      type: 'SET_BUSINESS',
      payload: {
        form,
      },
    });
    form.reset();
  };

  useEffect(() => {
    setFormAndReset();
    setAddressKeyToUse({
      keyToUse: 'modal.modalCreateBusiness.business',
      formKeyToUse: 'business',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tags = customer?.tags || [];
  const onSubmit = async (data) => {
    if (
      (data.backofficeUserId ||
        (data.nextActiveContactDate &&
          dayjs(data.nextActiveContactDate)
            .startOf('day')
            .diff(
              dayjs(customer?.ActiveContacts[0]?.contactDate).startOf('day'),
            ))) &&
      modal?.modalCreateBusiness?.business?.ActiveContacts[0]?.id
    ) {
      const activeContactInfos = {
        backofficeUserId: data.backofficeUserId,
        nextActiveContactDate:
          dayjs(data.nextActiveContactDate)
            .startOf('day')
            .isBefore(
              dayjs(customer?.ActiveContacts[0]?.createdAt).startOf('day'),
            ) ||
          dayjs(data.nextActiveContactDate)
            .startOf('day')
            .isSame(
              dayjs(customer?.ActiveContacts[0]?.createdAt).startOf('day'),
            )
            ? dayjs(customer?.ActiveContacts[0]?.contactDate)
            : dayjs(data.nextActiveContactDate),
        contactActiveId:
          modal?.modalCreateBusiness?.business?.ActiveContacts[0]?.id,
        constactActiveCustomerId: customer?.ActiveContacts[0]?.id,
      };
      await customerProvider.updateActiviteContact(
        customer.id,
        activeContactInfos,
      );
    }

    const requestNew = {};

    if (data.backofficeUserId) {
      requestNew.backofficeUserId = data.backofficeUserId;
    }

    if (data.nextActiveContactDate) {
      requestNew.contactDate = dayjs(data.nextActiveContactDate);

      if (
        modal?.modalCreateBusiness?.business?.NewActiveContacts[0]?.finishedAt
      ) {
        delete requestNew.contactDate;
        requestNew.nextContactDate = dayjs(data.nextActiveContactDate);
      }
    }

    if (modal?.modalCreateBusiness?.business?.NewActiveContacts[0]?.id) {
      NewActiviteContactProvider.update({
        id: modal?.modalCreateBusiness?.business?.NewActiveContacts[0]?.id,
        ...requestNew,
      });
    }

    const reqData = { ...data };
    reqData.cep = reqData['cep/ZIPCODE'];
    delete reqData.backofficeUserId;
    delete reqData.nextActiveContactDate;
    const request = {
      ...reqData,
      customerId: customer.id,
    };
    !modal?.modalCreateBusiness?.edit
      ? await businessProvider.create(request)
      : await businessProvider.update(
          modal?.modalCreateBusiness?.business?.id,
          request,
        );
    customersActions.fetchById(customer.id);
    resetModal();
  };

  return (
    <ModalForm
      open={modal?.modalCreateBusiness?.isOpen}
      close={resetModal}
      schema={schemaGeneric}
      overflowScroll
      onSubmit={onSubmit}
      style={{
        width: '60%',
      }}
      useForm={form}
    >
      <h3 className="text-center">
        {modal?.modalCreateBusiness?.business
          ? 'Editar Negócio'
          : 'Adicionar negocio'}
      </h3>
      <InputGeneric
        name="name"
        label="Nome"
        placeholder="Digite o nome do negócio"
        defaultValue={modal?.modalCreateBusiness?.business?.name}
      />
      <InputSelect
        options={CONSTANTE_CUSTOMER.SOCIETY_TYPES}
        name="typeCompany"
        label="Tipo de sociedade"
        placeholder="Selecione o tipo de sociedade do negócio"
        defaultValue={modal?.modalCreateBusiness?.business?.typeCompany}
      />
      <Row>
        <Col>
          <InputSelect
            options={[
              {
                value: 'PF',
                label: 'Pessoa Física',
              },
              {
                value: 'PJ',
                label: 'Pessoa Jurídica',
              },
            ]}
            name="personType"
            label="Tipo de pessoa"
            placeholder="Selecione o tipo de pessoa do negócio"
            defaultValue={modal?.modalCreateBusiness?.business?.personType}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputSelect
            options={CONSTANTE_CUSTOMER.CUSTOMER_TYPES.OPTIONS}
            name="businessType"
            label="Tipo do negócio"
            placeholder="Digite o tipo do negócio"
            defaultValue={modal?.modalCreateBusiness?.business?.businessType}
          />
        </Col>
      </Row>
      <FormAddressData />
      <Row>
        <Col>
          <InputMask
            type="cpf"
            name="cpf"
            label="CPF do Representante Legal"
            placeholder="Digite o CPF do representante legal"
            defaultValue={modal?.modalCreateBusiness?.business?.cpf}
          />
        </Col>
        <Col>
          <InputMask
            type="cnpj"
            name="cnpj"
            label="CNPJ"
            placeholder="Digite o CNPJ do Negócio"
            defaultValue={modal?.modalCreateBusiness?.business?.cnpj}
          />
        </Col>
      </Row>
      {customer?.contactFor === 'Businesses' &&
        !tags.find(({ name }) => name === 'Demanda Específica') && (
          <Row>
            <Col>
              <InputSelect
                name="backofficeUserId"
                label="Responsável pelo contato"
                placeholder="Selecione o responsável"
                options={backofficeList?.rows?.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                defaultValue={[
                  modal?.modalCreateBusiness?.business?.ActiveContacts[0]?.User
                    ?.id,
                ]}
              />
            </Col>
            <Col>
              <InputCalendar
                name="nextActiveContactDate"
                label="Data do próximo contato"
                defaultValue={[
                  Array.isArray(
                    modal?.modalCreateBusiness?.business?.NewActiveContacts,
                  ) &&
                    modal?.modalCreateBusiness?.business?.NewActiveContacts[0]
                      ?.contactDate,
                ]}
              />
            </Col>
          </Row>
        )}
    </ModalForm>
  );
}
